import React from "react";
import "./NewsMore.css";

function NewsMore() {
  return (
    <div className="nm">
      Izrada pri kraju, podstranica će biti ubrzo dostupna...
    </div>
  );
}

export default NewsMore;
