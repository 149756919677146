import React, { useEffect } from "react";
import "./ContactUs.css";
import Footer from "./Footer";
import Header from "./Header";
import ContactMore from "./ContactMore";

function ContactUs() {
  document.title = "Kontakt \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Imaš pitanja za nas? Volonteri Dosta je bilo odgovorit će ti u nekoliko sati ili kraće. Dostupni smo 24 sata dnevno."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="contact-us">
        <Header
          mainLabel="KONTAKT"
          otherLabel="Na raspolaganju smo svaki dan i odgovorit ćemo na upit u kraće od 12 sati, obično i puno prije."
        />
        <ContactMore />
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
