import React from "react";
import "./ProgramMore.css";

function ProgramMore() {
  return (
    <div className="pm">
      U tijeku je izmjena stare web-stranice, njezina dopuna i redizajn, ova
      podstranica će biti ubrzo dostupna. Hvala na razumijevanju, a ostale
      podatke moguće je naći na ostalim dijelovima koji su već završeni.
    </div>
  );
}

export default ProgramMore;
