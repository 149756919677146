import React from "react";
import "./LastPosts.css";
import { Link } from "react-router-dom";

function LastPosts() {
  return (
    <div className="last-posts">
      <div className="lp-container">
        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>
        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>

        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>

        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>

        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>
        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>

        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>

        <div className="lp-box">
          <a href="/vijesti#istaknuto">
            <img
              src={require("../files/mobile.jpeg")}
              alt="Tekst će biti povučen iz DB-ja"
              draggable="false"
            />
            <h5>Autor: Dosta je bilo</h5>
            <p>
              Naslov će biti povučen iz baze podataka naknadno kod objave gotove
              stranice...
            </p>
          </a>
        </div>
      </div>
      <br />
      <br />
      <Link style={{ paddingBlock: ".5rem" }} to="/vijesti">
        SVE OBJAVE "DOSTA JE BILO"
      </Link>
      <br />
      <br />
      <br />
    </div>
  );
}

export default LastPosts;
