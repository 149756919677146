import React, { useState, useRef } from "react";
import "./Footer.css";
import Newsletter from "./Newsletter";
import LastTrack from "./LastTrack";

import axios from "axios";

import { Link } from "react-router-dom";
import { BsHeartFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { MdAlternateEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { SiViber } from "react-icons/si";

function Footer() {
  const [rateBox, setRateBox] = useState(false);
  const [adviceMsg, setAdviceMsg] = useState("");
  const [isItError, setIsItError] = useState(true);

  const textareaRef = useRef();
  const btnAdviceRef = useRef();

  function textareaMark(e) {
    e.stopPropagation();
    setRateBox(true);
    console.log("User can mark the website");
  }

  function buttonMark(e) {
    e.stopPropagation();
    setRateBox(true);

    setAdviceMsg(textareaRef.current.value);

    axios
      .post("http://localhost:7250/advices", {
        advicesMessage: adviceMsg,
      })
      .then(function (response) {
        console.log(response);
        btnAdviceRef.current.disabled = "true";

        textareaRef.current.value = "";

        setAdviceMsg("");
        setIsItError(false);

        document.getElementById("rateWindow").style.backgroundColor = "#32a834";

        setTimeout(() => {
          document.getElementById("rateWindow").style.backgroundColor =
            "#d82020";
          setRateBox(false);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setIsItError(true);
      });
  }

  return (
    <div className="foot-all">
      <Newsletter />
      <div className="footer">
        <div
          id="rateWindow"
          className={rateBox ? "rate-box" : "not-shown"}
          onClick={() => setRateBox(!rateBox)}
        >
          <h3>Tvoje kritike, savjeti i pohvale da bismo bili još bolji</h3>
          <p>
            VIŠESTRUKA SLANJA NE ŠALJU SE U BAZU PODATAKA ZBOG ONEMOGUĆAVANJA
            SPAMA. NAKON ŠTO TI SE POZADINA KLIKOM JEDNOM ZAZELENI, NOVI
            ZAHTJEVI MOĆI ĆE BITI POSLANI U BAZU TEK KROZ 30 MIN.
            <br />
            <br />
            Također, s namjerom suzbijanja spama svaki dan ćemo zapise
            pročitati, uzeti na razmatranje i potom ih brisati iz podatkovne
            baze, da bismo ostavili aktivnim ovakav brzi način komunikacije s
            korisnicima.
          </p>
          <textarea
            placeholder="Napiši nam anonimno svoje prijedloge..."
            name="footarea"
            id="footarea"
            required
            onClick={textareaMark}
            ref={textareaRef}
            onChange={(e) => setAdviceMsg(e.target.value)}
          ></textarea>
          <button onClick={buttonMark} ref={btnAdviceRef}>
            POŠALJI
          </button>
        </div>

        <div className="reset-pos">
          <div className="nav-track-foot">
            <div className="nav-track-both-foot">
              <div className="nav-track-1-foot">
                <img
                  src={require("../files/dostaje.png")}
                  alt="Službeni logo organizacije Dosta je bilo"
                  draggable="false"
                />
                <p>
                  Dosta je bilo <br />
                  <span>Nevladina organizacija</span>
                </p>
              </div>
              <div className="nav-track-2-foot">
                <Link to="/volontiranje">
                  <p>VOLONTIRAJ</p>
                </Link>
                <BsHeartFill
                  className="cross-foot"
                  onClick={() => setRateBox(!rateBox)}
                />
              </div>
            </div>

            <div className="menu-links-foot">
              <Link className="" to="/">
                Početna
              </Link>
              <Link className="" to="/statut">
                Statut
              </Link>
              <Link className="" to="/program-i-ciljevi">
                Program
              </Link>
              <Link className="" to="/dokumenti">
                Dokumenti
              </Link>
              <Link className="" to="/inicijativa-protiv-stozera">
                Ne Stožeru
              </Link>
              <Link className="" to="/pravni-koraci">
                Pravni koraci
              </Link>
              <Link className="" to="/financijska-izvjesca">
                Financije
              </Link>
              <Link className="" to="/politika-privatnosti">
                Politika privatnosti
              </Link>
              <Link className="" to="/sitemap">
                Sitemap
              </Link>
              <Link className="" to="/o-nama">
                O nama
              </Link>

              <Link className="" to="/administracija">
                Prijavi se
              </Link>
              <Link className="" to="/vijesti">
                Vijesti
              </Link>
            </div>

            <div className="add-links-foot">
              <Link className="" to="/kontakt">
                Kontakt
              </Link>
              <Link className="" to="/doniranje">
                Doniraj
              </Link>
              <Link className="" to="/uclanjenje">
                Učlani se
              </Link>
            </div>

            <div className="cont-foot">
              <a href="mailto:info@dostajebilo.hr">
                <div className="cont">
                  <GrMail className="footicon" />
                  <h5>
                    info
                    <br />
                    @dostajebilo.hr
                  </h5>
                </div>
              </a>
              <a href="mailto:dostajebilo@dostajebilo.hr">
                <div className="cont">
                  <MdAlternateEmail className="footicon" />
                  <h5>
                    dostajebilo <br /> @dostajebilo.hr
                  </h5>
                </div>
              </a>
              <a
                href="https://www.facebook.com/groups/3430335050311881"
                target="_blank"
                rel="noreferrer"
              >
                <div className="cont">
                  <FaFacebook className="footicon" />
                  <h5>Facebook-grupa</h5>
                </div>
              </a>
              <a
                href="https://www.facebook.com/dostajebilo.hrvatska"
                target="_blank"
                rel="noreferrer"
              >
                <div className="cont">
                  <FaFacebookMessenger className="footicon" />
                  <h5>@DostajebiloHR</h5>
                </div>
              </a>
              <a href="https://api.whatsapp.com/send?phone=385977656906">
                <div className="cont">
                  <BsWhatsapp className="footicon" />
                  <h5>+385 97 765 6906</h5>
                </div>
              </a>
              <a href="viber://chat?number=385977656906">
                <div className="cont">
                  <SiViber className="footicon" />
                  <h5>+385 97 765 6906</h5>
                </div>
              </a>
            </div>

            <h4 className="label-foot">Izrada web-stranice</h4>
            <div className="web-creating">
              <div className="mathscript">
                <img
                  src={require("../files/favicon.png")}
                  alt="Logo Mathscripta, agencije za web-development i izradu web-stranica"
                  draggable="false"
                />
                <h5>
                  <a
                    href="https://mathscript.eu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mathscript
                  </a>
                </h5>
              </div>
              <p>
                <i>
                  Napomena. Osnivač trgovačkog društva autora web-stranice i
                  "Dosta je bilo" ista je osoba, ali sama izrada izvršena je bez
                  naplate, čime nije došlo ni do kakvih zakonski upitnih
                  financijskih radnji. Održavanje se, također, sve vrijeme
                  izvršava volonterski. Bilo kakve izmjene ovog odnosa bit će
                  otvoreno objavljene na ovoj stranici.
                </i>
              </p>
            </div>
            <br />
            <br />
            <br />
            <br />
            <LastTrack />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
