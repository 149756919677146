import React, { useState, useRef } from "react";
import "./Admin.css";
import axios from "axios";

function AdminAddArticle() {
  const [naslov, setNaslov] = useState("");
  const [podnaslov, setPodnaslov] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [article, setArticle] = useState("");
  const [errText, setErrText] = useState("");
  const [err, setErr] = useState(false);
  const [okayText, setOkayText] = useState("");
  const [okay, setOkay] = useState(false);

  const naslovRef = useRef();
  const podnaslovRef = useRef();
  const dateRef = useRef();
  const authorRef = useRef();
  const articleRef = useRef();

  function writeArticle(e) {
    e.preventDefault();

    axios
      .post("http://localhost:7250/post", {
        postText: article,
        postHeader: naslov,
        postSubheader: podnaslov,
        postAuthor: author,
        postDate: date,
      })
      .then(function (response) {
        console.log(response.data);

        setErr(false);
        setOkay(true);
        setOkayText("Članak uspješno objavljen");

        naslovRef.current.value = "";
        podnaslovRef.current.value = "";
        dateRef.current.value = "";
        authorRef.current.value = "";
        articleRef.current.value = "";
      })
      .catch(function (error) {
        console.log(error.message);
        setErr(true);
        setOkay(false);
        setErrText(error.message);
      });
  }
  return (
    <div>
      <form className="admin-form-article" action="" onSubmit={writeArticle}>
        <input
          type="text"
          placeholder="naslov..."
          required
          onChange={(e) => setNaslov(e.target.value)}
          ref={naslovRef}
        />
        <input
          type="text"
          placeholder="podnaslov..."
          required
          onChange={(e) => setPodnaslov(e.target.value)}
          ref={podnaslovRef}
        />
        <input
          type="text"
          placeholder="author..."
          required
          onChange={(e) => setAuthor(e.target.value)}
          ref={authorRef}
        />
        <input
          type="text"
          placeholder="date..."
          required
          onChange={(e) => setDate(e.target.value)}
          ref={dateRef}
        />
        <textarea
          type="text"
          placeholder="write an article..."
          required
          onChange={(e) => setArticle(e.target.value)}
          ref={articleRef}
        ></textarea>
        <button type="submit">OBJAVI ČLANAK</button>
      </form>

      {err && <h3>{errText}</h3>}
      {okay && <h3>{okayText}</h3>}
    </div>
  );
}

export default AdminAddArticle;
