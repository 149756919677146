import React, { useEffect } from "react";
import "./Financije.css";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

function Financije() {
  document.title = "Poslovanje \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Financijska izvješća, najave skupština i druge informacije vezane uz interno funkcioniranje Dosta je bilo za javnost."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="financije">
        <Header
          mainLabel="POSLOVANJE"
          otherLabel="Financijska izvješća, najave skupština i druge informacije usko vezane uz interno funkcioniranje za javnost"
        />
        <div className="fin-more">
          <div className="galaxy">
            <img
              src={require("../files/galaxy.jpeg")}
              alt="Galaksija poput Mliječne staze ili Milky Waya s mnoštvom crvenih zvijezda na nebu - Dosta je bilo"
              draggable="false"
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="fin-label">Financijska izvješća</h3>
          <p>
            Prvo godišnje financijsko izvješće "Dosta je bilo" bila je dužna
            izraditi za 2021. godinu i predati ga početkom 2022. Ministarstvu
            financija, po načelima dvojnog knjigovodstva tijekom prvih triju
            godina postojanja. Svoju prvu obvezu je ispunila i u istom smjeru
            nastavit će i dalje.{" "}
          </p>

          <p>
            Godišnja financijska izvješća bit će dostupna ispod i ovdje se svake
            godine redovito objavljivati, kako bismo i na našim stranicama
            održali maksimalnu razinu transparentnosti. Ovo je izvješće javno
            dostupno i na stranicama Ministarstva financija Republike Hrvatske i
            to u sklopu{" "}
            <a
              href="https://banovac.mfin.hr/rnoprt/"
              target="_blank"
              rel="noreferrer"
            >
              Registra neprofitnih organizacija
            </a>
            , odnosno RNO-a. Tu je moguće ostvariti uvid u objavljena godišnja
            izvješća svih udruga u Republici Hrvatskoj. Naravno, onih koje
            posluju transparentno.
          </p>

          <p>
            Obveza sastavljanja i predavanja financijskih izvješća propisana je
            <i>
              Zakonom o financijskom poslovanju i računovodstvu neprofitnih
              organizacija
            </i>{" "}
            (NN br. 121/14) i pravilnicima donesenim temeljem Zakona (NN br.
            31/15, 67/17 i 115/18), stoji na stranicama{" "}
            <a
              href="https://www.fina.hr/neprofitne-organizacije"
              target="_blank"
              rel="noreferrer"
            >
              Financijske agencije (FINA)
            </a>
            . Zakonom je uvedena obveza dostave financijskih izvješća za sve
            neprofitne organizacije, ali prilagođeno visini prihoda i
            vrijednosti imovine.
          </p>
          <p>
            Neprofitna organizacija čija je vrijednost imovine na kraju svake od
            prethodne tri godine uzastopno manja od 230 000 HRK na razini godine
            i čiji je godišnji prihod uzastopno u svakoj od prethodne tri godine
            manji od 230 000 HRK na razini godine nije u obvezi vođenja dvojnog
            knjigovodstva, već se može odlučiti za vođenje jednostavnog
            knjigovodstva. Međutim, te neprofitne organizacije mogu donijeti
            odluku prema kojoj, unatoč tome što su zadovoljavaju uvjete za
            vođenje jednostavnog knjigovodstva, vode dvojno knjigovodstvo i u
            tom slučaju su dužne predavati sva izvješća kao obveznici dvojnog
            knjigovodstva.
          </p>
          <p>
            Rok za predaju godišnjeg financijskog izvješća u poslovne jedinice
            Financijske agencije je 60 dana od isteka izvještajnog razdoblja.
            Udruge dostavljaju i polugodišnja izvješća koja obuhvaćaju
            financijsku godina u razdoblju od 1. siječnja do 30. lipnja i imaju
            rok do kraja srpnja kako bi predali i za to predviđeni obrazac.{" "}
          </p>
          <br />
          <a className="gfi" href={require("../files/gfi.xls")}>
            Godišnje financijsko izvješće za 2021.
          </a>
          <br />
          <br />
          <a className="gfi" href="javascript:void(0)">
            Godišnje financijsko izvješće za 2022. (nedostupno)
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="fin-label">Sjednice nakon registracije</h3>
          <p>
            "Dosta je bilo" je registrirana i puštena u pravni promet 15.
            veljače 2021. Obvezna je jednom godišnje održati redovito zasjedanje
            tijela skupštine i svake četiri godine izbornu skupštinu. Po
            potrebi, a sukladno uvjetima iz statuta organizacije, moguće je
            sazvati izvanredne sjednice. Svaka će sjednica biti najavljena ovdje
            ispod barem osam dana prije održavanja, nastavno na{" "}
            <a href="/statut">odredbe Statuta</a>, kao što će biti navedene i
            ključne odluke koje je skupština donijela na zasjedanju.
          </p>
          <br />
          <div className="yellowish">
            <a href="javascript:void(0)" className="gfi redish">
              30. svibnja 2022. — DRUGA REDOVITA SKUPŠTINA - Datuma 30. svibnja
              2022. u Zagrebu održala se druga redovita godišnja skupština na
              kojoj je izvršena potvrda izmjene niza odredaba statuta. Došlo je
              do promjene loga u trenutno aktualni oblik, redefinicije simbola
              udruge, a iz imena udruge izbačena je riječ "građana" radi
              pojednostavljivanja i skraćivanja naziva. Program i ciljeve
              organizacije jasnije se odredilo i proširilo, kako bi se otklonile
              nejasnoće zbog pretjerane općenitosti. Predstavljeno je i godišnje
              financijsko izvješće za prošlu 2021. godinu i ukratko plan
              aktivnosti za godinu koja je u tijeku. Nije bilo potrebe za
              dodavanjem dodatnih točaka na sjednici pa je ista zaključena, a
              odgovorna će osoba u zakonskom roku izvješće predati nadležnom
              gradskom uredu za opću upravu.
            </a>
          </div>
          <br />
          <br />
          <div className="yellowish">
            <a href="javascript:void(0)" className="gfi redish">
              20. svibnja 2022. — NAJAVA ODRŽAVANJA SKUPŠTINE - Datuma 30.
              svibnja 2022. u Zagrebu održat će se druga redovita godišnja
              skupština na kojoj bi trebalo doći do izmjene niza odredaba
              statuta. Cilj je izmijeniti logo i simbole udruge i koristiti novi
              dizajn u budućnosti, iz imena udruge izbaciti "građana" radi
              pojednostavljivanja i skraćivanja naziva i na kraju redefinirati
              šire i jasnije program i ciljeve organizacije. Također, predstavit
              će se i godišnje financijsko izvješće za prošlu 2021. godinu i
              ukratko predstaviti plan aktivnosti za ovu trenutnu godinu.
              Postoji mogućnost dodavanja dodatnih točaka po potrebi u skladu sa
              statutarnim odredbama. Osobe s pravom sudjelovanja u radu dobit će
              sve dodatne potrebne informacije e-mailom.
            </a>
          </div>
          <br />
          <br />

          <div className="yellowish">
            <a href="javascript:void(0)" className="gfi redish">
              20. srpnja 2021. — PRVA IZVANREDNA SKUPŠTINA — Utvrđeno je
              ispunjenje uvjeta iz članaka 26., 27., 28. i 29. Statuta Udruge i
              mogućnost nastavka sjednice. Izmjene u Statutu: članak 5.
              (nadodana zadnja rečenica o heksadecimalnoj vrijednosti crvene
              boje u logu - neobvezujuće za ostale dizajnerske aktivnosti u tom
              pogledu), članak 7. (nadodana zadnja točka o zabrani djelovanja
              članova u kontekstu poticanja ekstremizma ili pseudoznanosti),
              članak 14. (ispravljeni kontaktni podatci Udruge), članak 16. (u
              st. 1. obrisana točka koja je omogućavala iščlanjenje iz Udruge
              usmenim putem zbog nedorečenosti), članak 26. (na kraju dodana
              odredba da Predsjednik i Dopredsjednik mogu odbiti zahtjev osobe
              koja želi postati članom Skupštine ako procjene da bi ista mogla
              djelovati suprotno Statutu).
            </a>
          </div>
          <br />
          <br />
          <div className="yellowish">
            <a href="javascript:void(0)" className="gfi redish">
              10. srpnja 2021. — NAJAVA ODRŽAVANJA SKUPŠTINE - Datuma 20. srpnja
              2021. u Zagrebu održat će se prva izvanredna skupština na kojoj bi
              trebalo doći do izmjene nekoliko odredaba Statuta (5., 7., 14.,
              16., 26.). Osobe s pravom sudjelovanja u radu dobit će sve dodatne
              potrebne informacije e-mailom.
            </a>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="fin-label">
            Financijske potpore primljene iz sredstava državnog proračuna,
            državnih tijela, agencija, fondova EU ili drugih javnih izvora{" "}
          </h3>
          <p>
            "Dosta je bilo" će, ako bi ostvarila pravo ili primila bilo kakva
            novčana sredstva namijenjena za pomoć radu, razvijanju strukture i
            unaprjeđenju položaja organizacije u društvu (iz izvora navedenih u
            podnaslovu), javno obavijestiti sve zainteresirane o korištenju
            takvih prava i to u najkraćem mogućem roku od potencijalnog
            ostvarivanja prava na takva sredstva. Potičemo i sve one koji za to
            imaju interes da nam se slobodno jave upitom i u najkraćem roku ćemo
            ih obavijestiti o svim financijskim podatcima ove vrste kojima
            raspolažemo, u duhu potpune transparentnosti.{" "}
          </p>
          <a href="javascript:void(0)" className="gfi redish">
            Trenutno nema ostvarenih prava za prikaz
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="fin-label">
            Način potrošnje odobrenih sredstava iz javnih i europskih izvora{" "}
          </h3>
          <p>
            Nastavno na prethodno, "Dosta je bilo" redovito će javnost
            upoznavati s načinom na koji je trošila i raspolagala novčanim
            sredstvima odobrenima iz izvora koji su opisani u prethodnom
            poglavlju. Objavit će se ne samo odobreni iznos, već i za što je on
            sukladno ugovoru točno odobren i naknadno je li doista potrošen u te
            svrhe. Ako će postojati odstupanja, nastojat ćemo objasniti kako je
            do njih došlo i na koji način ih planiramo ispraviti. Naravno,
            navest će se i je li bilo kakvo odstupanje dozvoljeno prema ugovoru,
            a ako nije, kako postići da bude. Informacije ove vrste slat će se i
            zainteresiranim stranama na njihov izravan upit slanjem zahtjeva
            elektroničkom poštom, u najkraćem mogućem roku, uz navođenje svih
            razumno traženih pojedinosti.
          </p>
          <a href="javascript:void(0)" className="gfi redish">
            Trenutno nema ostvarenih prava za prikaz
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="fin-label">Predsjednici </h3>
          <p>
            U nastavku prilažemo popis osoba koje su vodile "Dosta je bilo" od
            osnutka na poziciji Predsjednika. Prva izborna skupština od osnutka
            održat će se u prosincu 2024. i tada će biti izbor predsjednika na
            nove četiri godine mandata. U skladu s time i eventualnim
            izvanrednim promjenama, ovaj će se popis redovito ažurirati.
            Predsjednik je ujedno i osoba odgovorna za zastupanje organizacije.
          </p>
          <br />
          <a
            className="gfi"
            href="https://www.facebook.com/tomislav.pilkic"
            target="_blank"
            rel="noreferrer"
          >
            Tomislav Pilkić (2021. - 2024.)
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="docs-fin">
            <Link to="/dokumenti">
              DOKUMENTI ZA OSNIVANJE, FUNKCIONIRANJE ILI SAVJETOVANJE UDRUGA
            </Link>
          </div>
          <br />
          <br />
          <div className="docs-fin">
            <Link to="/o-nama">INFORMACIJE O "DOSTA JE BILO"</Link>
          </div>
          <br />
          <br />
          <div className="docs-fin">
            <Link to="/kontakt">KONTAKTIRAJ NAS</Link>
          </div>

          <br />
          <br />

          <div className="corrupt">
            <img
              src={require("../files/nocorruption.png")}
              alt="Dosta je bilo - podrška transparentnosti i borbi protiv mita i korupcije u Hrvatskoj"
              draggable="false"
              className="stopcorr"
              style={{ width: "75%" }}
            />
            <h1>netransparentnosti</h1>
            <h1>malverzacijama</h1>
            <h1>korupciji</h1>
            <h5>
              "Dosta je bilo" od prvih se dana svog djelovanja, još kao
              neformalna inicijativa, vodi načelima transparentnosti,
              izbjegavanja svih oblika nezakonitosti, malverzacija i
              nepravilnosti i najstrože osuđuje bilo kakve koruptivne radnje pa
              čak i njezino opravdanje, toleriranje ili neprijavljivanje.{" "}
            </h5>
            <br />
            <h3>
              KORUPCIJA JE ODVRATNI KARCINOM KOJI UBIJA POVJERENJE U DEMOKRACIJU
            </h3>
            <br />
            <h5>
              Ako imaš bilo kakva saznanja za koruptivne radnje oko sebe,
              nuđenje, primanje mita, sumnju u bilo kakav oblik nepravilnosti na
              bilo kojoj razini oko tebe, imaš za to potvrdu, ali se ne osjećaš
              sigurno prijaviti,{" "}
              <a href="/kontakt">prijavi sumnju na korupciju nama</a> i mi ćemo
              ti pomoći ili odraditi provjeru i prijavu, pazeći potpuno na tvoju
              anonimnost.
            </h5>
            <p>
              Korupcija guši tvoje pravo da živiš bolje. Ne budi sudionik
              šutnjom.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Financije;
