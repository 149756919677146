import React, { useEffect } from "react";
import "./Donate.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import DonateMore from "../components/DonateMore";

function Donate() {
  document.title = "Doniraj \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Dosta je bilo ne obvezuje čak ni članove da plaćaju članarinu, ali ako želiš — možeš pomoći donacijom. Donacije nemaju utjecaja na našu neovisnost."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="donate">
        <Header
          mainLabel="DONIRAJ"
          otherLabel="Nije u šoldima sve, ali oni su naša stvarnost. Iako svi mi volontiramo, pogledaj zašto nam ipak možeš pomoći."
        />
        <DonateMore />
      </div>
      <Footer />
    </div>
  );
}

export default Donate;
