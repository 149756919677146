import React, { useEffect, useRef, useState } from "react";
import "./Admin.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import AdminAddArticle from "../components/AdminAddArticle";

import axios from "axios";

function Admin() {
  document.title = "Prijavi se \u00A0 \u2764 \u00A0Dosta je bilo";

  const loginNameRef = useRef();
  const loginPasswordRef = useRef();

  const [loginName, setLoginName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Prijava u admin-sučelje korisničkim imenom i lozinkom. Moguće uređivati sadržaj i objavljivati članke, vijesti i obavijesti."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  function logAsAdmin(e) {
    console.log("testing...");
    e.preventDefault();

    axios
      .post("http://localhost:7250/login", {
        userLoggedName: loginName,
        userLoggedPassword: loginPassword,
      })
      .then(function (response) {
        console.log(response.data);

        setIsAdmin(true);
        window.scroll(0, 0);

        loginNameRef.current.value = "";
        loginPasswordRef.current.value = "";
      })
      .catch(function (error) {
        console.log(error.message);
        setIsAdmin(false);

        loginNameRef.current.value = "";
        loginPasswordRef.current.value = "";
      });
  }

  return (
    <div>
      <div className="admin">
        <Header
          mainLabel="PRIJAVI SE"
          otherLabel="Da bi mogao dodavati članke i komentare, uređivati ih i brisati, prijavi se podatcima koji su ti dostavljeni posebnim e-mailom"
        />

        {isAdmin && <AdminAddArticle />}

        {!isAdmin && (
          <div className="admin-more">
            <h3 className="admin-label">ADMINISTRACIJA SADRŽAJA</h3>
            <div className="add-shadow">
              <div className="flag-div-red"></div>
              <div className="flag-div-black"></div>
              <div className="flag-div-white"></div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <p className="expl-admin">
              Osobe koje na to imaju pravo mogu pristupiti administracijskom
              sučelju i preko njega upravljati dijelom sadržaja. U većini
              slučajeva to se odnosi na mogućnost pisanja, uređivanja, dopuna i
              izmjene članaka, komentara i obavijesti u ime organizacije.
            </p>

            <p className="expl-admin">
              Da bi to pravo osoba ostvarila, treba se prijaviti korisničkim
              podatcima u sklopu prijavnice koja je ispod. Nužno se prijaviti
              korisničkim imenom i lozinkom koji su oboje alfanumeričke
              vrijednosti koje dodjeljuje "Dosta je bilo". Nakon kreiranja,
              podatci se osobi šalju putem posebnog e-maila sa svim ostalim
              uputama i objašnjenjima.{" "}
            </p>

            <p className="link-admin">
              Za sve ostale upite vezane uz proces prijave, (ne)ispravnost
              podataka za prijavu ili općenito tehničke poteškoće ove vrste na
              ovom dijelu stranice — molimo te da nam se obratiš{" "}
              <a href="mailto:info@dostajebilo.hr">slanjem poruke</a>{" "}
              elektroničkom poštom kako bi netko od kolega to registrirao i
              pokušao riješiti bez odgađanja. Na{" "}
              <a href="/politika-privatnosti"> ovom mjestu </a> možeš vidjeti
              više o načinu na koji provodimo politiku zaštite podataka i kako
              nam se s pitanjima iz tog područja možeš obratiti.
            </p>
            <br />
            <br />
            <br />
            <br />
            <form className="admin-form" onSubmit={logAsAdmin}>
              <h3 className="admin-label">PRIJAVA</h3>
              <input
                type="text"
                id="username"
                name="username"
                required
                ref={loginNameRef}
                onChange={(e) => setLoginName(e.target.value)}
              />
              <label>Unijeti korisničko ime</label>
              <br />
              <br />
              <input
                type="password"
                id="password"
                name="password"
                required
                ref={loginPasswordRef}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
              <label>Unijeti korisničku lozinku</label>
              <br />
              <br />
              <h4 className="auth-msg">
                Trenutni podatci nisu ispravni i ne možeš se prijaviti. Molimo
                te da uneseš ispravne i pritisneš tipku za prijavu ispod.
              </h4>
              <br />
              <br />
              <button type="submit">PRIJAVI ME</button>
              <br />
            </form>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Admin;
