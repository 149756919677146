import React, { useEffect } from "react";
import "./Sitemap.css";

import Footer from "../components/Footer";
import Header from "../components/Header";

import { Link } from "react-router-dom";

function Sitemap() {
  document.title = "Sitemap \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Popis stranica, podstranica, linkova i poveznica (sitemap) na web-stranici Dosta je bilo, www.dostajebilo.hr"
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="sitemap">
        <Header
          mainLabel="SITEMAP"
          otherLabel="Popis poveznica na stranici, uz napomenu da nisu navedene one koje se odnose na internu administraciju, dokumente i druge privatne rute"
        />

        <div className="sitemap-more">
          <div className="site-box">
            <Link to="/">Početna</Link>
            <p>https://dostajebilo.hr</p>
          </div>

          <div className="site-box">
            <Link to="/vijesti">Vijesti</Link>
            <p>https://dostajebilo.hr/vijesti</p>
          </div>

          <div className="site-box">
            <Link to="/kontakt">Kontakt</Link>
            <p>https://dostajebilo.hr/kontakt</p>
          </div>

          <div className="site-box">
            <Link to="/doniranje">Doniraj</Link>
            <p>https://dostajebilo.hr/doniranje</p>
          </div>

          <div className="site-box">
            <Link to="/uclanjenje">Učlani se</Link>
            <p>https://dostajebilo.hr/uclanjenje</p>
          </div>

          <div className="site-box">
            <Link to="/volontiranje">Volontiraj</Link>
            <p>https://dostajebilo.hr/volontiranje</p>
          </div>

          <div className="site-box">
            <Link to="/statut">Statut</Link>
            <p>https://dostajebilo.hr/statut</p>
          </div>

          <div className="site-box">
            <Link to="/program-i-ciljevi">Program</Link>
            <p>https://dostajebilo.hr/program-i-ciljevi</p>
          </div>

          <div className="site-box">
            <Link to="/pravni-koraci">Pravni koraci</Link>
            <p>https://dostajebilo.hr/pravni-koraci</p>
          </div>

          <div className="site-box">
            <Link to="/inicijativa-protiv-stozera">Ne Stožeru</Link>
            <p>https://dostajebilo.hr/inicijativa-protiv-stozera</p>
          </div>
          <div className="site-box">
            <Link to="/financijska-izvjesca">Financije</Link>
            <p>https://dostajebilo.hr/financijska-izvjesca</p>
          </div>
          <div className="site-box">
            <Link to="/dokumenti">Dokumenti</Link>
            <p>https://dostajebilo.hr/dokumenti</p>
          </div>
          <div className="site-box">
            <Link to="/politika-privatnosti">Zaštita podataka</Link>
            <p>https://dostajebilo.hr/politika-privatnosti</p>
          </div>
          <div className="site-box">
            <Link to="/administracija">Prijavi se</Link>
            <p>https://dostajebilo.hr/administracija</p>
          </div>
          <div className="site-box">
            <Link to="/o-nama">Tko smo mi?</Link>
            <p>https://dostajebilo.hr/administracija</p>
          </div>
          <div className="site-box">
            <Link to="/sitemap">Sitemap</Link>
            <p>https://dostajebilo.hr/sitemap</p>
          </div>
          <div className="site-box">
            <Link to="/neispravno">Neispravan URL</Link>
            <p>https://dostajebilo.hr/neispravno</p>
          </div>
        </div>

        <h3 className="last-content">
          Ovdje su navedene osnovne poveznice čiji je sadržaj javno dostupan,
          barem u većoj mjeri. Privatne rute, one za pristup određenim
          dokumentima i internom sadržaju, osobnim profilima i slično — nisu
          dostupni na ovom popisu. <br />
          <br />
          <br /> Isto vrijedi i za podrute (npr. <i>"/route"</i> navedeno,{" "}
          <i>"/route/more"</i> ne). Do njih se ionako dolazi kroz jednostavnu
          navigaciju unutar popisanih stranica. <br />
          <br />
        </h3>
      </div>
      <Footer />
    </div>
  );
}

export default Sitemap;
