import React from "react";
import "./AboutMore.css";
import { Link } from "react-router-dom";

function AboutMore() {
  return (
    <div className="admin-more">
      <h3 className="admin-label no-breaking">Simboli "Dosta je bilo"</h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <img
        src={require("../files/dostaje.png")}
        alt="Pravokutni logo udruge Dosta je bilo"
        draggable="false"
        className="whiten-logo"
      />
      <br />
      <br />
      <p className="expl-admin">
        Glavni simbol i logo "Dosta je bilo" prikazan je iznad na slici.
        Predstavljen je znakom kružnog oblika u kojem se nalaze dvije boje,
        crvena i crna. Crvena je pozicionirana u gornjem, a crna u donjem
        dijelu. Dijelovi s bojama nisu odijeljeni ravnom linijom, već vijugavom,
        valovitom, s jednim dolom na lijevoj strani i jednim brijegom na desnoj.{" "}
        Znak sadrži dvije temeljne boje "Dosta je bilo" koje se često u
        umjetnosti smatraju "oprječnima" i u ovom smislu simboliziraju
        povezivost i potrebu jednakosti svih ljudi pred institucijama. Nema
        nikakve povezanosti ili namjere iste s nekim drugim vjerskim,
        filozofskim, državnim ili ideološkim simbolima.
      </p>
      <br />
      <br />
      <br />
      <br />
      <img
        src={require("../files/dostajeflag.png")}
        alt="Logo udruge Dosta je bilo"
        draggable="false"
        className="whiten-logo-flag"
      />
      <br />
      <br />
      <p className="expl-admin">
        "Dosta je bilo" posjeduje i drugi simbol, a on je pravokutnog oblika,
        pri čemu je horizontalna strana (x-os) dvostruko duža od vertikalne
        (y-osi). Boja tog simbola je bijela, dok se u samom središtu nalazi
        gorespomenuti i opisani prvi simbol, kružnog oblika. Vizualni prikaz
        dostupan je na slici iznad ovog paragrafa.
      </p>
      <br />
      <br />
      <br />
      <br />
      <img
        src={require("../files/dostajecolours.png")}
        alt="Boje udruge Dosta je bilo - crvena #d82020 + crna #111111 + bijela #fffff"
        draggable="false"
        className="whiten-logo-flag"
      />
      <br />
      <br />
      <p className="expl-admin">
        Predstavljamo i prikaz vrijednosti triju osnovnih boja koje
        simboliziraju organizaciju, a koristimo ih u simbolima i prilikom
        dizajniranja. To su RGB-vrijednost (udio crvene, zelene i plave boje —
        red, green, blue), HSL (nijansa, zasićenost i svjetlina — hue,
        saturation, lightness) i heksadecimalni zapis. Naravno, pri izradi
        UI-dizajna (user interface), odnosno vizualnih komponenti sadržaja
        stranice, koristimo i neke druge boje osim crvene, crne i bijele.
      </p>
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        "Dosta je bilo" u nekoliko općih rečenica
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        "Dosta je bilo" nevladina je, neprofitna, aktivistička i jedina potpuno
        neovisna organizacija čiji je temelj želja da društvom u kojem nema
        dovstrukih kriterija i mjerila i u kojem nema privilegiranih skupina za
        koje zakoni ne vrijede. Pred državnim institucijama svaki član društva
        treba biti jednak i zato ćemo bez kompromisa i vrlo oštro reagirati na
        svaki oblik nejednakosti, nepravde i neujednačenog ponašanja u društvu,
        od koga god da dolazio, trudeći se da to ispravimo i nametnemo drukčiju
        realnost.
      </p>
      <p className="expl-admin">
        "Dosta je bilo" čini niz volontera koji marljivo ulažu svoje vrijeme
        kako bi dali pozitivan doprinos društvu u području ili djelatnosti u
        kojima se odlično snalaze svojim ili znanjem, ili iskustvom, ili
        aktivizmom. Volonterima nije dozvoljeno na bilo koji način svojim
        djelovanjem ostavljati dojam da bi "Dosta je bilo" svoje stavove
        iznosila pod pritiskom, utjecajem donacija ili neiskreno na bilo koji
        način.
      </p>
      <p className="link-admin">
        Svi mi u "Dosta je bilo" promoviramo isključivo ciljeve i program naše
        organizacije, u potpunosti odbacujući bilo kakvu povezanost s ostalim
        političkim organizacijama i njihovim programima. U "Dosta je bilo"
        trenutno imamo niz stručnjaka za razna područja, a s odmakom vremena
        strukturu ćemo dodatno jačati. Naši pogledi na svijet potpuno su
        normalni, bez ekstremizma i temelje se na srži našeg djelovanja —
        zdravom razumu. To znači da ćemo svaku pojavu nastojati gledati sa svih
        strana i procjene donositi smireno, razumom, bez određivanja stavova
        unaprijed, prema ideologiji.
      </p>
      <p className="expl-admin">
        Konačno, ono čemu težimo je — biti drukčiji. Drukčije osobe su zapravo
        jedini razlog da danas ne živimo po poljima, u blatu i bez struje. Da
        jesmo drukčiji, pomalo i prgavo držimo svoje stavove bez ustupaka — već
        smo dokazali, ali o tome ćeš pročitati više u nastavku i na ostalim
        dijelovima naše stranice (možeš kliknuti na naš logo u gornjem desnom
        kutu).
      </p>
      <p className="link-admin">
        <h3>PODATCI IZ REGISTRA UDRUGA</h3>
        <br />
        <br />
        <br />
        <br />
        Naziv: Udruga "Dosta je bilo" <br />
        <br />
        Skraćeni naziv: "Dosta je bilo" <br />
        <br />
        Naziv (engl.): Association "It is enough" <br />
        <br />
        Skraćeni naziv (engl.): "It is enough" <br />
        <br />
        Datum upisa u Registar udruga: 15. 2. 2021. <br />
        <br />
        Registarski broj: 21014075 <br />
        <br />
        OIB: 72276005315 <br />
        <br />
        Statut usklađen sa Zakonom o udrugama (NN 74/14, 70/17 i 98/19): da{" "}
        <br />
        <br />
        Odgovorna osoba: Tomislav Pilkić <br />
        <br />
        Likvidator: Tomislav Pilkić <br />
        <br />
        Trenutni status: aktivna <br />
        <br />
        Područje djelovanja: Republika Hrvatska <br />
        <br />
      </p>
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">Kako smo i gdje započeli?</h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        Započeli smo poprilično neformalno i neslužbeno, djelujući preko
        društvenih mreža, prvo u proljeće pa onda drugi put uspješnije u ljeto
        2020. Tada smo kroz grupu na Facebooku naziva "Tražimo ukidanje
        Nacionalnog stožera civilne zaštite" i inicijativu "Dosta je bilo"
        počeli ukazivati na licemjerje vladajućih, kršenje vlastitih pravila
        vezanih uz odgovor na epidemiju i kriterije koje vlast primjenjuje samo
        na građane, dok povlaštenim skupinama od kojih očekuje glasove
        dodjeljuje neprestane i neprestane ustupke. U tom djelovanju bili smo
        uspješni. U trenutku pokretanja grupe 24. srpnja još je uvijek veći dio
        društva imao pozitivniji stav prema Stožeru. Već mjesec-dva iza, ta
        potpora je počela nestajati slobodnim padom, a u javnosti se konačno
        čuti i šira kritika nelogičnosti u ponašanju članova Stožera i Vlade.{" "}
      </p>
      <br />
      <img
        src={require("../files/fbgrupa.png")}
        alt="Tražimo ukidanje Nacionalnog stožera civilne zaštite - grupa na Facebooku u vlasništvu inicijative i naknadno organizacije Dosta je bilo"
        draggable="false"
        className="whiten-logo-flag"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Formalno organiziranje u udrugu i pokretanje niza pravnih koraka
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        Dok je "bitka" na društvenim mrežama išla glatko i grupa je u svega pet
        dana dobila 100 000 članova, kao najbrže rastuća grupa u Hrvatskoj, od
        prvih dana najavljivali smo i počeli provoditi pripreme za organiziranje
        tužbe za zaštitu kolektivnih prava i interesa protiv Republike Hrvatske.
        U početcima smo skupljali popis zainteresiranih podržavatelja kako bismo
        znali procijeniti u kojem ćemo smjeru točno ići, ali i skupljali
        iskustva i razloge nezadovoljstva građana radom Vlade. Glavni koji smo
        čuli je — da rad i mnoge odluke Stožera smatraju teškom uvredom
        vlastitom razumu. Nakon niza pravnih konzultacija i sastanaka, određen
        je početkom jeseni konačni okvir i pokrenut je sljedeći korak nužan za
        predaju kolektivne tužbe. To je osnivanje udruge.
      </p>
      <p className="expl-admin">
        Nakon sređivanja potrebne dokumentacije i pažljivog usklađivanja
        statutarnih odredbi kako bismo imali aktivni legitimitet da tužbu uopće
        možemo pokrenuti (jer kolektivne tužbe udruge podnose pod vrlo strogim i
        ograničavajućim pravilima), početkom prosinca 2020. prihvatili smo
        statut i iza Nove godine, sada i s napisanom tužbom, pokrenuli zahtjev
        prema Gradskom uredu za opću upravu u Zagrebu. Nakon blagog otezanja,
        odobrenje je došlo 15. veljače 2021. i u sljedećim danima pokrenuli smo
        prvi korak, mirenje s državom. Sve ostalo je u posebnoj sekciji ove
        stranice, uz dodatni opis i nekih drugih pravnih radnji, poput
        prijedloga za ocjenom usklađenosti sa zakonima i Ustavom nekih spornih
        odluka Stožera Ustavnom sudu.
      </p>
      <br />
      <img
        src={require("../files/discr.jpeg")}
        alt="Stop diskriminaciji - pokretanje kolektivne tužbe za suzbijanje diskriminacije preko udruge Dosta je bilo prema državi zbog diskriminacije u odlukama Nacionalnog stožera civilne zaštite i Vlade"
        draggable="false"
        className="whiten-logo-flag"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        U međuvremenu smo potpuno prokazali Nacionalni stožer kao stranačko
        tijelo Vlade i premijera
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        Kako su mjeseci prolazili, od ljudi koje su novinski članci i portali
        zvali "superjunacima", članovi Stožera i Vlade postali su ocijenjeni kao
        oosbe koje grubo zloupotrebljavaju tešku situaciju da bi se preko nje
        održavali na vlasti i ljudske živote i sudbine prilagođavali svojim
        potrebama (famozna ukidanja mjera naglo pred izbore pa ubrzo vraćanje).
        Postali su toliko nepopularni da su stvarali osmijehe na svakom koraku i
        pri svakoj pojavi, dok se u drugom dijelu 2021. čak nisu prestali
        pojavljivati češće u javnosti jer nanose štetu rejtingu vladajuće
        koalicije.{" "}
      </p>
      <p className="link-admin">
        Bitnije od svega, javnost je nakon početnih grešaka i neopravdanog
        davanja pretjeranog povjerenja toj skupini doživjela triježnjenje i
        uvidjela da je NSCZ ništa drugo, nego privatno tijelo stranke na vlasti.
        Čak ne toliko i to, koliko i samog premijera Plenkovića, kojemu je
        Stožer služio isključivo za to da mu donosi političke bodove u početku,
        a naknadno da nezadovoljstvo javnosti makne sa sebe.{" "}
      </p>
      <p className="expl-admin">
        Uglavnom, kako ne bismo pretjerano duljili, za sve ostale i puno
        detaljnije informacije o našem djelovanju u početnoj fazi molimo te da
        pogledaš naš logo fiksiran u gornjem desnom kutu stranice i klikneš na
        njega, odnosno stisneš na mobilnim uređajima. U izborniku smo ti
        pripremili sve — koje smo točno i kako pravne korake vodili, kako je to
        teklo, koje smo mane Vlade popisali kroz djelovanje Stožera, koji je naš
        program i doslovno sve ostalo što bi te moglo zanimati.
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Svi u "Dosta je bilo" smo volonteri i nismo u radnom odnosu s
        organizacijom
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        Ljudi koji pomažu organizaciji trenutno su bez iznimke svi odreda
        volonteri "Dosta je bilo", što znači da nemaju agencijski ili bilo kakav
        drugi oblik ugovora o radu. Kroz volonterski status želimo osigurati da
        nam se, osobito u početnim fazama djelovanja, pridruže osobe koje u
        programu i planu organizacije zaista vide nešto drukčije, nešto vrijedno
        toga da bi s nama gradili naše stavove i iznosili ih javno. Želimo
        surađivati s iskrenim osobama, osobama koje doista žele učiniti nešto
        pozitivno za zajednicu, a ne koristiti ovakva udruživanja za vlastito
        bogaćenje, skupljanje novaca za osobne potrebe ili da bi na bilo koji
        drugi način manipulirali građanima, njihovom lošom situacijom ili kakvim
        trećim okolnostima/razlozima.{" "}
      </p>
      <p className="expl-admin">
        U unutrašnjoj organizaciji osigurali smo da većinu radnji potrebnih za
        život udruge odrađujemo sami. To uključuje pripreme i izmjene statuta,
        internih materijala i sav rad na papirologiji i administraciji. Također,
        ova cijela web-stranica https://dostajebilo.hr izrađena je volonterski i
        pod autorskim je pravima izravno same organizacije. Vodimo se politikom
        maksimalne uštede i nerazbacivanja novcem, kako bismo mogli omogućiti da
        nam članovi ne trebaju plaćati članarinu, barem dok strukturu značajno
        ne ojačamo i ne osnažimo, što je u tijeku. Dakle, sve što može biti
        riješeno interno kod nas i ne opterećuje nas vremenski ili nekim drugim
        legitimnim razlozima pretjerano, riješit će netko od nas volontera.
      </p>
      <p className="expl-admin">
        Mi udruge i ostale neprofitne organizacije ne želimo koristiti kao zone
        uhljebljivanja, nerada, parazitiranja i za, popularno rečeno, "spavanje
        do 12". Nama je "Dosta je bilo" organizacija na koju smo jako ponosni i
        koju koristimo samo da bismo dali naš doprinos boljem, uređenijem i
        jednakijem društvu. To smo dosad pokazivali, ali neovisno o vanjskom
        dojmu, ta načela njegujemo interno i držat ćemo ih se sve dok je "Dosta
        je bilo" aktivna.{" "}
      </p>
      <br />
      <img
        src={require("../files/shaking.jpeg")}
        alt="Dvoje ljudi se rukuje - Dosta je bilo (povezanost, suradnja, volontiranje)"
        draggable="false"
        className="whiten-logo-flag"
        style={{
          height: "400px",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Uglavnom smo mlađi, ali ne brini — u čovjeku ne gledamo godine i
        prihvaćamo sve
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        U ovom trenutku, naši su volonteri većinom i u prosjeku ljudi mlađe ili
        srednje životne dobi raznog profila - zaposleni, studenti na doktorskom
        studiju, regularni studenti, IT-jevci, ekonomisti, poduzetnici i
        samozaposleni, osobe s iskustvom rada s ljudima i u marketingu i
        promidžbi, pravnici i ostali. Nikada ne isključujemo i da netko starije
        životne dobi može biti vrlo koristan. Ako i vi imate znanje, vještine i
        općenito se slažete s našim pogledima većinski (navedeni su na
        podstranici s programom i ciljevima u izborniku), možete nam pomoći i
        oblikovati javno mišljenje s nama. Naravno, isto vrijedi i za ostale
        kategorije. Ne zanima nas spol, koji je fakultet završen, koliko godina
        traje zaposlenje i ostalo, sve dok smo na istom putu i u tebi
        prepoznajemo iskrenost i znanje o području u kojem nam želiš pojačati
        linije.
      </p>
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Neovisni smo i takvi želimo ostati. Donacije nam neće mijenjati
        mišljenje. Nikada.
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        Kao i svaka neprofitna organizacija, unatoč politici nerazbacivanja
        novcima i naglasku na volontiranju na korist društva, i mi imamo neke
        troškove koje trebamo podmiriti. Zato, naravno, primamo donacije i o
        tome možeš vidjeti više u zasebnoj rubrici. Međutim, za razliku od
        drugih, ono što nas je "krasilo" tijekom sukoba s Vladom oko licemjerja
        i dvostrukih mjerila Stožera bila je potpuna neovisnost, žestoko
        kritiziranje i prozivanje političara licemjera svih vrsta i boja,
        najviše vladajućih i slobodno iznošenje stavova — kad, kako i na koji
        način želimo.{" "}
      </p>
      <p className="link-admin">
        Sloboda je temelj mnogih stvari u životu, vjerojatno i cijelog života.
        Većina aktualnih stranaka i udruga izrazito su pristrane i izazivaju
        loše osjećaje kod građana, a jedan od razloga tome je baš njihova
        ovisnost o donatorima i glasačkom tijelu kojem se trebaju dodvoravati i
        paziti na istupe, što ih čini umjetnima i neprirodnima. Niti jedna
        organizacija ne može u društvo unijeti bilo kakvu pozitivnu promjenu dok
        predstavlja one koji ih drže novcima, a nikada ne potpuno i dokraja
        iskreno same građane.{" "}
      </p>{" "}
      <br />
      <img
        src={require("../files/freed.jpeg")}
        alt="Ljudska ruka ispružena u smjeru mora - Dosta je bilo (sloboda, neovisnost, samostalnost)"
        draggable="false"
        className="whiten-logo-flag"
        style={{
          height: "375px",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <br />
      <br />
      <br />
      <br />
      <p className="link-admin">
        Stoga, ako u nama zaista prepoznaješ iskrenost, radišnost i želju da
        budemo potpuno drukčiji od svega ostalog u ovoj državi, slobodno nam
        možeš pomoći i puno ti hvala na tome. Ali kao "Dosta je bilo" svoj
        program, djelovanje i ciljeve nećemo mijenjati apsolutno nikada i ni pod
        koju cijenu, ako bi tome trebao biti razlog novac, pritisak ili neki
        treći vanjski izvor.
      </p>
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        ... jer mi smo totalno drukčiji od drugih
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="expl-admin">
        U konačnici, iza svega i svi mi smo samo ljudi i samim time ne možemo
        biti savršeni. Ipak, od prvih dana djelovanje nam je doista drukčije od
        ostalih. Usred epidemije, najveću grupu po tom pitanju nismo
        zloupotrebljavali da bismo fokus napada držali na znanstvenicima,
        pojedincima i slično, iako je i među njima bilo radikala i ekstremista.
        Mi smo jedini glavnog krivca gledali u Vladi i bez straha i hrabro
        njihovom političkom i manipulativnom Stožeru prokazivali stvarni
        karakter.{" "}
      </p>
      <p className="expl-admin">
        Nismo bili kukavice kao pojedinci poput dr. Saše Sriće koji je
        svakodnevno vrijeđao i huškački pričao o sugrađanima, bojeći se čvrsto
        sukobiti s Vladom kao glavnim problemom zbog dvostukih kriterija. Nismo
        bili ni kao oni poput dr. Lauca, koji su koristili nezadovoljstvo
        mjerama da bi hvalili i branili Stožer i Vladu redovito, jer o takvom
        ponašanju imamo izrazito negativne stavove. Nismo bili niti kao dio
        medijskih djelatnika, koji su oštricu prozivanja puno snažnije okrenuli
        ka građanima, nego Vladi.{" "}
      </p>
      <p className="expl-admin">
        Nismo bili ni kao oni treći koji su skretali raspravu s problema
        dvostrukih mjerila i nepravde u institucijama i državi na alternativne
        teorije. Cijelo smo vrijeme u grupi izbjegavali skretanje u taj smjer,
        štiteći naše građane ozbiljno i razumno, bez skupljanja lajkova u
        području s kojim se ne slažemo. I taj smo stav držali cijelo vrijeme.
        Uporno, prgavo, neprevrtljivo. Opet bismo tako postupili, tako ćemo
        postupati i dalje, dok god ćemo postojati kao "Dosta je bilo".{" "}
      </p>
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Htio bi saznati još i više o nama?
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p className="link-admin">
        S obzirom da o sebi i svom radu imamo tako puno toga reći, baš zato i
        imamo ovu stranicu na kojoj sve možeš ispitati. Pozivamo te da najprije
        pogledaš vjerojatno i najbitniji dio i skeleton našeg postojanja i
        djelovanja — <Link to="/program-i-ciljevi">naš program</Link>. Tu ćeš
        naći koji su naši ciljevi, osnovna načela od kojih nema odstupanja,
        smjer djelovanja, vrste akcija, uvjerenja kojima se vodimo, prijedlozi
        kojima mijenjamo društvo i kako planiramo širiti naše djelovanje u
        bližoj budućnosti. Vidjet ćeš da smo i više nego posebniji od svih
        ostalih. U pozitivnom smislu, naravno.
        <br />
        <br />
        Nakon toga, predlažemo da pogledaš{" "}
        <Link to="/financijska-izvjesca">naše poslovanje</Link>. Tu su ti
        dostupna naša godišnja financijska izvješća, najave skupština, istaknute
        velike i značajne promjene i obavijesti vezane uz strukturu i naše
        postojanje i slično. Mlada smo organizacija pa s vremenom očekuj
        redovito i transparentno izvješćivanje, svake godine.
        <br />
        <br />
        <Link to="/statut">Ovdje </Link> možeš pogledati i naš službeni statut.
        <br />
        <br />
        Ako bi možda htio volontirati ili barem za početak vidjeti više o tome
        kako to izgleda i koje volonterske akcije provodimo, onda se{" "}
        <Link to="/volontiranje">prebaci ovdje</Link>, a ako smatraš da nešto
        nije jasno, potpuno, imaš samo prijedlog ili neki strah prije želje da
        nam se pridružiš — nikakav problem, <Link to="/kontakt">ovdje </Link> je
        obrazac koji možeš popuniti i uslijedit će ti naš odgovor podosta brzo.
        <br />
        <br />
        Od svega ostalog, možda i najbitniji dio je naša{" "}
        <Link to="/vijesti">
          rubrika s vijestima, analizama, komentarima, kritikama nepravilnosti i
          prijedlozima za rješenje
        </Link>
        , gdje naši volonteri iznose upravo to što si sada i pročitao/la.
        Vjerujemo da će ti zvučati razumno i zanimljivo i da ćeš nas redovito
        pratiti. Ne samo to, već nam se i pridružiti. Čekamo te.
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="admin-label no-breaking">
        Pogledaj kako stojimo u svijetu prema indeksima (percepcije) demokracije
      </h3>
      <div className="add-shadow">
        <div className="flag-div-red"></div>
        <div className="flag-div-black"></div>
        <div className="flag-div-white"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <iframe
        width="100%"
        height="515"
        src="https://www.youtube.com/embed/zGZSfz1_0xw"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "10px", boxShadow: "0 0 16px" }}
      ></iframe>
      <br />
      <br />
      <br />
      <br />
      <iframe
        width="100%"
        height="515"
        src="https://www.youtube.com/embed/I7YMJKX52NE"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "10px", boxShadow: "0 0 16px" }}
      ></iframe>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="link-admin">
        Kao što si mogao/la vidjeti, naša država itekako ima mjesta za napredak.
        Uostalom, demokracija uvijek može biti bolja i treba ju "vježbati" i
        tamo gdje je najrazvijenija. Odmori malo, a onda nam se javi. Pomoći
        ćemo ti da napreduješ u nekom području i preko nas oblikuješ svoje
        stavove javno. Za neka od područja ti možemo pružiti edukaciju ili barem
        materijale, odnosno smjernice kako bismo se što bolje povezali. S
        obzirom na to da to ovisi o više čimbenika, predlažemo ti da opišeš sebe
        i svoje zamisli pa ćemo to proučiti i povratno ti se javiti.
      </h3>
      <br />
      <br />
      <br />
      <h3
        className="link-admin"
        style={{ backgroundColor: "#d82020", color: "#eee" }}
      >
        {" "}
        <big>
          <big>
            <big>
              <big>PRAVNA NAPOMENA</big>
            </big>
          </big>
        </big>{" "}
        <br />
        <br />
        <br />
        Statut, ciljevi i djelatnosti, odnosno aktivnosti kojima se oni žele
        postići usklađeni u potpunosti sa svim važećim zakonskim propisima
        države, prije svega Zakonom o udrugama (Narodne novine, 74/14, 70/17,
        98/19), sukladno kojima udruga djeluje u pravnom prometu. U Registar
        stvarnih vlasnika kao nadležna osoba upisan je Tomislav Pilkić, temeljem
        Zakona o sprječavanju pranja novca i financiranja terorizma (Narodne
        novine, 108/17 i 39/19). Svi ostali podatci dostupni su na službenoj
        internetskoj stranici dostajebilo.hr ili slanjem upita nekim od
        predviđenih komunikacijskih kanala (npr. e-mail: info@dostajebilo.hr).
        Podatci o poslovanju, poput financijskih izvješća i održavanja sjednica,
        ažurirat će se na https://dostajebilo.hr/poslovanje
      </h3>
      <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <h3 className="admin-label no-breaking">
        Život je putovanje. Pokreni se i kreni na put, a "Dosta je bilo" je tu
        da te podrži.
      </h3>
      <br />
      <h3 className="link-admin">
        Postani <Link to="/volontiranje">volonter</Link> i utječi pozitivno na
        društvo, a ne ono negativno na tebe. Pružit ćemo ti sve potrebno da
        možeš pratiti nas i djelovati s nama. Čekamo te.
      </h3>
      <img
        src={require("../files/doubledecker.jpg")}
        alt="Priprema za trčanje, žena u sportskoj odjeći i obući na teniskom terenu, traka za trčanje"
        draggable="false"
        className="whiten-logo-flag"
      />
    </div>
  );
}

export default AboutMore;
