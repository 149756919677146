import React, { useEffect } from "react";
import "./ZSZG.css";

import Footer from "../components/Footer";
import Header from "../components/Header";

function ZSZG() {
  document.title = "Županijski sud \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Udružna tužba za zaštitu kolektivnih prava i interesa skupina koje su sumnjano oštećene nejednakim postupanjem državnim tijela tijekom 2020. i 2021."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, kolektivna tužba franak, tužba franak, tužba protiv banaka, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="zszg">
        <Header
          mainLabel="ŽUPANIJSKI SUD"
          otherLabel="Udružna tužba za zaštitu kolektivnih prava i interesa skupina koje su sumnjano oštećene nejednakim postupanjem državnim tijela tijekom 2020. i 2021."
        />
      </div>
      <Footer />
    </div>
  );
}

export default ZSZG;
