import React from "react";
import "./DemocracyDay.css";

function DemocracyDay() {
  return (
    <div className="dd">
      <img
        src={require("../files/heart.jpeg")}
        alt="Dosta je bilo - prikaz ljudskih ruku u obliku srca"
        draggable="false"
      />
      <div className="dd-second">
        <h3>Međunarodni dan demokracije</h3>
        <p>15. rujna</p>
        <span>
          Možda nije idealna i nekada nas živcira, ali omogućava nam da
          kritiziramo i mijenjamo i bolje dosad nismo izmislili. Zato ju nemojmo
          rušiti, radije ju učinimo boljom.
        </span>
        <h5>
          Demokracija je jedini sustav koji se pobrine da narod ima vlast kakvu
          zaslužuje.
        </h5>
      </div>
    </div>
  );
}

export default DemocracyDay;
