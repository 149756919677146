import React, { useEffect } from "react";
import "./Error.css";
import Header from "./Header";

import { Link } from "react-router-dom";
import Footer from "./Footer";

function Error() {
  document.title = "Ništa nije nađeno \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Stranica na dostajebilo.hr koja prikazuje da je korisnik unio ili došao na neispravan link, odnosno poveznicu. Poruka s greškom 404."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="error">
        <Header
          mainLabel="NEMA NIČEGA"
          otherLabel="POVEZNICA KOJA JE UNESENA VJEROJATNO JE NEISPRAVNA (POTEŠKOĆA 404)"
        />

        <div className="balt-cont">
          <img
            src={require("../files/baltazar.jpg")}
            alt="Profesor Baltazar - Dosta je bilo, ništa nije pronađeno, greška 404"
            draggable="false"
            className="baltazar"
          />

          <Link to="/" className="link-error">
            NAZAD NA POČETNU
          </Link>
        </div>

        <h3 className="error-h3">
          Došao/la si na poveznicu <span>{window.location.href}</span> koja ne
          sadrži ništa konkretno na sebi. Ako postoje bilo kakve poteškoće pri
          korištenju naše stranice, budi slobodan/na odmah nam uputiti upit kako
          bismo ti odgovorili čim prije. Upute pogledaj ispod.{" "}
        </h3>
      </div>
      <Footer />
    </div>
  );
}

export default Error;
