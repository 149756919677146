import React from "react";
import "./StatuteMore.css";

function StatuteMore() {
  return (
    <div className="statute-more pd">
      <div id="izbornik" className="flexed">
        <a href="#osnovne-odredbe" className="markbook">
          1. Osnovne odredbe
        </a>
        <a href="#ciljevi" className="markbook">
          2. Ciljevi, područja djelovanja, ciljane skupine i djelatnosti udruge
        </a>
        <a href="#javnost" className="markbook">
          3. Javnost djelovanja udruge
        </a>
        <a href="#clanstvo" className="markbook">
          4. Članstvo u udruzi
        </a>
        <a href="#upravljanje" className="markbook">
          5. Upravljanje udrugom i tijela udruge
        </a>
        <a href="#udruzivanje" className="markbook">
          6. Udruživanje udruga i ustrojstveni oblici udruga
        </a>
        <a href="#imovina" className="markbook">
          7. Imovina, način stjecanja i raspolaganja imovinom
        </a>
        <a href="#prestanak-postojanja" className="markbook">
          8. Prestanak postojanja udruge i postupak s imovinom u slučaju
          prestanka postojanja udruge
        </a>
        <a href="#sporovi" className="markbook">
          9. Način rješavanja sporova i sukoba interesa unutar udruge
        </a>
        <a href="#zavrsne-odredbe" className="markbook">
          10. Prijelazne i završne odredbe
        </a>
        <a href="#zadnje" className="markbook">
          Statut u PDF-u (Registar udruga)
        </a>
      </div>
      <br />
      <br />
      <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Na temelju članka 13. Zakona o udrugama (′′Narodne novine′′ broj 74/14,
        70/17 i 98/19), Skupština Udruge “Dosta je bilo” na redovitoj godišnjoj
        sjednici održanoj 30. svibnja 2022. u Zagrebu donijela je
      </h4>
      <br />
      <br />
      <h3 className="pd-label-stat">Statut</h3>
      <h3 className="pd-label-name">Udruga “Dosta je bilo”</h3>
      <br />
      <br />
      <br />
      <h3 id="osnovne-odredbe" className="pd-label">
        I. OSNOVNE ODREDBE
      </h3>
      <br />
      <h5>Članak 1.</h5>
      <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ovim se Statutom uređuje naziv udruge, skraćeni naziv, naziv na stranom
        jeziku, skraćeni naziv na stranom jeziku; sjedište udruge; teritorij
        djelovanja udruge; zastupanje; znak udruge i njegov izgled, izgled
        pečata udruge; ciljevi udruge; područja djelovanja udruge sukladno
        ciljevima; ciljane skupine udruge; djelatnosti kojima se ostvaruju
        ciljevi udruge; način osiguranja javnosti djelovanja udruge; uvjeti i
        način učlanjivanja u udrugu, prestanak članstva, prava, obveze i
        odgovornosti članova, stegovna odgovornost članova i način vođenja
        popisa članova; tijela udruge, njihov sastav i način sazivanja sjednica,
        izbor, opoziv, ovlasti, način odlučivanja i trajanje mandata u tijelima
        upravljanja i način sazivanja skupštine u slučaju isteka mandata; izbor
        i opoziv likvidatora udruge; prestanak postojanja udruge; imovina udruge
        i način stjecanja i raspolaganja imovinom udruge; postupak s imovinom u
        slučaju prestanka udruge; način rješavanja sporova i sukoba interesa
        unutar udruge i druga pitanja od značaja za Udrugu “Dosta je bilo”.
      </h4>
      <br /> <h5>Članak 2.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Naziv udruge glasi: Udruga “Dosta je bilo” (u daljnjem tekstu: Udruga).{" "}
        <br />
        <br />
        Skraćeni naziv Udruge glasi: DOSTA JE BILO. <br />
        <br />
        Naziv Udruge na engleskom jeziku glasi: Association “It is enough”.{" "}
        <br /> <br /> Skraćeni naziv Udruge na engleskom jeziku glasi: IT IS
        ENOUGH.
      </h4>
      <br /> <h5>Članak 3.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Sjedište Udruge je u Zagrebu. <br />
        <br />
        Odluku o adresi i promjeni adrese sjedišta Udruge donosi Skupština
        Udruge po važećim uvjetima za donošenje ostalih odluka. <br />
        <br /> Udruga djeluje na području Republike Hrvatske, a odlukom
        Skupštine sukladno njenim ovlastima može se donijeti odluka o
        međunarodnom djelovanju u vidu konkretne programske suradnje s inozemnim
        udrugama i programski povezanim organizacijama.
      </h4>
      <br /> <h5>Članak 4.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udrugu zastupa Predsjednik Udruge, a u slučaju njegove spriječenosti ili
        njegovim ovlaštenjem i Dopredsjednik Udruge, ovlašten sukladno Statutu.
      </h4>
      <br /> <h5>Članak 5.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga ima znak. Znak udruge čini krug u kojem gornjom polovinom
        dominira crvena boja, a donjom crna. Heksadecimalna oznaka crvene
        nijanse je #d82020, dok je crne #111111. Granica između tih dviju
        polovina nije pravocrtna, već je predstavljena valovitom vijugavom
        linijom koja predstavlja val s jednim brijegom (desno) i jednim dolom
        (lijeva strana kruga). Prikaz znaka je odmah u nastavku ispod ovog
        opisa.
        <br />
        <br />
        Uz primjenu ovog, dozvoljeno je službeno korištenje i pravokutnog znaka
        kojemu je horizontalna stranica (x-os) dvostruko duža od vertikalne
        (y-osi). Boja tog znaka je bijela (heksadecimalna oznaka: #ffffff), dok
        se u samom središtu bijele pozadine nalazi okrugli primarni znak koji je
        već određen u ovom članku. Prikaz ovog simbola ispod je prvoga, ispod u
        nastavku.
      </h4>
      <img
        src={require("../files/dostaje.png")}
        alt="Logo i simbol Dosta je bilo"
        draggable="false"
      />
      <br />
      <br />
      <br />
      <div className="flag-spread">
        <img
          src={require("../files/dostajeflag.png")}
          alt="Logo i simbol Dosta je bilo"
          draggable="false"
          className="flag-spread"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br /> <h5>Članak 6.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga ima pečat. Pečat Udruge je pravokutnog oblika, sa znakom odnosa
        dimenzija i izgleda ekvivalentnim onom Udruge iz članka 5. u
        crno-bijeloj boji i u samoj sredini pravokutnika. Duljina veće stranice
        pravokutnog oblika je 50 mm. <br />
        <br />
        Pečat čuva i ovlašten ga je koristiti Predsjednik Udruge i drugi članovi
        Udruge koje on za to ovlasti pisanim putem.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="ciljevi" className="pd-label">
        II. CILJEVI, PODRUČJA DJELOVANJA, CILJANE SKUPINE I DJELATNOSTI UDRUGE
      </h3>
      <br /> <h5>Članak 7.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ciljevi Udruge su: <br />
        <br />
        <ul>
          <li>
            zaštita kolektivnih prava i interesa građana pogođenih mjerama i
            odlukama Nacionalnog stožera civilne zaštite (opće populacije,
            radnika, poslodavaca/poduzetnika/obrtnika, pacijenata i sl.),
          </li>{" "}
          <br />
          <li>
            zaštita prava na jednako postupanje prema svim skupinama i pravno
            sankcioniranje moguće diskriminacije proizašle iz nerazmjernosti
            odluka Nacionalnog stožera kao tijela Vlade Republike Hrvatske,
          </li>{" "}
          <br />
          <li>
            očuvanje demokratskih postignuća, posebice usred događaja ili kriza
            čiji učinci mogu dovesti do degradacije postignutog stupnja i
            pokušaja upotrebe restriktivnih mjera (primjer aktualne epidemije
            uzrokovane širenjem bolesti COVID-19),
          </li>
          <br />
          <li>
            korištenje pravnih, promidžbenih i svih drugih sa zakonima i Ustavom
            RH usklađenih aktivnosti u cilju ukazivanja na postupanja
            neusklađena s valjanim zakonskim propisima i Ustavom RH i pokretanje
            sudskih postupaka radi zaštite kolektivnih interesa i prava osoba u
            Republici Hrvatskoj;
          </li>{" "}
          <br />
          <li>
            stvaranje institucionalnog okvira za fizičke i pravne osobe koje
            žele biti sudionici navedenih aktivnosti, a u skladu s time i
            davanje javnog prostora osobama i pojedincima koji ukazuju na
            poteškoće u demokratskom funkcioniranju sustava,
          </li>{" "}
          <br />
          <li>
            poticanje cjelokupne populacije na razvijanje svijesti o potrebi
            brige i edukaciji o vlastitim pravima,
          </li>{" "}
          <br />
          <li>
            praćenje djelovanja institucija i aktivno ukazivanje na propuste i
            zloupotrebu u tumačenju svojih ovlasti i zakonskih propisa (posebno
            kroz pravne procese iz treće alineje ovog članka),
          </li>{" "}
          <br />
          <li>
            promicanje i podupiranje svih ostalih aktivnosti i postupaka koji su
            u skladu sa Statutom, a nikako ne smiju biti usmjereni na znanost i
            medicinu, posebno ne na promoviranje alternativnih i neslužbenih
            pogleda na iste.
          </li>
        </ul>
      </h4>
      <br />
      <br />
      <br /> <h5>Članak 8.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Područja djelovanja Udruge sukladno ciljevima su: <br />
        <br />
        <ul>
          <li>
            demokratska politička kultura: obrazovanje za demokratsko
            građanstvo, organiziranje i provedba arbitražnih postupaka i
            postupaka mirenja, poticanje participativne demokracije/sudjelovanja
            građana u odlučivanju,
          </li>
          <br />
          <li>
            ljudska prava: - suzbijanje i zaštita od diskriminacije, zaštita
            prava i dostojanstva radnika i zaštita prava pacijenata, - zaštita
            prava potrošača.
          </li>
        </ul>
      </h4>
      <br />
      <br />
      <br /> <h5>Članak 9.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Djelatnosti kojima se ostvaruju ciljevi Udruge su: <br />
        <br />
        <ul>
          <li>
            okupljanje i organiziranje fizičkih i pravnih osoba radi zaštite
            kolektivnih prava i interesa pogođenih mjerama i odlukama
            Nacionalnog stožera civilne zaštite,
          </li>{" "}
          <br />
          <li>
            pokretanje postupaka radi zaštite prava na jednako postupanje prema
            svim skupinama, radi pravnog sankcioniranja moguće diskriminacije
            proizašle nerazmjernošću odluka Nacionalnog stožera kao tijela Vlade
            Republike Hrvatske,
          </li>{" "}
          <br />
          <li>
            pokretanje tužbe za zaštitu kolektivnih interesa i prava predviđenih
            posebnim zakonima (npr. Zakon o radu, Zakon o zaštiti pacijenata,
            Zakon o zaštiti potrošača i dr.), odnosno Ustavom Republike
            zajamčenih ljudskih i inih prava fizičkih i pravnih osoba na način
            kako je to propisano člankom 502.a Zakona o parničnom postupku,
          </li>{" "}
          <br />
          <li>
            organizacija svih zakonima i Ustavom Republike Hrvatske dopuštenih
            aktivnosti usmjernih ka ostvarivanju ciljeva Udruge.
          </li>
        </ul>
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="javnost" className="pd-label">
        III. JAVNOST DJELOVANJA UDRUGE
      </h3>
      <br /> <h5>Članak 10.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Rad Udruge je javan. Javnost djelovanja Udruge osigurava se: <br />
        <br />
        <ul>
          <li>
            izvješćivanjem članova o radu Udruge na sjednicama tijela Udruge,
          </li>{" "}
          <br />
          <li>
            javnošću sjednica tijela Udruge, osim iznimno, kada su sjednice
            zatvorene za javnost,
          </li>{" "}
          <br />
          <li>
            izradom i objavljivanjem godišnjih i projektnih opisnih i
            financijskih izvještaja o radu Udruge,
          </li>{" "}
          <br />
          <li>
            korištenjem informacijsko-komunikacijskih tehnologija (internetske
            stranice, društvene mreže i slično),
          </li>{" "}
          <br />
          <li>javnim priopćavanjem,</li>
          <br />
          <li>organiziranjem posebnih događanja,</li>
          <br />
          <li>na druge odgovarajuće načine.</li>
        </ul>
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="clanstvo" className="pd-label">
        IV. ČLANSTVO U UDRUZI
      </h3>
      <br /> <h5>Članak 11.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članom Udruge može postati svaka fizička i pravna osoba koja je
        zainteresirana za rad u Udruzi i pružanje doprinosa ostvarivanju ciljeva
        Udruge i prihvaća odredbe Statuta. Fizička ili pravna osoba koja je
        zainteresirana za primanje u članstvo Udruge podnosi pisani zahtjev
        Predsjedniku Udruge.
      </h4>
      <br /> <h5>Članak 12.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članom Udruge se postaje danom uplate pristupničke članarine.
        Predsjednik Udruge po primitku potvrde ili jasnog dokaza o uplati iznosa
        šalje pisanu obavijest uplatitelju o primanju u članstvo Udruge.
        Uplaćeni se novčani iznos ne vraća uplatitelju u slučaju podnošenja
        pisanog zahtjeva za iščlanjenjem iz Udruge. Nastavno na navedeno,
        zahtjev za iščlanjenjem iz Udruge (jednako tako i učlanjenjem) podnosi
        se primarno pisanim putem uz navođenje potrebnih podataka. <br />
        <br />
        Svaku sljedeću kalendarsku godinu obveza plaćanja članarine ne postoji.
        <br />
        <br />
        Jednako tako, znatnu pomoć i poticaj radu činile bi i pojedinačne uplate
        (donacije i sva ostala financijska pomoć u skladu s mogućnostima
        potencijalnog uplatitelja). <br />
        <br />
        Skupština Udruge određuje godišnji iznos pristupničke članarine na
        prijedlog Predsjednika Udruge.
      </h4>
      <br /> <h5>Članak 13.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Predsjednik Udruge iznimno može potencijalnom članu odobriti plaćanje
        nižeg iznosa pristupničke članarine, pisanom ili usmenom potvrdom.
      </h4>
      <br /> <h5>Članak 14.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga vodi popis svojih članova. Popis članova vodi se elektronički i
        sadrži podatke o osobnom imenu ili nazivu člana, osobnom
        identifikacijskom broju (OIB), datumu rođenja, datumu pristupanja Udruzi
        i datumu prestanka članstva u Udruzi. <br />
        <br />
        Osim zadnjeg navedenog, ostali se podatci šalju prilikom podnošenja
        zahtjeva za učlanjenjem u Udrugu ili pokretanjem bilo kojeg drugog
        pisanog zahtjeva ili radnje, radi osiguravanja identifikacije člana.{" "}
        <br />
        <br />
        Popis članova dostupan je na uvid svim članovima Udruge i nadležnim
        tijelima, na njihov pisani zahtjev. Popis članova vodi Predsjednik
        Udruge. <br />
        <br />
        Komunikacija pisanim putem izvršava se elektroničkom poštom. Službena
        elektronička adresa Udruge je info@dostajebilo.hr, a službena
        internetska stranica https://dostajebilo.hr. Promjene kontaktnih
        podataka, njihovu nadopunu ili izmjenu donosi Predsjednik Udruge uz
        potvrdu većine glasova svih članova Skupštine Udruge.
      </h4>
      <br /> <h5>Članak 15.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prava, obveze i odgovornosti članova Udruge su: <br />
        <br />
        <ul>
          <li>aktivno sudjelovati u upravljanju poslovima Udruge,</li> <br />
          <li>birati i biti birani u tijela upravljana Udrugom,</li>
          <br />
          <li>biti informirani o aktivnostima Udruge,</li> <br />
          <li>
            aktivno sudjelovati u provedbi aktivnosti i nadzoru rada Udruge,
          </li>{" "}
          <br />
          <li>
            u obavljanju djelatnosti Udruge poštovati odredbe zakona i ovog
            Statuta,
          </li>{" "}
          <br />
          <li>platiti pristupničku članarinu.</li>
        </ul>
      </h4>
      <br />
      <br />
      <br /> <h5>Članak 16.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članstvo u udruzi prestaje: <br />
        <br />
        <ul>
          <li>prestankom postojanja Udruge,</li>
          <br />
          <li>
            istupanjem, danom podnošenja potpisane izjave o istupanju iz
            članstva Predsjedniku Udruge,
          </li>
          <br />
          <li>
            isključenjem zbog povrede članskih obveza i odgovornosti,
          </li>{" "}
          <br />
          <li>smrću člana.</li>
        </ul>{" "}
        <br />
        <br />
        Članstvo službeno prestaje slanjem pisane obavijesti članu koji je
        istupio.
      </h4>
      <br />
      <br />
      <br /> <h5>Članak 17.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članovi Udruge preuzimaju obveze i odgovornosti koje proizlaze iz Zakona
        i drugih pravnih propisa, ovog Statuta i drugih akata Udruge. <br />
        <br />
        Članovi Udruge stegovno odgovaraju za povredu članskih obveza i
        odgovornosti: <br />
        <br />
        <ul>
          <li>kršenje zakonskih i drugih obveza u svezi djelovanja Udruge,</li>{" "}
          <br />
          <li>nepoštovanje odredaba Statuta,</li> <br />
          <li>nepoštovanje odluka tijela upravljanja Udruge,</li> <br />
          <li>neizvršavanje preuzetih obveza,</li> <br />
          <li>
            nesavjesno izvršavanje obveza zbog čega je nastala materijalna šteta
            Udruzi,
          </li>{" "}
          <br />
          <li>nesavjesno ili nezakonito raspolaganja imovinom Udruge,</li>{" "}
          <br />
          <li>narušavanje ugleda Udruge.</li>
        </ul>
      </h4>{" "}
      <br />
      <br />
      <br /> <h5>Članak 18.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Stegovna odgovornost članova Udruge utvrđuje se u stegovnom postupku.{" "}
        <br />
        <br />
        Stegovni postupak može pokrenuti Predsjednik Udruge ili trećina članova
        Skupštine Udruge pisanim zahtjevom s identifikacijskim podatcima danima
        pri učlanjenju.
        <br />
        <br />
        Stegovni postupak vodi i stegovne mjere izriče Predsjednik Udruge, uz
        pravo kažnjenog člana na dobivanje detaljnog pisanog obrazloženja u roku
        od 15 dana i žalbeni postupak, sukladno Statutu.
      </h4>
      <br /> <h5>Članak 19.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U stegovnom postupku utvrđuju se okolnosti uslijed kojih je došlo do
        povrede članskih obveza i odgovornosti, težina povrede i šteta nastala
        za Udrugu.
      </h4>
      <br /> <h5>Članak 20.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Od trenutka prijave, stegovni se postupak pokreće u roku od osam dana.
        Predsjednik Udruge upoznaje se sa zaprimljenim slučajem i donosi pisanu
        odluku uz obvezu navođenja argumentiranog obrazloženja. Bez spomenutog
        uvjeta nije moguće primijeniti sankcije članu. Isto je očitovanje
        dostupno pokretačima postupka na njihov zahtjev. <br />
        <br /> Uvjete i način provođenja ovakvog postupka može mijenjati
        isključivo Skupština.
      </h4>
      <br /> <h5>Članak 21.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Za utvrđenu povredu članskih obveza i odgovornosti mogu se izreći
        sljedeće stegovne mjere: opomena ili isključenje iz Udruge. <br />
        <br />
        Opomena se uvijek određuje kao prvi korak, osim u slučajevima kada bi
        procjena Predsjednika Udruge bila takva da ona ne bi ispunila svrhu i
        potrebe sankcioniranja člana u odnosu na težinu povrede statutarnih
        pravila.
      </h4>
      <br /> <h5>Članak 22.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Član Udruge kojem je izrečena stegovna mjera isključenja iz Udruge ima
        pravo u roku od 15 dana, računajući od dana dostave odluke, podnijeti
        žalbu Skupštini Udruge. Skupština Udruge je dužna riješiti žalbu u roku
        30 dana, računajući od dana dostave žalbe. Odluka Skupštine Udruge o
        isključenju je konačna.
      </h4>
      <br /> <h5>Članak 23.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Svaki član Udruge ima pravo pisanim putem upozoriti Predsjednika Udruge
        ili Skupštinu Udruge na nepoštovanje odredbi Statuta ili nepravilnosti u
        provedbi odluka tijela Udruge. Svaki član Udruge ima pravo pismene
        pritužbe na rad pojedinih članova ili tijela Udruge. Na upozorenja i
        pritužbe člana Predsjednik Udruge dužan je odgovoriti pismeno u roku 30
        dana od njihova primitka.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="upravljanje" className="pd-label">
        V. UPRAVLJANJE UDRUGOM I TIJELA UDRUGE
      </h3>
      <br /> <h5>Članak 24.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članovi Udruge upravljaju Udrugom neposredno na Skupštini i putem
        izabranih predstavnika u drugim tijelima Udruge.
      </h4>
      <br /> <h5>Članak 25.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Tijela Udruge su: <br />
        <br />
        <ul>
          <li>Skupština Udruge (ili skraćeno Skupština),</li> <br />
          <li>Predsjednik Udruge (ili skraćeno Predsjednik),</li> <br />
          <li>Dopredsjednik Udruge (ili skraćeno Dopredsjednik).</li>
        </ul>
      </h4>
      <br />
      <br />
      <br /> <h5>Članak 26.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Skupština je najviše tijelo upravljanja Udrugom. <br />
        <br />
        Skupštinu čine Predsjednik i Dopredsjednik Udruge i svi poslovno
        sposobni članovi fizičke osobe te predstavnik pravne osobe članice
        Udruge koji su se pri učlanjenju (ili bilo kada naknadno) nedvosmisleno
        i jasno izjasnili da žele biti članovi Skupštine Udruge o čemu
        evidenciju vodi Predsjednik Udruge. Uvid u evidenciju članova Skupštine
        Udruge Predsjednik Udruge je dužan omogućiti članovima na njihov
        zahtjev, sukladno ovom Statutu. <br />
        <br />
        Predsjednik i Dopredsjednik Udruge mogu odbiti zahtjev osobe koja želi
        biti član Skupštine i isto pisanim putem obrazložiti toj osobi, ako
        postoji osnovana sumnja da bi djelovala suprotno ciljevima iz Statuta.
      </h4>
      <br /> <h5>Članak 27.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Skupština može biti redovita, izborna i izvanredna. Skupština redovito
        zasjeda jednom u tijeku godine, dok se izborna sjednica Skupštine
        održava svake četiri godine.
      </h4>
      <br /> <h5>Članak 28.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Sjednice skupštine saziva i vodi Predsjednik Udruge. <br />
        <br /> U slučaju njegove spriječenosti, sjednicu Skupštine saziva i vodi
        Dopredsjednik Udruge kojega ovaj ovlasti pisanom potvrdom. <br />
        <br /> Skupština se saziva najmanje osam dana prije održavanja sjednice
        pismenim pozivom koji se upućuje svim članovima Skupštine, a on može
        biti upućen i putem službenih sredstava priopćavanja Udruge, sukladno
        Statutu. Poziv na sjednicu sadrži prijedlog dnevnog reda sjednice, dan i
        mjesto održavanja sjednice. <br />
        <br />U slučaju vremenskih, epidemioloških ili drugih opravdanih
        okolnosti, predsjednik Udruge može donijeti odluku da se sjednica
        Skupštine održi na daljinu (videovezom).
      </h4>
      <br /> <h5>Članak 29.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Izvanrednu sjednicu Skupštine saziva Predsjednik Udruge na vlastitu
        inicijativu ili obvezno na zahtjev najmanje jedne trećine svih članova
        Skupštine Udruge. U svom zahtjevu za sazivanje izvanredne sjednice
        Skupštine predlagatelji su obvezni predložiti dnevni red sjednice.{" "}
        <br />
        <br />
        Ako Predsjednik Udruge ne sazove izvanrednu sjednicu Skupštine u roku od
        osam dana od dana dostave zahtjeva iz stavka 1. ovoga članka, sazvat će
        je predlagatelji. Postupak sazivanja izvanredne sjednice Skupštine
        istovjetan je postupku opisanom u prethodnom članku (28.).
      </h4>
      <br /> <h5>Članak 30.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju isteka mandata tijelima Udruge, sjednicu Skupštine saziva
        zadnja osoba ovlaštena za zastupanje upisana u Registar udruga ili jedna
        trećina svih članova Skupštine Udruge koji su upisani u popis članova
        Udruge i evidenciju članova Skupštine Udruge.
      </h4>
      <br /> <h5>Članak 31.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Skupština pravovaljano odlučuje prisutnim članovima Skupštine Udruge.{" "}
        <br />
        <br />
        Skupština donosi odluke većinom glasova nazočnih članova, ako Statutom
        nije određena posebna većina. <br />
        <br />
        Odluka o prestanku djelovanja Udruge donosi se dvotrećinskom većinom
        svih članova Skupštine Udruge. <br />
        <br />
        Glasovanje na sjednicama Skupštine je javno. Skupština može odlučiti da
        se o pojedinim pitanjima glasuje tajno. O radu sjednice vodi se zapisnik
        koji se trajno čuva u arhivi Udruge.
      </h4>
      <br /> <h5>Članak 32.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Skupština Udruge ima sljedeća prava i obveze: <br />
        <br />
        <ul>
          <li>usvaja Statut Udruge i njegove izmjene i dopune,</li> <br />
          <li>bira i razrješuje Predsjednika i Dopredsjednika,</li> <br />
          <li>imenuje i opoziva likvidatora Udruge,</li> <br />
          <li>
            odlučuje o udruživanju u saveze, zajednice, mreže i druge oblike
            povezivanja udruga,
          </li>{" "}
          <br />
          <li>
            usvaja plan rada i financijski plan za sljedeću kalendarsku godinu i
            izvješće o radu za prethodnu kalendarsku godinu,
          </li>{" "}
          <br />
          <li>usvaja godišnje financijsko izvješće,</li> <br />
          <li>
            odlučuje o promjeni ciljeva i djelatnosti, prestanku rada i
            raspodjeli preostale imovine Udruge,
          </li>{" "}
          <br />
          <li>donosi odluku o statusnim promjenama,</li> <br />
          <li>
            odlučuje i o drugim pitanjima za koja statutom nije utvrđena
            nadležnost drugih tijela Udruge.
          </li>
        </ul>
      </h4>{" "}
      <br />
      <br />
      <br /> <h5>Članak 33.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Predsjednik Udruge ovlašten je za zastupanje Udruge. <br />
        <br />
        Predsjednik Udruge obavlja izvršne i druge poslove određene ovim
        Statutom.
      </h4>
      <br /> <h5>Članak 34.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Predsjednika Udruge bira Skupština Udruge na mandat od četiri godine.
        Ista osoba može više puta biti birana na tu poziciju. <br />
        <br /> Za svoj rad, Predsjednik Udruge je odgovoran Skupštini Udruge
        kojoj podnosi godišnje izvješće o svom radu.
      </h4>
      <br /> <h5>Članak 35.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Predsjedniku Udruge može prestati mandat prije isteka vremena na koje je
        biran: <br />
        <br />
        <ul>
          <li>na vlastiti zahtjev,</li> <br />
          <li>
            u slučaju nemogućnosti obavljanja dužnosti duljem od šest mjeseci,
          </li>{" "}
          <br />
          <li>opozivom.</li>
        </ul>
      </h4>{" "}
      <br />
      <br />
      <br /> <h5>Članak 36.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Do opoziva Predsjednika Udruge može doći zbog razloga navedenih u članku
        17. ovog Statuta. <br />
        <br />
        Postupak za opoziv Predsjednika Udruge može se pokrenuti na zahtjev
        najmanje jedne polovine svih članova Skupštine Udruge. <br />
        <br />
        O zahtjevu za opoziv Predsjednika Udruge raspravlja se i odlučuje na
        izvanrednoj sjednici Skupštine Udruge koja se saziva u skladu s
        odredbama ovog Statuta. <br />
        <br />
        Predsjednik Udruge bit će opozvan ako je odluka o opozivu donesena
        dvotrećinskom većinom svih članova Skupštine.
      </h4>
      <br /> <h5>Članak 37.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju prestanka mandata Predsjednika Udruge prije isteka vremena na
        koje je biran, provodi se postupak izbora novog Predsjednika Udruge za
        preostali dio mandata na izvanrednoj sjednici Skupštine Udruge. U
        slučaju opoziva Predsjednika Udruge, na sjednici na kojoj je isti
        opozvan bira se novi Predsjednik Udruge za preostali dio mandata.
      </h4>
      <br /> <h5>Članak 38.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Predsjednik Udruge ima sljedeća prava i obveze: <br />
        <br />
        <ul>
          <li>odgovara za zakonitost rada Udruge,</li> <br />
          <li>vodi poslove Udruge sukladno odlukama Skupštine,</li> <br />
          <li>
            odgovoran je za podnošenje godišnjeg financijskog izvješća
            Skupštini,
          </li>{" "}
          <br />
          <li>
            dostavlja zapisnik s redovne sjednice Skupštine nadležnom uredu koji
            vodi Registar udruga,
          </li>{" "}
          <br />
          <li>
            sklapa ugovore i poduzima druge pravne radnje u ime i za račun
            Udruge,
          </li>{" "}
          <br />
          <li>
            obavlja i druge poslove u skladu sa zakonom, Statutom i aktima
            Udruge,
          </li>{" "}
          <br />
          <li>predlaže izmjene i dopune Statuta Udruge,</li> <br />
          <li>predlaže projekte i programe Udruge,</li> <br />
          <li>
            koordinira pravne radnje vezane uz pravne procese (članak 7.
            Statuta).
          </li>
        </ul>
      </h4>{" "}
      <br />
      <br />
      <br /> <h5>Članak 39.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju odsutnosti ili spriječenosti Predsjednika Udruge, u svim
        poslovima zamjenjuje ga Dopredsjednik Udruge. Odredbe ovog Statuta o
        izboru, trajanju mandata i prestanku mandata Predsjednika Udruge na
        odgovarajući se način primjenjuju na odredbe o Dopredsjedniku Udruge.
      </h4>
      <br /> <h5>Članak 40.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga može imati jednog Dopredsjednika kojeg bira Skupština Udruge na
        mandat četiri godine. Predsjednik može ovlastiti Dopredsjednika za
        obavljanje pojedinih poslova u slučaju njegove spriječenosti i
        onemogućenosti ili potrebe za pomoći.
      </h4>
      <br /> <h5>Članak 41.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga može osnivati tijela potrebna za ostvarivanje ciljeva Udruge u
        skladu sa Statutom. <br />
        <br />
        Odluku o osnivanju tijela donosi Predsjednik Udruge, a uz obveznu
        potvrdu Skupštine. <br />
        <br />U odluci o osnivanju tijela utvrđuje se sastav tijela, ciljevi
        osnivanja, prava i obveze tijela i članova tijela i vrijeme na koje se
        tijelo osniva.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="udruzivanje" className="pd-label">
        VI. UDRUŽIVANJE UDRUGA I USTROJSTVENI OBLICI UDRUGA
      </h3>
      <br /> <h5>Članak 42.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga se može udruživati u domaće i međunarodne saveze ili zajednice
        udruga i učlanjivati se u domaće i međunarodne udruge i institucije.{" "}
        <br />
        <br />
        Odluku o udruživanju i učlanjivanju donosi Skupština na prijedlog
        Predsjednika Udruge.
      </h4>
      <br /> <h5>Članak 43.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga može osnovati podružnice u drugim gradovima na području Republike
        Hrvatske. Podružnice se osnivaju radi ostvarenja ciljeva i učinkovitijeg
        djelovanja Udruge. Podružnice nemaju svojstvo pravne osobe. <br />
        <br />
        Odluku o osnivanju i ukidanju podružnica donosi Skupština na prijedlog
        Predsjednika Udruge.
      </h4>
      <br /> <h5>Članak 44.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Unutarnji ustroj, nadležnost i ostala prava i obveze podružnica uređuju
        se Pravilnikom o radu podružnica. Pravilnik o radu podružnica mora biti
        u skladu sa Statutom, a donosi ga Skupština na prijedlog Predsjednika
        Udruge.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="imovina" className="pd-label">
        VII. IMOVINA, NAČIN STJECANJA I RASPOLAGANJA IMOVINOM
      </h3>
      <br /> <h5>Članak 45.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Imovinu Udruge čine novčana sredstva koja je udruga stekla uplatom
        članarina, dobrovoljnim prilozima i darovima, novčana sredstva koja
        Udruga stekne obavljanjem djelatnosti kojima se ostvaruju ciljevi,
        financiranjem programa i projekata Udruge iz državnog proračuna i
        proračuna jedinica lokalne i područne (regionalne) samouprave i fondova
        i/ili inozemnih izvora, druga novčana sredstva stečena u skladu sa
        zakonom, njezine nepokretne i pokretne stvari i druga imovinska prava.
      </h4>
      <br /> <h5>Članak 46.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga raspolaže svojom imovinom samo za ostvarivanje ciljeva i
        obavljanje djelatnosti određenih Statutom, u skladu sa zakonom. <br />
        <br />O raspolaganju imovinom odlučuje Predsjednik Udruge i o tomu
        sastavlja zapisnik, isključivo u najboljem interesu za Udrugu i njezine
        članove. Svaka odluka treba biti javna, odnosno dostupna kroz pisano
        obrazloženje na zahtjev pojedinog člana elektroničkom poštom.
        Predsjednik je dužan medijima i javnosti iznijeti obrazloženje
        financijskih odluka u slučaju postojanja zanimanja za to. Rok za sve
        navedene zahtjeve iz ovog članka je osam dana.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="prestanak-postojanja" className="pd-label">
        VIII. PRESTANAK POSTOJANJA UDRUGE I POSTUPAK S IMOVINOM U SLUČAJU
        PRESTANKA POSTOJANJA UDRUGE
      </h3>
      <br /> <h5>Članak 47.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Udruga prestaje postojati: <br />
        <br />
        <ul>
          <li>odlukom Skupštine Udruge o prestanku Udruge,</li> <br />
          <li>
            odlukom Skupštine Udruge o pripajanju drugoj udruzi, spajanju s
            drugom udrugom, podjelom udruge razdvajanjem,
          </li>{" "}
          <br />
          <li>u drugim slučajevima predviđenim Zakonom.</li>
        </ul>
      </h4>{" "}
      <br />
      <br />
      <br /> <h5>Članak 48.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Likvidator zastupa udrugu u postupku likvidacije, a on se otvaranjem
        likvidacijskog postupka upisuje u Registar udruga kao osoba ovlaštena za
        zastupanje Udruge do okončanja postupka likvidacije i brisanja udruge iz
        Registra udruga.
      </h4>
      <br /> <h5>Članak 49.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Likvidatora bira i opoziva Skupština Udruge.
      </h4>
      <br /> <h5>Članak 50.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju prestanka postojanja Udruge, imovina se, nakon namirenja
        vjerovnika i troškova likvidacijskog, sudskog i drugih postupaka,
        predaje udruzi, ustanovi ili zakladi koje imaju iste ili slične
        statutarne ciljeve, na osnovi odluke Skupštine Udruge, sukladno Statutu.{" "}
        <br />
        <br />U slučaju da da se iz bilo kojeg razloga Skupština Udruge ne može
        sastati i donijeti odluku iz prethodnog stavka, preostalu imovinu stječe
        jedinica lokalne uprave ili samouprave prema mjestu sjedišta udruge.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="sporovi" className="pd-label">
        IX. NAČIN RJEŠAVANJA SPOROVA I SUKOBA INTERESA UNUTAR UDRUGE
      </h3>
      <br /> <h5>Članak 51.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako postoji spor između članova Udruge koji otežava ili onemogućava rad
        Udruge, a on se ne može riješiti kroz postupke propisane ovim Statutom,
        članovi Udruge dužni su pokušati taj spor riješiti mirenjem. <br />
        <br />
        Ako postupak mirenja ne uspije, članovi Udruge spor rješavaju pred
        mjesno nadležnim općinskim sudom.
      </h4>
      <br /> <h5>Članak 52.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Članovi Udruge u svim poslovima od interesa i značaja za Udrugu moraju
        postupati časno, pošteno, savjesno, odgovorno i nepristrano čuvajući
        vlastitu vjerodostojnost i vjerodostojnost Udruge. <br />
        <br />U obavljanju djelatnosti Udruge članovi ne smiju svoj privatni
        interes stavljati iznad interesa Udruge.
      </h4>
      <br /> <h5>Članak 53.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju da su privatni interesi člana Udruge u suprotnosti s interesom
        Udruge ili kad privatni interes utječe ili može utjecati na nepristran
        rad člana Udruge u obavljanju djelatnosti Udruge, član Udruge koji se
        zatekne u sukobu interesa dužan je o tome bez odlaganja izvijestiti
        Predsjednika Udruge i, ako je moguće, izuzeti se od daljnjeg rada na
        konkretnoj djelatnosti.
      </h4>
      <br /> <h5>Članak 54.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju dvojbe o mogućem sukobu interesa, bilo koji član Udruge može
        zatražiti mišljenje Predsjednika Udruge. On se na njega dužan očitovati
        pisanim putem u roku od osam dana. <br />
        <br />U slučaju pojavljivanja mogućeg sukoba interesa, Predsjednik je
        dužan privremeno razriješiti člana ili tijelo tako da zaštiti interes
        Udruge u tom slučaju. U slučaju da je sam Predsjednik predmet spora,
        odluka o njegovom privremenom izuzeću donosi se na izvanrednoj sjednici
        Skupštine dvotrećinskom većinom, sukladno statutarnim propisima. Ovisno
        o zaprimanju jasnih i nepobitnih dokaza o slučaju, odluka o privremenom
        izuzeću preoblikuje se u trajnu ili se poništava. Član ima pravo žalbe
        sukladno ovom Statutu.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 id="zavrsne-odredbe" className="pd-label">
        X. PRIJELAZNE I ZAVRŠNE ODREDBE
      </h3>
      <br /> <h5>Članak 55.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Statut Udruge, odnosno izmjene i dopune Statuta, donosi Skupština
        većinom glasova prisutnih članova Skupštine Udruge nakon provedene
        rasprave.
      </h4>
      <br /> <h5>Članak 56.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Inicijativu za izmjene i dopune Statuta može pokrenuti najmanje polovina
        redovnih članova Udruge. <br />
        <br />
        Inicijativa za izmjene i dopune Statuta podnosi se u pisanom obliku
        Predsjedniku Udruge (s potpisima članova) koji je prijedlog izmjena i
        dopuna dužan uvrstiti na dnevni red prve sljedeće sjednice Skupštine.{" "}
        <br />
        <br />
        Prijedlog izmjena i dopuna Statuta mora sadržavati novu formulaciju
        članaka Statuta čije se izmjene i dopune predlažu uz obrazloženje
        prijedloga.
      </h4>
      <br /> <h5>Članak 57.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Tumačenje odredaba ovog Statuta daje Skupština. <br />
        <br /> Tumačenje drugih akata Udruge daje Predsjednik Udruge.
      </h4>
      <br /> <h5>Članak 58.</h5> <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ovaj Statut stupa na snagu danom donošenja.
      </h4>
      <br />
      <a href="#izbornik" className="markbook">
        Vrati se nazad na izbornik
      </a>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br id="zadnje" />
      <br />
      <div className="reg-info">
        Za više informacija o održavanju i prethodnim najavama sjednica
        skupštine, s posebnim naglaskom na statutarne intervencije, s navođenjem
        bitnih izmjena i dopuna koje su se dogodile s nekim datumom održavanja,
        možeš pogledati{" "}
        <a className="markbook" href="/financijska-izvjesca">
          poslovanje
        </a>{" "}
        naše organizacije i slobodno nam uputiti bilo kakva pitanja{" "}
        <a className="markbook" href="/kontakt">
          {" "}
          obrascem
        </a>{" "}
        ili izravno, a mi ćemo se potruditi odgovoriti u doista najkraćem
        mogućem roku. Na prvoj poveznici dostupna su ti i naša godišnja
        financijska izvješća. Stojimo na raspolaganju.{" "}
      </div>
      <br />
      <br />
      <br />
      <a
        href={require("../files/statut.pdf")}
        target="_blank"
        rel="noreferrer"
        className="reg-def"
      >
        <img
          src={require("../files/pdfdocs.png")}
          alt="Isječak iz Registra udruga za traženje statuta Dosta je bilo"
          draggable="false"
          className="reg-img"
        />
        <h4>
          Statut udruge u PDF-u, dostavljen iz Registra udruga Republike
          Hrvatske
        </h4>
      </a>
      <br />
      <br />
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}
      <div className="corrupt">
        <img
          src={require("../files/nocorruption.png")}
          alt="Dosta je bilo - podrška transparentnosti i borbi protiv mita i korupcije u Hrvatskoj"
          draggable="false"
          className="stopcorr"
        />
        <h1>netransparentnosti</h1>
        <h1>malverzacijama</h1>
        <h1>korupciji</h1>
        <h5>
          "Dosta je bilo" od prvih se dana svog djelovanja, još kao neformalna
          inicijativa, vodi načelima transparentnosti, izbjegavanja svih oblika
          nezakonitosti, malverzacija i nepravilnosti i najstrože osuđuje bilo
          kakve koruptivne radnje pa čak i njezino opravdanje, toleriranje ili
          neprijavljivanje.{" "}
        </h5>
        <br />
        <h3>
          KORUPCIJA JE ODVRATNI KARCINOM KOJI UBIJA POVJERENJE U DEMOKRACIJU
        </h3>
        <br />
        <h5>
          Ako imaš bilo kakva saznanja za koruptivne radnje oko sebe, nuđenje,
          primanje mita, sumnju u bilo kakav oblik nepravilnosti na bilo kojoj
          razini oko tebe, imaš za to potvrdu, ali se ne osjećaš sigurno
          prijaviti, <a href="/kontakt">prijavi sumnju na korupciju nama</a> i
          mi ćemo ti pomoći ili odraditi provjeru i prijavu, pazeći potpuno na
          tvoju anonimnost.
        </h5>
        <p>
          Korupcija guši tvoje pravo da živiš bolje. Ne budi sudionik šutnjom.
        </p>
      </div>
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}{" "}
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}{" "}
      {/* marker
      marker
      marker
      marker
      marker
      marker
      marker
      marker */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default StatuteMore;
