import React, { useState } from "react";
import "./ContactMore.css";

import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

function ContactMore() {
  const [isError, setIsError] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    if (
      (document.querySelector("#checker").checked === true) &
      (document.querySelector("#nameid").value !== "") &
      (document.querySelector("#emailid").value !== "") &
      (document.querySelector("#placeid").value !== "") &
      (document.querySelector("#areaid").value !== "")
    ) {
      emailjs
        .sendForm(
          "service_zo9q334",
          "template_fov1w9c",
          e.target,
          "user_saWdICV9owpFaiJHB3V1j"
        )
        .then(
          (result) => {
            setIsCorrect(true);
          },
          (error) => {
            setIsError(true);
          }
        );

      e.target.reset();
    } else {
      document.getElementsByClassName("submitst").disabled = true;
      setIsError(true);
    }
  }

  return (
    <div className="cm">
      <div className="setppp">
        <h1>Obrazac</h1>
        <p>
          Ako želiš postaviti pitanje, dati svoj prijedlog, primjedbu ili
          pritužbu, možeš sve to navesti ispod u obrascu. Odgovor stiže u
          najkraćem mogućem roku. Pripazi na ispravnost podataka, posebno
          e-maila.
        </p>
      </div>
      <form className="setform" onSubmit={sendEmail}>
        <label>Ime i prezime (ili naziv poslovnog subjekta): </label>
        <input type="text" name="ime" id="nameid" required /> <br />
        <label>E-mail za odgovor: </label>
        <input type="email" name="email" id="emailid" required /> <br />
        <label>Mjesto: </label>
        <input type="text" name="mjesto" id="placeid" required />
        <br />
        <label>Pitanje, prijedlog ili prigovor: </label>
        <textarea name="messagebody" id="areaid" required /> <br />
        <div>
          <input
            type="checkbox"
            value="abc"
            checked="false"
            id="checker"
            required
          />{" "}
          <label>Potvrđujem da sam popunio/la sve podatke. </label> <br />{" "}
          <br /> <br /> <br />
          <div className="cont-add">
            Ako se želiš učlaniti u "Dosta je bilo", tada te molimo da nam
            obvezno u poruci nadodaš podatke koji su prema{" "}
            <i>Zakonu o udrugama </i>
            obvezni da bismo zahtjev mogli pravovaljano obraditi, a to su:{" "}
            <br /> <br /> <span>
              a) ime i prezime ili naziv pravne osobe
            </span>, <br /> <br />
            <span>
              b) datum rođenja — za pravnu osobu onaj ovlaštene osobe za
              predstavljanje
            </span>
            , <br /> <br /> <span>c) OIB</span>, <br /> <br />
            <span>d) adresu elektroničke pošte</span>.
          </div>
          <br /> <br /> <br />
          <br />
          <h4 className="mb-link">
            Ako će ti biti lakše, klikni na{" "}
            <Link to="/uclanjenje">pristupnicu ovdje</Link> i tu smo ti
            pripremili obrazac predviđen izravno za učlanjenje.
          </h4>
          <br /> <br /> <br />
          <br />
          <label>
            Obrada podataka danih pri popunjavanju obrasca iznad odvijat će se
            isključivo s ciljem odgovaranja na postavljeni upit i neće doći do
            prosljeđivanja istih bilo kojoj trećoj strani. Podatke obrađuje
            'Dosta je bilo' (OIB 72276005315). U slučaju nepopunjavanja svih
            podataka iznad, neprofitna organizacija nije dužna odgovoriti na
            upit. Svako postupanje usklađeno je s važećim europskim i domaćim
            zakonodavnim okvirom koji se odnosi na zaštitu i obradu osobnih
            podataka.
          </label>{" "}
          <br /> <br />
          <br />
        </div>
        <button className="form-btn" type="submit" onSubmit={sendEmail}>
          POŠALJI
        </button>
        {isCorrect ? (
          <div className="is-correct">
            Poruka je uspješno poslana. Pričekaj kraće vrijeme i netko od naših
            volontera će ti odgovoriti vrlo detaljno. Hvala ti.
          </div>
        ) : (
          ""
        )}
        {isError ? (
          <div className="is-error">
            Prilikom slanja dogodila se određena poteškoća. Ljubazno te molimo
            da nam se trenutno obratiš slanjem izravnog upita klikom na{" "}
            <a href="mailto:info@dostajebilo.hr">info@dostajebilo.hr</a>
          </div>
        ) : (
          ""
        )}
      </form>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905339.835480299!2d15.099103103125007!3d44.67375590617243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133441080add95ed%3A0xa0f3c024e1661b7f!2sCroatia!5e0!3m2!1sen!2shr!4v1654289969253!5m2!1sen!2shr"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="djb-map"
      ></iframe>
      <h4 className="map-h4">
        "Dosta je bilo" djeluje na teritoriju Republike Hrvatske, iako je
        statutarno otvorena za suradnju i s ostalim programski srodnim
        organizacijama izvan Hrvatske, o čemu će obavijestiti putem svojih
        standardnih komunikacijskih kanala, poput ove stranice.
      </h4>

      <Link className="map-link" to="/o-nama">
        Pogledaj više komunikacijskih kanala ovdje
      </Link>
    </div>
  );
}

export default ContactMore;
