import React from "react";
import "./LastTrack.css";

import { FaVolleyballBall } from "react-icons/fa";

function LastTrack() {
  const date = new Date();
  const day = date.getDate().toString();
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1;

  var monthName;

  switch (month) {
    case 1:
      monthName = "siječnja";
      break;
    case 2:
      monthName = "veljače";
      break;
    case 3:
      monthName = "ožujka";
      break;
    case 4:
      monthName = "travnja";
      break;
    case 5:
      monthName = "svibnja";
      break;
    case 6:
      monthName = "lipnja";
      break;
    case 7:
      monthName = "srpnja";
      break;
    case 8:
      monthName = "kolovoza";
      break;
    case 9:
      monthName = "rujna";
      break;
    case 10:
      monthName = "listopada";
      break;
    case 11:
      monthName = "studenog";
      break;
    case 12:
      monthName = "prosinca";
      break;
    default:
      monthName = "NEPOZNAT MJESEC";
  }

  return (
    <div className="last-track">
      Dosta je bilo <FaVolleyballBall className="track-ball" />{" "}
      <span>{day + ". " + " " + monthName + " " + year + "."}</span>{" "}
      <FaVolleyballBall className="track-ball" />
      copyright
    </div>
  );
}

export default LastTrack;
