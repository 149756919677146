import React from "react";
import "./TableNSCZ.css";

/* import stopimage from "../images/stopimage.png";
import stozerglupiranje from "../images/stozer-glupiranje.jpg"; */

function TableNSCZ() {
  let isClicked = true;
  function toggleTable() {
    document.getElementById("tgl").classList.toggle("show-tbl");
  }

  return (
    <div className="basic-div">
      <div className="black-div">
        <h3>
          DISKRIMINATORNE, NELOGIČNE, NEPRAVEDNE, STRANAČKE I POLITIČKE ODLUKE,
          MJERE I PONAŠANJA STOŽERA I VLADE U BORBI S EPIDEMIJOM BOLESTI
          COVID-19 <br />
          <br />{" "}
          <a
            href="#fotografije"
            style={{
              textDecoration: "none",
              color: "#d82020",
              backgroundColor: "white",
              padding: "0.25rem 1rem",
              borderRadius: "10px",
            }}
          >
            FOTOGRAFIJE
          </a>
        </h3>
        <img
          alt="Znak STOP - asocijacija na suprostavljanje diskriminaciji kroz kolektivnu sudsku tužbu protiv Nacionalnog stožera i države zbog mjera protiv epidemije COVID-19 kojeg uzrokuje koronavirus - STOP diskriminaciji"
          src={require("../files/stozermjere.jpeg")}
        />
      </div>
      {/* Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker */}
      <div className="nscz-expl">
        <h3 className="pd-label">
          Osnivanje Stožera civilne zaštite Republike Hrvatske
        </h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Stožer civilne zaštite Republike Hrvatske formalno je osnovan u
          veljači godine 2020. kao odgovor na već tada nadolazeću epidemiju (i
          ubrzo pandemiju) bolesti uzrokovane širenjem novog virusa po svijetu —
          SARS-CoV-2. Kako se bolest širila, Vlade zemalja svijeta u početku su
          oblikovale "specijalizirana" tijela čija je zadaća bila voditi i
          koordinirati protuepidemiološki odgovor na novu situaciju, a ubrzo i
          donositi razne restriktivne mjere ograničavanja kretanja, rada u nekim
          djelatnostima i slično. Takvo tijelo u Hrvatskoj je bio upravo Stožer.
        </h4>
        <br />

        <h3 className="pd-label">Kontekst osnivanja — parlamentarni izbori</h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          U otprilike prvih mjesec dana novooblikovano tijelo donosilo je
          pretežito odluke sa snagom preporuke. Međutim, kako su brojevi
          zaraženih postajali sve viši, Stožer je počeo razmišljati o oštrijem
          odgovoru pa je krajem ožujka 2020. naglo uveo niz snažnih restrikcija,
          zatvaranja i ograničenja neformalno nazvanih "lockdown". Iako su u
          početku davali izjave da je epidemija bezopasna, da su maske
          nepotrebne i da pojedinci koriste situaciju za stvaranje panike i
          određene vrste profita, ubrzo su i sami shvatili da bi mogli izvući
          političku korist za svoju stranku. Naime, u srpnju 2020. očekivali su
          se parlamentarni izbori.
        </h4>
        <br />
        <h3 className="pd-label">
          Jedno od najsramotnijih razdoblja novije hrvatske povijesti
        </h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Nagli zaokret iz "bolesti na razini gripe" (Vili Beroš, ministar
          zdravstva, veljača 2020.) do "strašnog nevidljivog neprijatelja" zbog
          kojeg je ugašen značajan dio ionako slabog hrvatskog gospodarstva prvi
          je znak zloupotrebe izvanredne situacije u stranačke svrhe. S vremenom
          je retorika promijenjena pa su nas dočekali redovite javne sjednice
          članova tog tijela na kojima se izvještavalo o brojevima oboljelih,
          umrlima i oštrim tonom prozivalo one koji situaciju "ne shvaćaju
          ozbiljno". Vrlo brzo slušali smo o "bioteroristima" i prijetnje
          zatvorskim kaznama za ugrožavatelje javnog zdravlja. Koristio se čak
          naziv "kovidioti" (čak i "idioti"). Panika je brzo zavladala, zdrav
          razum — nestao. Uslijedilo je sramotno stvaranje kulta ličnosti.
        </h4>
        <br />
        <h3 className="pd-label">Stvaranje kulta ličnosti</h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Vili Beroš, Davor Božinović, Alemka Markotić i Krunoslav Capak preko
          noći postali su spasitelji, mesije, heroji. Gotovo svi mediji o njima
          su pisali hvalospjeve, a većina ih je otvoreno zvala "herojima" pa su
          dobivali i ogromne bannere s tom riječi na naslovnim stranicama.
          Izostanak demokratske kritičnosti doveo je do prekida kritika i same
          stranke HDZ-a pa je tako gotovo ispod radara prošla vijest o
          nepravomoćnoj presudi kojom je stranka neslužbeno ocijenjena kao
          zločinačka organizacija. Još gore, ljude se počelo prozivati grubim
          riječima po člancima, a mnogi građani nisu zaostajali — stvorena je
          atmosfera linča (primjer su izrazito huškački komentari na vijest o
          kažnjavanju poljoprivrednika koji je usred samoizolacije otišao na
          polje sam). Ponovimo — sam.
        </h4>
        <br />
        <h3 className="pd-label">
          Prvi znakovi ispolitiziranosti tijela i bezobraznog korištenja posebne
          situacije za osobni profit
        </h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Iako smo kao inicijativa već tada pokušali djelovati preko društvenih
          mreža i zaustaviti ludilo, nismo uspjeli zbog snažnih restrikcija koje
          su u tim početcima koristili njihovi algoritmi. Čekali smo priliku i
          dočekali ju naljeto. Međutim, već od prvih dana Stožer je pokazao da
          koristi ljudsku patnju za politički uspjeh. Mijenjanje mišljenja,
          prihvaćanje uloge junaka, korištenje djece u propagandne svrhe (op. a.
          djeca pjevaju pjesmice o Stožeru, crtaju ih kao junake i sl.), prve
          odluke kojima su se davali ustupci povlaštenim skupinama, dok su drugi
          bili zaključani itd. Ovakvih situacija s vremenom se nakupilo doslovno
          — nekoliko stotina. Tek jedan dio popisali smo ispod pa se možeš
          podsjetiti. Podsjetiti da se nikad više ne zaboravi da političari lako
          tvoje povjerenje mogu zloupotrijebiti.
        </h4>
        <br />
        <h3 className="pd-label">Ljeti smo napokon i mi došli do riječi :)</h3>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Nije prošlo u proljeće 2020., ali novo osnivanje grupe "Tražimo
          ukidanje nacionalnog stožera civilne zaštite" se isplatilo. Kada je
          pokrenuta grupa 24. srpnja 2020., velika većina javnosti još je
          vjerovala Stožeru. Ipak, zadovoljstvo se gomilalo, ali nije se moglo
          jasno izreći jer su jedini glas protiv tada jasno isticali ljudi koji
          su propagirali sulude teorije, a ne glas razuma. Mi smo odabrali taj
          smjer i već sredinom kolovoza grupa je imala preko 100 000 članova. To
          smo iskoristili da povučemo ostale u društvu da hrabrije kažu "ne"
          Stožeru, da ostvarimo pluralizam u javnosti i konačno maknemo po
          strani herojsku sliku ovog tijela, koja će i u medijima s vremenom
          slabiti zbog pritiska čitatelja.
        </h4>
        <br />
        <br />
        <br />
      </div>
      {/* Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker
Breaker */}
      {/*    <div className="toggler" onClick={toggleTable} id="green">
        <p>
          Klikni ovdje da bi skrio/la tablicu (CRVENO), odnosno ponovno da bi ju
          prikazao/la (ZELENO)...{" "}
        </p>{" "}

        onClick={toggleTable}
      </div> */}
      <div className="something">
        <div className="black-div">
          <h3>
            TKO NE UČI IZ GREŠAKA IZ POVIJESTI, OSUĐEN JE DA MU SE ONE STALNO
            VRAĆAJU — BITNO JE NE ZABORAVITI <br /> <br />
            VELIKA TABLICA S POPISOM SAMO DIJELA NEPRAVILNOSTI
            <br />
            <br />{" "}
            <a
              href="#fotografije"
              style={{
                textDecoration: "none",
                color: "#d82020",
                backgroundColor: "white",
                padding: "0.25rem 1rem",
                borderRadius: "10px",
              }}
            >
              FOTOGRAFIJE
            </a>
          </h3>
          <img
            alt="Znak STOP - asocijacija na suprostavljanje diskriminaciji kroz kolektivnu sudsku tužbu protiv Nacionalnog stožera i države zbog mjera protiv epidemije COVID-19 kojeg uzrokuje koronavirus - STOP diskriminaciji"
            src={require("../files/junak.jpeg")}
            style={{ objectPosition: "bottom" }}
          />
        </div>

        <h4
          className="mb-link going-up centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Tijekom glavnine svog djelovanja, pratili smo rad Nacionalnog stožera,
          njegovih članova i Vlade. U grupi "Tražimo ukidanje Nacionalnog
          stožera civilne zaštite" beskompromisno smo i svakodnevno mjesecima i
          mjesecima bilježili kršenja mjera i pravila od samih donositelja
          odluka i hvatali njih i druge odgovorne u kršenju mjera. Za iste takve
          stvari, unatoč svemu, redovito su prozivali ostale građane i često ih
          kažnjavali.
        </h4>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          S obzirom na to da je popis licemjernog i sramotnog ponašanja članova
          Vlade i Stožera na svim razinama suviše dugačak, nije ga praktično
          ispisivati jer postaje nečitljiv i više je materijal za jednu opsežnu
          knjigu. Ispod je tablica s popisom najpoznatijeg takvog ponašanja
          odgovornih.{" "}
        </h4>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Napominjemo da je ta ogromna tablica tek 20-ak % onoga što smo mi
          redovitim praćenjem zabilježili u grupi, bez obrade svih podataka.
          Dakle, ovakvih tablica ispod trebalo bi najmanje još četiri-pet samo
          da se ispiše ono što smo mi uspjeli pratiti.{" "}
        </h4>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Bitno je naglasiti da u popisu nisu niti ostali nevladini članovi,
          poput ostalih političara, nacionalnih i lokalnih, istaknutih javnih
          osoba koje su masovno pametovale drugima, a sami su hvatani da krše
          mjere, za što bi druge vrlo oštro kažnjavali.
        </h4>
        <br />
        <br />
        <br />
        <div className="move-table" id="tgl">
          <br />
          <table border="0.5" cellPadding="25" cellSpacing="5">
            <tr className="redcolour">
              <td>3./4. mj. 2020.</td>
              <td>
                Uredno ošišani članovi Vlade, iako su frizerski saloni
                zatvoreni. Nekoliko objašnjenja bilo je da su ih precizno šišale
                žene (primjer - Božinović).
              </td>
            </tr>

            <tr className="blackcolour">
              <td>3./4. mj. 2020.</td>
              <td>
                Članovi Stožera govore o "kritičnoj situaciji" na svega nekoliko
                desetaka zaraženih dnevno, nepotrebno unoseći paniku i
                pretjeranu reakciju građana.
              </td>
            </tr>

            <tr>
              <td>10. 4. 2020.</td>
              <td>
                Odobrena hvarska procesija "Za križen". Stožer u to vrijeme
                govorio o vrlo opasnoj situaciji i prijetio zatvorskim kaznama u
                slučaju okupljanja.
              </td>
            </tr>

            <tr className="redcolour">
              <td>3. - 6. mj.</td>
              <td>
                Dok su građane nazivali bioteroristima i ubojicama, članovi
                Vlade potajno su se masovno okupljali u Slovenskoj 9 i
                izrugivali zatvorenim građanima.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>16. 3. 2020.</td>
              <td>
                Čovjek kraj varaždinskog Ivanca kažnjen s 8000 kn jer je sam
                otišao raditi u polje, osuđivan u javnosti i od Stožera danima.
                Da je stvarno ugrožavao ljude i otišao u Slovensku, kaznu ne bi
                dobio. U komentarima na Facebook-profilu vrijeđan uz prijetnje.
              </td>
            </tr>
            <tr>
              <td>3. - 5. mj.</td>
              <td>
                Stožer redovito na sjednicama objavljuje podatke o prekršajno
                kažnjenim osobama, osudio strogo i zaraženu ženu koja je šetala
                gradom, iako nije bila u dodiru s ljudima. Mnogi takvi primjeri
                ići će u zatvor - vidjeti još jednom sve ovo iznad.
              </td>
            </tr>
            <tr className="redcolour">
              <td>3. - 6. mj.</td>
              <td>
                Potaknuti rječnikom sa sjednica Stožera, dobar dio javnosti
                teško vrijeđao i osuđivao osobe koje krše mjere, makar bez
                ugroze drugih (vidljivo po internetskim komentarima i dijelu
                članaka), ne znajući za npr. Slovensku. Stožer i dalje ne
                smiruje paniku, već ju potiče.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>3. - 5. mj.</td>
              <td>
                Stožer uveo propusnice da ograniči kretanje ljude, a istovremeno
                ih odobrio preko milijun i pol u ovom razdoblju, dodjeljujući
                mnoge od njih po stranačkom kriteriju u pojedinim općinama. Sa
                strane pričaju o alarmantnoj situaciji u kojoj nema vremena za
                opuštanje.
              </td>
            </tr>
            <tr>
              <td>27. 3. 2020.</td>
              <td>
                Stožer izdao posebno odobrenje za rad tržnica koje prodaju
                određene proizvode. Tih dana ministar Beroš izjavio da je
                situacija krajnje zabrinjavajuća.
              </td>
            </tr>
            <tr className="redcolour">
              <td>18. 4. 2020.</td>
              <td>
                Donesena odluka o popuštanju mjera samo za uskrsne blagdane.
                Objašnjenje - "radi lakše oskrbe". Pravo na lakšu opskrbu
                izostalo za pripadnike drugih skupina ili deesetke zatvorenih
                djelatnosti koji su ostali bez takve privilegije.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>24. 4. 2020.</td>
              <td>
                Donesena odluka o neradnoj nedjelji i blagdanima uz opravdanje
                da to nema veze s politikom stranke, nego sprječavanjem gužvi.
                Najveće gužve u trgovinama po APIS-u petkom.
              </td>
            </tr>
            <tr>
              <td>4./5. mj. 2020.</td>
              <td>
                U nizu popuštanja, sportske aktivnosti na otvorenom i zatvorenom
                došle na red među zadnjima. Masovna okupljanja u zatvorenom
                odobravana prije.
              </td>
            </tr>
            <tr className="redcolour">
              <td>4. 5. 2020.</td>
              <td>
                Pogrebi u krugu obitelji, ali donesena odluka da se pogrebi
                branitelja uz vojne počasti izuzimaju od toga. Ostali građani
                svoje preminule ispraćaju i dalje po strogom režimu. Bliže se
                izbori.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>12. 5. 2020.</td>
              <td>
                Capak objavljuje da se planira omogućiti osobama u samoizolaciji
                izlazak na izbore. Tjednima prije kolege i on ispisivali ogromne
                kazne i tražili zatvor za prekršitelje takve odredbe.
              </td>
            </tr>
            <tr>
              <td>24. 6. 2020.</td>
              <td>
                Predsjednik Vlade: "Ova Vlada je pobijedila COVID-19". Izbori za
                dva tjedna.
              </td>
            </tr>
            <tr className="redcolour">
              <td>30. 6. 2020.</td>
              <td>
                Stožer izmijenio odluku o graničnom režimu omogućivši ulazak bez
                PCR-testa građanima BiH s nacionalnim pravom. Izbori za nekoliko
                dana. Epidemiološka situacija u BiH tada višestruko gora u toj
                zemlji.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>krajem 6. mj. 2020.</td>
              <td>
                Osoba s djetetom nasilno izbačena iz vlaka pred djetetom zbog
                odbijanja stavljanja zaštitne maske. U izbornoj noći stotine
                političara i članovi Stožera gurali se i skakutali na otvorenom
                i zatvorenom satima bez maski i razmaka, policija tu ne reagira
                i ne podnosi prijave.
              </td>
            </tr>
            <tr>
              <td>5. 7. 2020.</td>
              <td>
                Izborna noć - članovi Stožera krše sve moguće propisane
                epidemiološke mjere s predsjednikom Vlade. Za slične stvari
                slali ljude u pritvor tjednima prije.
              </td>
            </tr>
            <tr className="redcolour">
              <td>6. 7. 2020.</td>
              <td>
                Neće biti pokrenut postupak zbog bezobraznog kršenja mjera na
                izbornu noć od onih koji ih propisuju i traže od građana da ih
                se drže. Inspektorat odlukom od kraja 6. mj. ugostitelje za
                manje ozbiljne prekršaje može kažnjavati drastičnim kaznama.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>7. 7. 2020.</td>
              <td>
                Capak uvjerljivo tvrdi da će biti obješen s ostalim članovima
                Stožera ako stopom mrtvih Hrvatska prestigne Švedsku. Hrvatska
                ju prestigla u 12. mjesecu 2020.
              </td>
            </tr>
            <tr>
              <td>10. 7. 2020.</td>
              <td>
                Okupljanja s preko 100 okupljenih trebaju biti poimenično
                evidentirana. Odluka donesena četiri dana nakon što su se
                članovi Vlade i Stožera u izbornoj noći izrugivali građanima
                pred kamerama.
              </td>
            </tr>
            <tr className="redcolour">
              <td>10. 7. 2020.</td>
              <td>
                Odluka o maskama - do prije nekoliko dana članovi Stožera svi
                odreda javno isticali da je nošenje maske besmisleno, nepotrebno
                i ima psihološku svrhu.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>11. 7. 2020.</td>
              <td>
                Posebni ustupak građanima BiH s nacionalnim pravom dan nekoliko
                dana pred izbore sada izmijenjen, odmah poslije izbora i
                "odrađenog posla".
              </td>
            </tr>
            <tr>
              <td>23. 7. 2020.</td>
              <td>
                Capak posebnom odlukom gotovo pa poništio odredbu o
                samoizolaciji za zastupnika Hrebaka kako bi mogao doći na
                konstituirajuću sjednicu Sabora. Dva mjeseca prije takve radnje
                opisivao kao bioterorizam. Svi ti "bioteroristi" nisu dobili
                poseban tretman, platili drastične kazne.
              </td>
            </tr>
            <tr className="redcolour">
              <td>7. - 9. mj. 2020.</td>
              <td>
                Zbog turističke sezone Stožer je uveo fleksibilne mjere, gotovo
                ih i ukinuo, iako su brojke zaraženih bile i do pet puta veće od
                doba u kojem je bilo radikalno zatvaranje. Stručnjaci u Stožeru
                procijenili da virus zna da državi treba novac i privremeno nema
                opasnosti.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>13. 8. 2020.</td>
              <td>
                Ograničen rad objekata iz kategorije "Barovi" do 24 h.
                Obrazloženje kakvog to smisla ima i zašto se vrijeme nije
                skratilo više ako je već cilj ograničiti okupljanje i je li to
                samo bezvezna kozmetička mjera - nije dano. Sezona u tijeku.
              </td>
            </tr>
            <tr>
              <td>14. 8. 2020.</td>
              <td>
                Odobrene procesije za blagdan Velike Gospe. U Sinju tisuće,
                kršenja mjera po Stožeru nije bilo. Dan prije barovima uvedena
                ograničenja. Odluka o popisivanju ljudi ako je preko 100
                sudionika zaboravljena. Sankcije ostavljene za višestruko manje
                prekršaje.
              </td>
            </tr>
            <tr className="redcolour">
              <td>26. 8. 2020.</td>
              <td>
                Stožer zabranio rad teretanama i fitness-centrima u SD županiji.
                Izjavili da nema dokaza da se bolest tamo širila. S druge strane
                nisu zatvorili cijeli niz djelatnosti s izraženijim okupljanjima
                masa.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>31. 8. 2020.</td>
              <td>
                Stožer odluku o zabrani rada teretanama i fitness-centrima u SD
                županiji naglo poništio pod pritiskom raznih inicijativa s
                društvenih mreža i neizravno priznao da nema pojma zašto je tu
                odluku uopće donio.
              </td>
            </tr>
            <tr>
              <td>12. 9. 2020.</td>
              <td>
                Predsjednik Vlade Plenković izjavljuje da je Stožer političko
                tijelo, nije neovisno i da ono provodi politiku Vlade (stranke).
                Dva mjeseca prije tvrdio suprotno.
              </td>
            </tr>
            <tr className="redcolour">
              <td>12. 9. 2020.</td>
              <td>
                Predsjednik Vlade Plenković poručuje da je identificirao one
                koji u zadnje vrijeme rade na rušenju ugleda Stožera.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>14. 9. 2020.</td>
              <td>
                Ustavni sud, znajući za sve navedeno iznad, donosi odluku da je
                rad Stožera potpuno usklađen s Ustavom i pokazuje se kao
                besmisleno, političko i Vladino tijelo. Troje sudaca u
                izdvojenim mišljenjima žestoko napalo ovakvu odluku.
              </td>
            </tr>
            <tr>
              <td>14. 9. 2020.</td>
              <td>
                Beroš, ohrabren besmislenim odlukama Ustavnog suda, izjavljuje
                da je Stožer političko tijelo. U četvrtom mjesecu za N1 se kleo
                da to tijelo nije političko.
              </td>
            </tr>
            <tr className="redcolour">
              <td>15. 9. 2020.</td>
              <td>
                U javnost izašla vijest o kažnjavanju organizatora koncerata u
                MSU-u s po 30 000 HRK i to jer se nekoliko ljudi usudilo
                zaplesati na pozornici. Tjedan dana prije inspektori zaključuju
                da su se tisuće na Festivalu slobode 5. rujna držale svih mjera.
                Isti inspektori nisu primijetili kršenje mjera ni u izbornoj
                noći i desetcima sličnih okupljanja.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>25. 9. 2020.</td>
              <td>
                Odlukom za općinu Đulovac ugostiteljskim objektima zabranjen rad
                iza 21 h, vjerskim okupljanjima, iako s puno više prisutnih,
                propisane tek uobičajene mjere popisivanja ljudi.
              </td>
            </tr>
            <tr>
              <td>6. 10. 2020.</td>
              <td>
                Izmjena odluke za Đulovac. Sada okupljanja vani ograničena na do
                10 osoba. Misna okupljanja, iako u zatvorenom, izuzeta.
                Nacionalni i lokalni Stožer ne daju ikakvo, a kamoli logično
                objašnjenje.
              </td>
            </tr>
            <tr className="redcolour">
              <td>12. 10. 2020.</td>
              <td>
                Sezona završava pa postroženi uvjeti za popisivanje sudionika
                okupljanja sa 100 na sada 50. Objašnjeno da je nužno smanjiti
                okupljanja jer vreba opasnost. Tri mjeseca prije, dok se punio
                proračun, virus je to znao i, prema Stožeru, nije bio opasan.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>12. 10. 2020.</td>
              <td>
                Sezona završava pa postrožena odluka o obvezi nošenja zaštitnih
                maski. Mjesecima prije tijekom sezone i okupljanja tisuća
                turista za tim nije bilo potrebe prema odlukama Stožera.
              </td>
            </tr>
            <tr>
              <td>25. 10. 2020.</td>
              <td>
                Stožer potpuno zabranjuje sva okupljanja gdje se očekuje preko
                50 ljudi jer navodno žele suzbiti opasnost pri okupljanjima.
                Dozvoljava da se na misi ili umjetničkoj izložbi okupi i 2000
                ljudi ako će doći.
              </td>
            </tr>
            <tr className="redcolour">
              <td>25. 10. 2020.</td>
              <td>
                Stožer zabranjuje prodaju alkohola iza 22 h. Ista odluka
                izostala tijekom sezone i okupljanja tisuća i tisuća turista i
                donesena sada kada su okupljanja deseterostruko manja sama po
                sebi.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>28. 10. 2020.</td>
              <td>
                Veliko neprijavljeno okupljanje Torcide. Božinović ne vidi išta
                sporno.
              </td>
            </tr>
            <tr>
              <td>8. 11. 2020.</td>
              <td>
                Prosvjed u Zagrebu u nedjelju 8. 11. na otvorenom - prijava
                organizatoru, Stožer zgrožen "ugrožavanjem zdravlja". Koji sat
                poslije odobrili da se okupi i mogućih 100 000 ljudi u Vukovaru.
              </td>
            </tr>
            <tr className="redcolour">
              <td>9. 11. 2020.</td>
              <td>
                Stožer donosi izmjenu odluke na način da omogućava okupljanje i
                mogućih 100 000 ljudi u Vukovaru. Nekoliko sati prije govorili o
                neodgovornim pojedincima koji krše mjere i ilegalno se okupljaju
                u kućama.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>9. 11. 2020.</td>
              <td>
                Alemka Markotić objašnjava epidemiološku osnovu odluke o
                Vukovaru - "Svi znamo što Vukovar znači Hrvatima."
              </td>
            </tr>
            <tr>
              <td>10. 11. 2020.</td>
              <td>
                Capak o iznimki za Vukovar: "Nadam se da nitko neće brojati
                ljude." Prevedeno: 50, 500 ili 100 000, nije velika razlika.
                Osim ako se Stožeru nešto ne svidi pa tu određuje drakonske
                kazne jer samo oni znaju kada je virus opasan, a kada ne.
              </td>
            </tr>
            <tr className="redcolour">
              <td>15. 11. 2020.</td>
              <td>
                Alemka Markotić, poznata po prozivanju ljudi za bioterorizam ako
                bi se njih petero šetalo parkom u trećem mjesecu, objašnjava
                zašto se potencijalno okupljanje desetaka tisuća u Vukovaru ne
                može usporediti s time: "Branitelji su znali čuvati jedni druge
                u ratu pa znaju i danas."
              </td>
            </tr>
            <tr className="blackcolour">
              <td>18. 11. 2020.</td>
              <td>
                U Vukovaru se okupilo najmanje 10 000 ljudi. Božinović tvrdi da
                nije uočio kršenje mjera. Za prosvjed protiv Stožera 8. studenog
                s 10 puta manje ljudi trebalo mu je nekoliko sekundi da utvrdi
                da je riječ o opasnom okupljanju.
              </td>
            </tr>
            <tr>
              <td>18. 11. 2020.</td>
              <td>
                Počast Vukovaru odala i Torcida. Stožer i Božinović, motivirani
                strahom od reakcije na to, tvrde da je sve po propisima.
              </td>
            </tr>
            <tr className="redcolour">
              <td>20. 11. 2020.</td>
              <td>
                Dan i pol poslije masovnog okupljanja u Vukovaru predsjednik
                Vlade i članovi Stožera zgrožavaju se nad tadašnjom situacijom i
                uvode nove stroge mjere. Okupljanje preko 25 osoba strogo će se
                kažnjavati, ovaj put bez izuzetaka.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>20. 11. 2020.</td>
              <td>
                U obrazloženju novih strogih mjera, članovi Stožera ističu da
                žele "ograničiti okupljanja". Kao izuzetak omogućili masovna
                vjerska okupljanja i sjednice predstavničkih tijela (napomena -
                u RH skoro 52 000 samo udruga!).
              </td>
            </tr>
            <tr>
              <td>kraj 11. mj. 2020.</td>
              <td>
                Dozvoljeno održavanje unutarstranačkih izbora u HDZ-u. Sankcija
                za kršenje mjera nije bilo.
              </td>
            </tr>
            <tr className="redcolour">
              <td>27. 11. 2020.</td>
              <td>
                Zatvaraju se ugostiteljski objekti. Međutim, ako nude smještaj,
                tada nema opasnosti i mogu raditi.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>27. 11. 2020.</td>
              <td>
                Zatvoreni ugostiteljski objekti gdje ljudi sjede za svojim
                stolovima i npr. teretane, ali je Stožeru u redu pun tramvaj ili
                bus, s frekvencijom okupljanja višestruko većom od
                prvospomenutih.
              </td>
            </tr>
            <tr>
              <td>27. 11. 2020.</td>
              <td>
                Odlukom omogućen rad velikim shopping centrima (uz ograničenja
                koja oni nikad niti ne dostignu - dakle, rade kao i prije).
                Pozatvarani mali kafići, teretane i dječje plesne škole.
              </td>
            </tr>
            <tr className="redcolour">
              <td>27. 11. 2020.</td>
              <td>
                Stožer zabranio sportska natjecanja i treninge. Izuzetak - više
                kategorije. Epidemiološka logika - ne postoji.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>12. mjesec 2020.</td>
              <td>
                Na društvenim mrežama objavili smo niz slika iz toplica gdje se
                masa gura u zatvorenom. Stožer - klor tjera virus. Ugostitelji
                gomilaju dugove i sjede doma.
              </td>
            </tr>
            <tr>
              <td>3. 12. 2020.</td>
              <td>
                Beroš u intervjuu govori da su zatvorili kafiće, iako nemaju
                ikakvih potvrda da su oni žarišta bolesti.
              </td>
            </tr>
            <tr className="redcolour">
              <td>3. 12. 2020.</td>
              <td>
                Beroš u intervjuu priznaje da se u donošenju odluka vode i
                ekonomskim kriterijima. Podsjetnik - njihova zadaća je
                suzbijanje bolesti kao nepogode. Pri tomu taj kriterij odabiru
                kada im se svidi.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>18. 12. 2020.</td>
              <td>
                Odluka o produljenju strogih mjera. Izuzetak - za Badnjak i
                Božić može se okupljati. Sličan izuzetak nije dan drugim
                ugašenim djelatnostima, čak niti npr. pravoslavnim vjernicima za
                dva tjedna.
              </td>
            </tr>
            <tr>
              <td>18. 12. 2020.</td>
              <td>
                Opet se uvode propusnice pred Božić. Sporna stvar - uvode se
                kada su brojke zaraženih već počele padati. Kada je bio vrhunac
                - nije ih bilo.
              </td>
            </tr>
            <tr className="redcolour">
              <td>18. 12. 2020.</td>
              <td>
                Capak daje epidemiološku podlogu odluka o izuzetcima za
                blagdanska misna okupljanja: "Ljudima treba duhovni mir."
              </td>
            </tr>
            <tr className="blackcolour">
              <td>18. 12. 2020.</td>
              <td>
                Božinović izjavljuje da "treba smanjiti obiteljska i privatna
                druženja i da Stožer na to najviše mogu utjecati apelom.
                Najrizičnije je okupljati se u zatvorenom prostoru u epidemiji."
                Koju minutu poslije opravdava ustupke za blagdanska misna
                okupljanja.
              </td>
            </tr>
            <tr>
              <td>18. 12. 2020.</td>
              <td>
                Alemka Markotić, kreatorica termina "bioterorist" za one koji
                osporavaju rad Stožera, izjavljuje: "Svatko tko je vjernik zna
                zašto mu treba mir u crkvi. Otići ću na polnoćku."
              </td>
            </tr>
            <tr className="redcolour">
              <td>18. 12. 2020.</td>
              <td>
                Predsjednik Vlade, poznat po priznanju iz rujna 2020. da Stožer
                nije stručno tijelo, nego provodi politiku Vlade (stranke),
                izjavljuje da je "bezobrazna usporedba" činjenice da ugostitelji
                sjede doma, gomilaju dugove i propadaju, a vjerska okupljanja su
                aktivna.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>18. 12. 2020.</td>
              <td>
                Saborski zastupnici izuzeti od potrebe posjedovanja propusnica.
              </td>
            </tr>
            <tr>
              <td>24. 12. 2020.</td>
              <td>
                Vijećnik iz Oroslavja odbio propusnicu kroz pet minuta jer je
                naveo da mu treba duhovni mir. Susjedu ista odbijena, a tražio
                je da odveze majku od 92 godine na liječenje. Niz sličnih
                primjera u javnosti.
              </td>
            </tr>
            <tr className="redcolour">
              <td>29. 12. 2020.</td>
              <td>
                Razorni potres uzrokovao ukidanje odluke o propusnicama. Stožer
                sada tvrdi da masovna okupljanja i međužupanijska kretanja u
                pogođenom području nisu uopće opasna pa se opet postavlja
                pitanje zašto su uopće uvođene. Nije dano objašnjenje.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>1./2. mj. 2021.</td>
              <td>
                Dostava kave za van može u restoranima, ne i kafićima.
                Društvenim se mrežama šire stotine slika s primjerima besmisla
                ovakve odluke.
              </td>
            </tr>
            <tr>
              <td>4. 2. 2021.</td>
              <td>
                Na sprovodu Miroslava Tuđmana više stotina ljudi bez
                pridržavanja mjera, za što ostali građani dobivaju drakonske
                kazne. Sankcija nije bilo.
              </td>
            </tr>
            <tr className="redcolour">
              <td>2. mj. 2021.</td>
              <td>
                Masovna okupljanja ljudi vikendima ispred HNK i na Jarunu i
                diljem Hrvatske. Božinović zaključuje da je sve bilo u skladu s
                njihovim mjerama.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>8. 2. 2021.</td>
              <td>
                Božinović komentira masovna kršenja mjera od njih samih: "Ne
                treba previše filozofirati o mjerama."
              </td>
            </tr>
            <tr>
              <td>8. 2. 2021.</td>
              <td>
                Alemka Markotić kaže da "bioterorizam" treba demistificirati i
                da je u redu prekoračiti dozvoljeni broj okupljenih, ako su
                osobe "svjesne rizika". Nadodaje da "treba upotrijebiti mozak".
              </td>
            </tr>
            <tr className="redcolour">
              <td>10. 2. 2021.</td>
              <td>
                Alemka Markotić, autorica termina bioterorizma za okupljanja
                nekoliko osoba u parkovima, drži govor pred velikim brojem ljudi
                za Stepinčevo u zagrebačkoj katedrali.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>12. 2. 2021.</td>
              <td>
                Alemka Markotić pokušava objasniti kršenje mjera 10.veljače u
                kojem je sama sudjelovala. Tvrdi da nije držala misu, nego samo
                čitala. Iz toga valjda proizlazi da do širenja bolesti ne može
                doći.
              </td>
            </tr>
            <tr>
              <td>2. 3. 2021.</td>
              <td>
                Stožer najavio strože kontrole okupljanja ljudi ispred
                zagrebačkog HNK. Dan prije odobrili potencijalno okupljanje
                tisuća na sprovodu Milana Bandića.
              </td>
            </tr>
            <tr className="redcolour">
              <td>3. 3. 2021.</td>
              <td>
                Na sprovodu Milana Bandića okupile se tisuće. Stožer odobrio.
              </td>
            </tr>
            <tr className="blackcolour">
              <td>3. 3. 2021.</td>
              <td>
                Beroš o Bandićevom sprovodu: "Virus nije šampion skoka u dalj."
              </td>
            </tr>
            <tr>
              <td>4. 3. 2021.</td>
              <td>
                Božinović komentira tisuće na Bandićevom sprovodu na način da
                tvrdi da nadležni organi tek trebaju utvrditi je li došlo do
                prekoraćenja. Podsjetnik - zabranjena su okupljanja preko 25
                osoba.
              </td>
            </tr>
          </table>
          <br />
        </div>
        <br />
        <br />
        <br />
        <h4 className="tbl-stars">
          * Popis iznad nije potpun i konačan. Naime, zbog količine kršenja
          vlastitih mjera i odluka, broj takvih licemjernih ponašanja koji smo
          neformalno ispisali prema dijelu objava iz grupe je bio toliki da smo
          bili preglednosti radi izdvojiti tek manji dio primjera.{" "}
        </h4>
        <br />
        <br />
        <h4 className="tbl-stars">
          * Ovaj popis obuhvaća najviše 20-ak % zabilješki te vrste, vezanih
          samo uz vladajuće i njihov Stožer, bez uzimanja u obzir oporbe,
          ostalih političara na svim razinama i drugih koji su istupali u
          javnosti tražeći strogi pristup, a sami su licemjerno zaobilazili
          pravila.{" "}
        </h4>
        <br />
        <br />
        <h4 className="tbl-stars">
          Popis je obuhvatio razdoblje do samog početka ožujka 2021. budući da
          je u njemu negativno ponašanje bilo najizraženije i najočitije, ne
          uzimajući i mnoge druge primjere nakon toga.{" "}
        </h4>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="nscz-expl">
        <h3 className="make-black">
          Licemjerje u fotografijama (samo neki istaknuti primjeri)
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Već je vidljivo — Stožer i Vlada itekako su zloupotrebljavali
          izvanrednu situaciju (koju, usput, zakonski nisu niti proglasili —
          više pod pravnim procesima!) i koristili represivne i stroge mjere za
          građane, dok su sebi, političkim poslušnicima i "uglednicima"
          odobravali izuzetke, ustupke i pogodnosti. Takvo ponašanje nije bilo
          jednokratno, već svakodnevno. Tako smo u grupi "Tražimo ukidanje
          Nacionalnog stožera civilne zaštite" svaki dan prozivali i davali
          primjere, slike i komentare neujednačenog ponašanja i licemjernih
          pravila i postupaka. Takvih je objava i komentara u grupi ispisano
          preko 10 000 u godinu i pol dana. Očito je da je širina zloupotrebe
          ljudskog zdravlja i straha bila enormnih razmjera i kao takva ne smije
          se nikada zaboraviti.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Ispod će biti priložena poveznica na našu veliku grupu na Facebooku
          gdje možeš danima i tjednima skrolati i čitati sadržaj i doslovno se
          čuditi koliko takvog ponašanja ima. Kako je količina toga očito
          enormna, ispod odabiremo nekoliko slika s primjerima takvog ponašanja,
          koji su bili snažnije popraćeni.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "#d82020",
            color: "white",
            paddingInline: "1rem",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
            letterSpacing: "1px",
            boxShadow: "0 0 16px gray",
          }}
        >
          Dok se pred kamerama građanima pričalo o nevidljivom neprijatelju, dok
          se prijetilo zatvorskim kaznama, dok su se čak neki počinitelji manjih
          prekršaja uhićivali i spremali u pritvore na mjesec dana, dok su za
          mirne prosvjede (ne ulazeći u nebuloze koje su se tamo često čule)
          dijelile prekršajne prijave i kazne — vladajući i političari sa strane
          su masovno kršili mjere i smijali se onima koji su postupali prema
          njihovim uputama, punima besmisla i nelogičnosti. Evo primjera:
        </h4>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br id="fotografije" />
      <br />
      <div className="nscz-images">
        <div className="nscz-images-box">
          <img
            src={require("../files/nscz1.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Članovi Stožera i Vlade, pa i sam premijer, slave izbornu pobjedu u
            srpnju 2020. kršeći sve moguće vlastite mjere, za koje građanima
            određuju stroge kazne i javne osude.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz2.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Premijer Plenković i članovi Vlade na otvaranju Pelješkog mosta,
            ljeto 2021. Veselo krše sve mjere dok su ograničenja građanima tada
            još uvijek na snazi, posebno marljivim radnicima u ugostiteljskom
            sektoru.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz3.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ministar zdravstva Vili Beroš bez maske u prodavaonici, božićno
            vrijeme 2021. Dotad su ovlašteni organi ostalim građanima već
            ispisali nekoliko tisuća kazni i opomena zbog istog. Ne i
            političarima, kojima je ovo uobičajeno ponašanje.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz4.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ljeto 2020. Čelnik Stožera gosp. Davor Božinović krši mjere nakon
            jedne od sjednica na kojima je s ostatkom Stožera pozivao na potrebu
            strogog i ozbiljnog ponašanja. Tih mjeseci su također ukinuli mnoga
            pravila samo za potrebe izbora, kao i nekoliko puta naknadno u 2021.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz5.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Gospođa Alemka Markotić iz Stožera bez maske i među ogromnom
            skupinom vjernika čita za Stepinčevo 2021. Nekoliko puta prije
            počinitelje značajno manjih "prekršaja" zvala bioteroristima i
            izvedenicama riječi "idioti".
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz6.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Vukovarska Kolona sjećanja 2021. (slično i 2020.). Iako su na snazi
            stroge zabrane okupljanja, vladajući zloupotrebljavaju nevine žrtve
            Grada heroja za stjecanje političkih bodova. Tvrde da opasnosti nema
            jer, kažu, ljude štiti "duševni mir". Ovo su riječi stručnjaka,
            kojima građani trebaju vjerovati.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz7.webp")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Andrej Plenković na turniru u Zadru 2020. Dodirivao se sa zaraženim
            tenisačem Novakom Đokovićem. Iako su svi u toj zoni dodira išli u
            izolaciju, premijer je pravila zaobišao jer je izborna kampanja bila
            u tijeku i gdje je redovito kršio tada još donekle stroga pravila.
            Otad se počinje snažnije primjenjivati pravilo o dopuštenosti
            kontakta od 15 minuta.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz8.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ožujak 2021. i sprovod Milana Bandića, koalicijskog partnera
            vladajućih i osobe protiv koje se vodio niz postupaka za teške
            malverzacije i koruptivna djela. Ostali građani tada svoje mrtve
            pozdravljaju pod rigoroznim ograničenjima. Beroševo opravdanje:
            "Virus nije šampion skoka u dalj".
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz9.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Veljača 2021. i sprovod Miroslava Tuđmana, saborskog zastupnika
            HDZ-a. Ostali građani tada svoje mrtve pozdravljaju pod rigoroznim
            ograničenjima. Ostalim sprovodima dozvoljeno je do 25 ljudi.
            Ministar Božinović komentirao da se na slikama ne vidi jasno stanje,
            možda i nije bilo kršenja mjera (parafr.).
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz10.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Procesija "Pod križen" na Hvaru u travnju 2020. Dok je cijela država
            u jednom od najstrožih lockdowna u svijetu, gosp. Krunoslav Capak
            potpisuje ovu iznimku, jednu od prvih od stotina koje će se
            zaredati. Član Stožera gosp. Beroš podrijetlom je iz tih krajeva,
            slučajno.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz11.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            U prvim tjednima jednog od najstrožih lockdowna u svijetu političari
            i ostali, saznalo se naknadno, okupljali su se tajno u klubu u
            Slovenskoj 9 u Zagrebu, jeli, pili i zabavljali kršeći sve mjere na
            snazi. Poljoprivredniku tih tjedana naplatili 8000 kn jer je sam
            otišao u polje usred izolacije. Opet ponovimo — sam.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz12.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Slika iz turističke sezone 2021., slično 2020. Iako se priča o
            strašnom nevidljivom neprijatelju (virusu), da mjesta opuštanju
            nema, da ljudi umiru, da su oni koji osporavaju autoritet stranačkom
            stožeru "idioti" — sva opasnost uvijek je naglo i potpuno nestala
            kada državi trebaju novci turista. Novci za plaće i druge benefite
            raznim uhljebima.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz13.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Kraj srpnja 2020. — slučaj Hrebak, možda i najbahatiji oblik
            bezobrazluka. Iako je prema važećim pravilima zbog dugotrajnog
            boravka u prostoriji sa zaraženim u Bjelovaru trebao u
            samoizolaciju, gosp. Capak potpisuje mu posebno rješenje kojim je
            izbjegao pravilo i došao na konstituirajuću sjednicu Sabora. Bio je
            glas potreban za potvrdu većine u parlamentu.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz14.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Masovno okupljanje Torcide krajem 2020. Ni policija ni Stožer ne
            reagiraju jer se boje zamjerati većoj organiziranoj skupini. Zato
            dodjeljuju kazne i prijave mirnim prosvjednicima koji su svoje
            prosvjede uredno prijavili (primjer 8. studenog 2020.).
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz16.webp")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Javno praćenje jedne od niza utakmica hrvatske nogometne
            reprezentacije u ljeto 2021., protiv Češke. S druge strane
            poduzetnicima i radnicima ograničava se posao, sportašima treninzi,
            sportskim djelatnicima aktivnosti, a pogrebima dostojni uobičajeni
            oproštaji od pokojnika.
          </h4>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className=" something">
        <div className="black-div">
          <h3>
            OPASNE I DUGOTRAJNE POSLJEDICE OVAKVOG PONAŠANJA VLASTI I SVIH ONIH
            KOJI SU SLIJEPO I NEKRITIČKI PROMATRALI NELOGIČNE MJERE
            <br />
            <br />{" "}
            <a
              href="https://www.facebook.com/groups/3430335050311881/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#d82020",
                backgroundColor: "white",
                padding: "0.25rem 1rem",
                borderRadius: "10px",
                fontSize: "clamp(var(--clamp-site-text))",
              }}
            >
              FACEBOOK
            </a>
          </h3>
          <img
            alt="Znak STOP - asocijacija na suprostavljanje diskriminaciji kroz kolektivnu sudsku tužbu protiv Nacionalnog stožera i države zbog mjera protiv epidemije COVID-19 kojeg uzrokuje koronavirus - STOP diskriminaciji"
            src={require("../files/nepovjerenje.jpeg")}
            style={{ backgroundColor: "white" }}
          />
        </div>

        <h3 className="mb-link centre-txt">Gubitak povjerenja u institucije</h3>
        <br />

        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Nažalost, povjerenje građana prema gotovo svim javnim institucijama
          redovito je nisko i u mjesečnim istraživanjima najčešće ocjene od 1 do
          5 jesu 2 ili slabija 3. Međutim, nakon ovakve epizode, to je oslabilo
          dodatno. Glasačko tijelo HDZ-a je uvijek stabilno, ali ostatak oporbe
          je zbog svog lošeg odgovora i šutnje kažnjen u početkom 2022. nalaze
          se u sramotnom zaostatku za vladajućima, iako su ovi opterećeni nizom
          teških afera i skandala. Oporba je praktički na samrti i gore stoji
          nego ikad prije.
        </h4>

        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Također, vršeno je nekoliko ispitivanja pa je prema jednom od njih
          povjerenje u sam Stožer 2021. usred koronakrize bilo tek devet (9) % —{" "}
          <a
            href="https://www.novilist.hr/novosti/hrvatska/nacionalnom-stozeru-vjeruje-tek-9-posto-gradana-medicinske-sestre-na-vrhu-politicari-na-dnu-liste-povjerenja/"
            target="_blank"
            rel="noreferrer"
          >
            članak u Novom listu
          </a>
          . Političari općenito imali su rejting gori nego ikad prije, a čak i
          niže povjerenje od gotovo nepostojećeg u Stožer ostvarili su — mediji.
          Ovakvo stanje izuzetno je opasno za razvoj demokratskih institucija u
          Hrvatskoj, a posljedica je niza agresivaca koji su to činili često
          nesvjesno, da bi ostvarili pet minuta slave. Ne samo političari, već i
          osobe iz drugih pora našeg društva.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Jačanje alternativnih teorija i potpora diktaturama ili autokracijama
        </h3>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Cijelo vrijeme djelovanja Stožera omogućilo je i mnogim radikalima da
          promoviraju svoje stavove. Oni su dolazili i s područja ekstremne
          desnice i ljevice, ali i kao pojedinci koji su ekstremističkim i
          huškačkim stavovima sprječavali da se fokus usmjeri na glavnog krivca,
          Stožer, već su ga prebacivali na građane ili sebe međusobno. Tu se
          posebno ističe s jedne strane Saša Srića, a s druge Gordan Lauc, koji
          su se plašili suprotstaviti Vladi zbog odluka, već su ju branili.
        </h4>

        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Također, opće nepovjerenje u znanost, neka dostignuća iste i struku
          općenito poraslo je na visoke razine, što se vidjelo i prema brojnim
          anketama u tom smjeru. Strašno je i zamisliti što bi se dogodilo kada
          bi svijet snašla epidemija na razini kuge iz 14. stoljeća s ovakvim
          razorenim povjerenjem u društvu, za koje odgovorni fanatici u vlasti,
          oporbi i ostatku društva nisu dobili niti dostojnu kritiku.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Na društvenim mrežama osobito, ali i u komunikaciji izvan njih, teško
          je ne primijetiti da jedan nemali broj građana pokazuje pojačane
          simpatije prema nekim nedemokratskim sustavima, poput onih u NR Kini,
          Rusiji ili čak Sjevernoj Koreji. Nažalost, u početku su tome
          doprinosili i neki znanstvenici koji su veličali podosta nehumani
          odgovor Kine protiv širenja bolesti, što se ubrzo vratilo kao
          bumerang. Dok su zapadne zemlje prosvjednike tjerali šok-bombama i
          ledenom vodom zimi, Rusija i Kina skupljali su bodove, kao i svatko
          tko bi bilo kako istupio protiv Zapada. Često pretjerano i
          neopravdano, ali u takvom smo se stanju jednostavno našli.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Naime, jedan od glavnih prigovora Zapada Rusiji bio je onaj za
          represiju protiv prosvjeda, odnosno da često prosvjednike tjera
          nasilno, ne odobrava prosvjedne povorke ili da koristi izgovore svih
          oblika da ih onemogući. Nažalost, upravo sve to radili su mnogi
          radikali u svojim zemljama, što je mnogima stvorilo (većinom krivi)
          dojam da je Zapad i gori od tih država koje kritizira.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Odražaj na napad Ruske Federacije na susjednu Ukrajinu
        </h3>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          24. veljače 2022. Rusija je započela vojni napad na susjednu Ukrajinu
          s ciljem da ju "oslobodi", "denacificira", "demilitalizira" i kazni
          njihove "nacionaliste". Naravno, zdravom razumu svi su ti argumenti
          suludi jer su, baš svi odreda, primjenjivi i na samu Rusiju pa nitko
          normalan zbog toga ne bi opravdavao agresiju na tu državu od bilo
          koga. Ne ulazeći u temu licemjerja NATO-saveza, i ovdje smo došli do
          situacije u kojoj dvoznamenkasti postotak naših građana i zemalja EU
          traži opravdanja ne samo za ovaj rat, već i razne druge oblike nasilja
          usmjerene prema svemu što ima veze sa Zapadom. Jedna anketa iz
          proljeća 2022. iznosi podatak od oko 20-ak % ljudi koji podržavaju
          rat, a lako moguće da je realno stanje i gore. Veće jedinstvo sigurno
          bi bilo osigurano da je Zapad tijekom epidemije odabrao umjereni
          odgovor, utemeljen na edukaciji, s manje licemjerja i kršenja
          vlastitih pravila. Ali nije, odabrao je model koji bismo prije 2020.
          opisali kao ruski ili kineski.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Više karcinoma, manje lijekova. Inflacija, poskupljenja, nestašice i
          dugoročno lošiji standard.
        </h3>
        <br />

        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Usred naglog uvođenja represivnih mjera i ograničenja koja su
          zaustavila, usporila ili smanjila opseg gospodarske aktivnosti i s
          druge strane zdravstvenog sustava, proizašle su mnoge posljedice. Već
          krajem 2021. inflacija je zbog naglo oživljenog gospodarstva počela
          snažno rasti, dok će rat u Ukrajini to samo otežati. Inflacija je
          većinom teško stanje koje se ne rješava u tjednima, nego i godinama.
          Već početkom 2022. cijene uobičajenih namirnica u trgovinama bile su
          osjetno više, a što će s vremenom povlačiti sa sobom nastavak pada
          životnog standarda, teži pristup obrazovanju siromašnijima, pad realne
          vrijednosti plaće, nestašice raznih potrepština, opću nesigurnost i
          dodatni rast ekstremizma, kao i uvijek u ovakvim situacijama.
        </h4>

        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Također, u proljeće 2022. prvi put je u javnosti proizišla informacija
          da bi moglo doći i do nestanka određenih vrsta lijekova. Hoće li tako
          i biti, vrijeme će pokazati, ali ono što je sigurno je to da su dugovi
          zdravstva drastično porasli. Istog onog koje je i prije jedva sklapalo
          kraj s krajem. Ono što se saznalo još i tijekom 2020. je da su desetci
          tisuća zdravstvenih pregleda zbog "korone" otkazani i da će nam kroz
          godine to dolaziti na naplatu u vidu povišenog broja karcinoma i smrti
          od njih.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "yellow",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Naravno, svi oni koji su primjenjivali radikalni pristup,
          onemogućavali propitkivanje rada Stožera, suludih mjera pa i traženje
          alternative mjerama — neće građanima platiti veću cijenu kruha. Neće
          se boriti dan i noć da vrate povjerenje u institucije. Neće riješiti
          dodatne dugove koje su nagomilali zdravstvu. Neće doći do oboljelih od
          roka zbog propuštenih pregleda i iskazati im podršku. Neće pokazati
          razumijevanje za sve nepravde koje su oni kroz svoje licemjerne i
          nelogične odluke uzrokovali da se osjeti jedan ružan osjećaj —
          nepravda.
        </h4>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="nscz-expl">
        <h3 className="make-black">
          Mladi tijekom epidemije — zloupotreba djece u političke svrhe,
          psihičke i obrazovne poteškoće
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Mladi, a i svi oni koji se tako osjećaju (jer, uostalom, mi ljudi smo
          takve podjele i stvorili, često iz statističkih potreba), posebna su
          skupina. Oni razvijaju svoje poglede na život, obrazuju se, uče,
          uklapaju se u društvo. Češće su osjetljivi na nepravdu jer je se nisu
          još toliko nagledali kroz život, a okvirno ih smatramo iskrenijima od
          starijih ljudi, osobito u javnom djelovanju.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Nažalost, oni imaju i najveću manu — ne tretira ih se kao previše
          važno biračko tijelo. Zato su njihove potrebe u politici uglavnom
          sekundarne, a tako je bilo i tijekom epidemije. Saborski zastupnici
          najveći su dio krize gledali isključivo starije osobe. Tvrdili su da
          je razlog "briga o zdravlju", a u stvarnosti su licemjerno koristili
          njihovu starost za skupljanje političkih bodova jer su oni češće
          glasačko tijelo.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Upravo stoga je naša velika grupa na Facebooku "Tražimo ukidanje
          Nacionalnog stožera civilne zaštite" bila jedno od rijetkih mjesta
          gdje su mladi mogli iznositi svoje probleme i kritike odluka vlasti i
          Stožera, ukazati na dvostruke kriterije i svoju zapostavljenost. Na
          taj smo način bili jedina inicijativa koja je tu skupinu zastupala i
          pomagala im na kakav-takav način i da se njihovu problemi neizravno
          proguraju u javnost.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Ovakva nesklonost politike i javnosti prema mlađoj populaciji samo je
          dodatno ojačala njihovo nepovjerenje u naše vrijednosti i sustav,
          osigurala im lošiji pristup obrazovanju i lošiju kvalitetu obrazovnog
          sustava (što se može vratiti kao bumerang upravo odgovornima). S
          obzirom da su kod njih stroge mjere izazivale snažniju psihičku
          reakciju, potpuno je skandalozno da u Stožeru od prvog dana do danas
          nije osnovan neki oblik podrške za psihološke probleme koje proizvode
          mjere. Problemi iz podnaslova su ipak s vremenom postali češći u
          javnosti, a mi ćemo se truditi o njima pisati i u postpandemijsko doba
          da iz grešaka barem nešto naučimo. Glede djece, u početnim danima
          Stožera svjedočili smo i ovom sa slike ispod.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <img
          style={{ borderRadius: "30px", boxShadow: "0 0 16px silver" }}
          src={require("../files/vilijunak.png")}
          alt="Zloupotreba djece u političke svrhe gdje roditelji koriste djecu da Vilija Beroša, Davora Božinovića, Capaka i ostale crtaju kao heroje"
          draggable="false"
        />{" "}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Situacije koje vidimo iznad u početcima su bile vrlo česte. Slike
          djece koja pjevaju i hvale Stožer, često da još ne idu niti u prvi
          razred osnovne škole, obilazile su Hrvatsku. Iako većina te djece nije
          imala realno pojma tko su Vili Beroš, Kruno Capak, Davor Božinović,
          Alemka Markotić, svejedno su nekako znali da su "superjunaci" i
          spasitelji. Naravno, djeca su najiskreniji dio ljudskog roda i nema
          govora o njihovoj krivnji, već to pitanje treba postaviti roditeljima,
          a onda i političarima koji bi trebali pripaziti općenito s
          iskorištavanjem djece i gradnjom rejtinga na njima.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "#d82020",
            color: "white",
            paddingInline: "1rem",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
            letterSpacing: "1px",
            boxShadow: "0 0 16px gray",
          }}
        >
          Konačno, kao inicijativa od mnogih mladih zaprimili smo i niz kritika
          na ponašanje medija prema njima kroz 2020. i 2021. Isti se odnose na
          način pisanja članaka vezanih uz njihova okupljanja, zabave i ulogu
          tijekom korone. Tako su članci okupljanje na Jarunu početkom 2021.
          izrazito oštro kritizirali, a u jednom obraćanju s terena reporterka
          se pitala i zašto "policija nije radila svoj dio posla", aludirajući
          da je valjda trebala vodenim topovima otjerati ljude. Apsurdno tim
          više jer isti takav ton i osuda nisu uslijedili za barem 30 puta više
          takvog ili goreg ponašanja vladajućih i donositelja odluka.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Posljedice su da po jednom ispitivanju tek oko pet (5) % mladih
          iskazuje povjerenje u medije, što svakako nije dobro za razvoj
          demokratskog modernog društva kojem težimo. Bilo ih je još nekoliko,
          ali rezultatima je isto da su po tom pitanju uvijek poražavajući. U
          svakom slučaju, jedna od motivacija "Dosta je bilo" bit će upravo
          istaknuti probleme na koje ova skupina ljudi nailazi i nuditi
          prijedloge kako im pomoći i spriječiti da se epizoda iz pandemije ne
          ponavlja.
        </h4>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="something">
        <div className="black-div">
          <h3>
            SUMNJE NA KORIŠTENJE EPIDEMIJE ZA PROFITERSTVO, NEZAKONITE I
            KORUPTIVNE RADNJE
          </h3>
          <img
            alt="Znak STOP - asocijacija na suprostavljanje diskriminaciji kroz kolektivnu sudsku tužbu protiv Nacionalnog stožera i države zbog mjera protiv epidemije COVID-19 kojeg uzrokuje koronavirus - STOP diskriminaciji"
            src={require("../files/korupcija.png")}
            style={{ backgroundColor: "white" }}
          />
        </div>
        <h3 className="mb-link centre-txt">
          Multimilijunske afere oko izrada web-stranica
        </h3>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Od prvog dana nastanka Stožera razuman čovjek mogao si je postaviti
          pitanje — što nam jamči da bilo kakav prijenos velikih i ne baš
          demokratskih ovlasti na sebe vladajući neće zloupotrijebiti? Uostalom,
          tada 2020. baš su se bližili parlamentarni izbori. Na kraju je do te
          zloupotrebe, vidjeli smo iznad, i došlo. HDZ je na izborima profitirao
          i pomalo nenadano osvojio čvrstu većinu i održao se na vlasti. Sreća u
          nesreći je da smo kroz grupu na Facebooku, kao jednu od najvećih koja
          se bavi javnim djelovanjem u Hrvatskoj, iskoristili da takve stvari
          propitkujemo i namećemo javnosti. Tako smo se često pitali i izvlače
          li vladajući i kakvu financijsku korist iz svega.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Ipak, njihov premijer Ivo Sanader i u ratu zamjenik ministra koristio
          je, prema naknadno osporenoj preusudi pod čudnim razlozima, situaciju
          za stjecanje profita u slučaju Hypo, dok su ljudi bili pod ratnim
          strahom, a mnogi i žrtvovali svoju mladost, zdravlje i živote braneći
          upravo razne kriminalce i naknadno osuđivane lopove. Nema razloga ne
          vjerovati da bi se takav oblik profiterstva mogao dogoditi i tijekom
          korone, pod izlikom patetične brige o zaštiti zdravlja građana. Tako
          je i bilo. U javnost je izašlo tek nekoliko afera, a pitanje je kolika
          će masa curiti s vremenom. Jer uglavnom tek s vremenom se kriminalne
          radnje i otkrivaju.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Tako je i bilo. U javnost je izašlo tek nekoliko afera, a pitanje je
          kolika će masa curiti s vremenom. Jer uglavnom tek s vremenom se
          kriminalne radnje i otkrivaju. Najpoznatiji primjeri bili su sumnjiva
          izrada koronavirus.hr bez natječaja, uz dodjelu 1.1 milijun kuna
          ionako prezaduženog zdravstva, a onda i nova IT-afera oko tvrtke
          Cuspis, kojoj je ministar Vili dodijelio višestruko preplaćeni posao,
          a s vlasnikom, slučajno, ima osobne poveznice. Ispod je nekoliko
          poveznica na članke koji dotiču neke od spornih slučaja.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Više o nekoliko (zasad) otkrivenih afera: izrada koronavirus.hr i
          cijepise.hr
        </h3>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          <a
            href="https://www.index.hr/vijesti/clanak/doznajemo-za-koronavirushr-beros-bez-natjecaja-agenciji-isplatio-11-milijun-kuna/2238027.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Beroš agenciji bez natječaja isplatio 1.1 milijun kuna za stranicu
            koronavirus.hr
          </a>
          <br />
          <br />
          <a
            href="https://www.24sata.hr/news/vili-zasto-bacili-45-milijuna-na-cijepise-za-taj-novac-160-ljudi-moglo-je-dobiti-lijek-za-rak-756941"
            target="_blank"
            rel="noreferrer"
          >
            Bacili 4.3 milijuna na cijepise.hr. Za taj novac 160 ljudi moglo je
            dobiti lijek za rak
          </a>
          <br />
          <br />
          <a
            href="https://zagrebi.hr/promasena-aplikacija-cijepise-donijela-je-berosevom-prijatelju-vinku-kojundzicu-275-milijuna-kuna-u-12-dana/"
            target="_blank"
            rel="noreferrer"
          >
            Promašena aplikacija CijepiSe donijela je Beroševom prijatelju Vinku
            Kojundžiću 2.75 milijuna kuna u 12 dana
          </a>
          <br />
          <br />
          <a
            href="https://www.index.hr/vijesti/clanak/nova-stranica-koronavirushr-kostala-je-preko-600000-kn-strucnjaci-nisu-odusevljeni/2301371.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Nova stranica koronavirus.hr koštala je preko 600.000 kn
          </a>
          <br />
          <br />
          <a
            href="https://www.jutarnji.hr/vijesti/hrvatska/sumnjiv-posao-ministra-zdravstva-beros-prijatelju-isplatio-2-75-milijuna-kuna-za-12-dana-posla-15178648"
            target="_blank"
            rel="noreferrer"
          >
            Sumnjiv posao ministra zdravstva: Beroš prijatelju isplatio 2.75
            milijuna kuna za 12 dana posla
          </a>
          <br />
          <br />
          <a
            href="https://jadranski.hr/2022/04/02/drzavna-revizija-otkrila-beros-poznaniku-isplatio-275-milijuna-kuna-za-12-dana-rada/"
            target="_blank"
            rel="noreferrer"
          >
            Državna revizija otkrila: Beroš poznaniku isplatio 2.75 milijuna
            kuna za 12 dana rada
          </a>
          <br />
          <br />
          <a
            href="https://hr.n1info.com/vijesti/uskok-pokrenuo-izvide-o-berosevim-isplatama-kojundzicu-po-anonimnoj-prijavi/"
            target="_blank"
            rel="noreferrer"
          >
            Uskok pokrenuo izvide o Beroševim isplatama Kojundžiću
          </a>{" "}
          <br />
          <br />
          <a
            href="https://www.24sata.hr/news/super-vili-je-mogao-biti-heroj-a-bit-ce-tek-jos-jedan-muljator-826459"
            target="_blank"
            rel="noreferrer"
          >
            Super Vili je mogao biti heroj, a bit će tek još jedan muljator
          </a>{" "}
          <br />
          <br />
          <a
            href="https://www.index.hr/vijesti/clanak/beros-za-koronavirushr-dao-milijun-kuna-bez-natjecaja-sad-je-raspisao-novi-natjecaj/2270193.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Beroš za koronavirus.hr dao milijun kuna bez natječaja. Sad je
            raspisao novi natječaj
          </a>{" "}
          <br />
          <br />
          <a
            href="https://hr.n1info.com/vijesti/uskok-istrazuje-aferu-cijepise/"
            target="_blank"
            rel="noreferrer"
          >
            USKOK istražuje aferu cijepise.hr?
          </a>
        </h4>{" "}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Netransparentnost i stranački utjecaj u radu svih stožera i
          financijski troškovi
        </h3>
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Od prvih dana svog djelovanja, Stožer civilne zaštite na državnoj
          razini nikada nije izvješćivao javnost o sjednicama koje održava i
          načinu na koji se donose odluke. Doista, ne internetskim stranicama
          bilo kojeg ministarstva nemoguće je pronaći ikakav zapisnik sa
          sjednica, jesu li svi članovi prisustvovali raspravama, je li bilo
          moguće odluku donijeti ako je prisutan samo jedan član, što u takvim
          situacijama treba činiti, kakav je utjecaj premijera Plenkovića, kako
          se politika Vlade reflektira na ključne smjernice u radu tijela itd.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <img
          alt="Istraživanja su pokazala da je cijeli Stožer zaražen virusom HDZ-a"
          src={require("../files/hdzvirus.jpg")}
          className="hdz-virus"
          draggable="false"
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Također, postavljaju se pitanja jesu li svi članovi doista radili
          "volonterski" i jesu li financijski profitirali na neki neposredan
          način. Pitanje sukoba interesa pri odabiru određenih izvođača radova
          na IT-rješenjima i nabavkama u zdravstvu također ostaje nejasno.
          Možemo se pitati i je li sustav s tolikim brojem stožera (uključujući
          i lokalne razine) doista potreban i je li koristio ičemu, osim
          profiliranju pojedinaca preko stranačkih iskaznica i veza.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Pitanje je i koliki je bio utjecaj struke u tim tijelima, je li nje
          uopće bilo, kojim mehanizmima se to osiguravalo, je li se i na koji
          način vodilo brige i o ekonomskim posljedicama, psihološkom zdravlju,
          mladima i slično. Ukupno gledajući, jedno dosta tajanstveno i izrazito
          prostranačko tijelo bez ikakvih propisanih obrazaca i iza potpuno
          zatvorenih vrata donosilo je odluke o životima, zdravlju i budućnosti
          nekoliko milijuna građana Republike Hrvatske, a svojoj stranci
          donijelo je i ključni uspjeh za ostanak na vlasti 5. srpnja 2020. na
          parlamentarnim izborima. Bez obzira na teret koji su postali naknadno,
          Plenković će im na tome biti vječno zahvalan.
        </h4>
        <h4
          className="mb-link centre-txt"
          style={{
            color: "yellow",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          U svakom slučaju, Nacionalni stožer civilne zaštite bio je malo toga
          iz svog imena. Nacionalni je bio samo u suhoparnom značenju te riječi
          da je bio na nacionalnoj razini, ali nikad mu nacija nije bila
          prioritet. S civilnom zaštitom hrpetina nelogičnih mjera i pogodovanja
          nije imala veze jer je antidiskriminatornost osnovno obilježje sustava
          CZ-a. Bio je to tek stožer i to — stranački Stožer. Možda čak ne ni
          politički, koliko stranački.
        </h4>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <div className="nscz-expl">
        <br />
        <br />
        <br />
        <br />
        <h3 className="make-black">
          Facebook-grupa "Tražimo ukidanje Nacionalnog stožera civilne zaštite"
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Cilj je ove grupe djelovati protiv restriktivnih mjera i ograničavanja
          prava i sloboda građana od Nacionalnog stožera civilne zaštite. To
          tijelo smatramo opasnim tijelom koje je zapravo stranački stožer
          vladajuće koalicije i cijelo vrijeme zloupotrebljava tešku i
          novonastalu situaciju za ostvarivanje koristi u političkom smislu.
          Nastojat će se Stožeru poslati jasnu i nedvosmislenu poruku da su
          političke, dvosmislene i restriktivne mjere neprihvatljive u
          suvremenom društvu 21. stoljeća i otvoreno zadiru i u sam Ustav RH.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Temelj našeg djelovanja je upravo kontrolirati rad Vlade Republike
          Hrvatske i njenog tijela kako bismo ih prokazali i na taj način
          kompromitirali, a sve kako bi se izbjeglo da opet povrate utjecaj i
          kroz manipulaciju ljudskim strahom i nesigurnošću provode svoju
          predizbornu kampanju. Pratit ćemo i prozivati svaku nepravilnost u
          njihovom radu, a osobito u domeni dvostrukih kriterija i mjera,
          nejednakosti pred zakonima, utjecaja na sudske institucije da
          opravdaju diskriminaciju i neujednačeno postupanje prema raznim
          skupinama i slično.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "#d82020",
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            textAlign: "center",
            boxShadow: "0 0 16px grey",
          }}
        >
          VIŠE OD 10 000 OBJAVA I UKAZIVANJA NA DVOSTRUKE KRITERIJE,
          NEPRAVILNOSTI I NEPRAVDE
        </h4>
        <br />
        <br />
        <br />
        <img
          src={require("../files/fbgrupa.png")}
          alt="Naslovna stranica grupe na Facebooku Tražimo ukidanje Nacionalnog stožera civilne zaštite"
          draggable="false"
          style={{ boxShadow: "0 0 16px silver" }}
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="fb-link">
          <a
            href="https://www.facebook.com/groups/3430335050311881/"
            target="_blank"
            rel="noreferrer"
          >
            PREBACI ME U GRUPU NA FACEBOOK
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className="black-div">
        <h3>
          DISKRIMINATORNE, NELOGIČNE, NEPRAVEDNE, STRANAČKE I POLITIČKE ODLUKE,
          MJERE I PONAŠANJA NE PRIHVAĆAMO <br />
          <br /> PRATIMO VAŠ RAD I NAJESEN
        </h3>
        <img
          alt="Čekamo Stožer i Vladu i na jesen - STOP diskriminaciji (Dosta je bilo)"
          src={require("../files/moving.webp")}
          draggable="false"
        />
      </div>
    </div>
  );
}

export default TableNSCZ;
