import React from "react";
import "./Aims.css";

import { FaVolleyballBall } from "react-icons/fa";

function Aims() {
  return (
    <div className="aims">
      <div className="aim-box">
        <h3>Naši osnovni ciljevi</h3>
        <h5>Mi u nekoliko crtica</h5>
        <ul>
          <li>
            <FaVolleyballBall className="ball" />
            Mrzimo dvostruka mjerila i nejednakosti
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Ne želimo živjeti u društvu privilegiranih
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Trudimo se uvijek imati iste kriterije
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Vodimo se zdravim razumom i obrazovanjem, ne silom i ekstremizmom
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Ljude tretiramo kao razumne odrasle osobe, ne manipuliramo njima
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Otvoreni smo prema novim idejama ako će one pokrenuti zajednicu
            nabolje
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Ne želimo samo kritizirati, nego nuditi i prijedloge i iznositi
            ideje
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Nastojimo biti korektiv lošim pojavama i osobama u Hrvatskoj
          </li>
        </ul>
      </div>
      <div className="aim-box">
        <h3>Prepoznaješ li se u ovome?</h3>
        <h5>Ti u nekoliko crtica</h5>
        <ul>
          <li>
            {" "}
            <FaVolleyballBall className="ball" /> Isto si osjetljiv na
            licemjerje i nejednakost
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Spreman si stati na stranu i onoga s kime se ne slažeš, ako su
            ugrožene opće vrijednosti
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Imaš odlične ideje, ali nemaš ih kome izreći u Hrvatskoj
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Razumna si osoba, ne čekaš mesije i spasitelje kojima je cilj
            politički iskoristiti ljude za fotelje, sam djeluješ
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Imaš hrabrosti istupiti protiv onoga što ne valja oko tebe
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Ne bojiš se suprotstaviti bilo kome i na svaku glupost odgovaraš
            razumnim argumentima
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Nisi previše osjetljiv na ideološka pitanja i smatraš da je život
            više od toga
          </li>
          <li>
            <FaVolleyballBall className="ball" />
            Ne voliš se dodvoravati nikome, nego biti ravnopravni suradnik koji
            ne želi samo biti nečiji potrčko
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Aims;
