import React, { useEffect } from "react";
import "./News.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import NewsMore from "../components/NewsMore";

function News() {
  document.title = "Vijesti i komentari \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Vijesti, obavijesti, komentari i kritike nepravilnosti u Hrvatskoj, uz prijedloge za poboljšanja i stručne analize."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="news">
        <Header
          mainLabel="VIJESTI"
          otherLabel="Vijesti, obavijesti, komentari, kritike javnih događanja, osoba i nepravilnosti, stručne analize i prijedlozi"
        />
        <NewsMore />
      </div>
      <Footer />
    </div>
  );
}

export default News;
