import React, { useEffect } from "react";
import "./Lawsuit.css";

import Footer from "../components/Footer";
import Header from "../components/Header";

import { Link } from "react-router-dom";

function Lawsuit() {
  document.title = "Pravno djelovanje \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Dosta je bilo pokrenula je pravne korake, kolektivnu tužbu i slične radnje protiv Republike Hrvatske kada su stranke, političari i udruge civilnog društva šutjeli."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="lawsuit">
        <Header
          mainLabel="PRAVNO DJELOVANJE"
          otherLabel="Dok su ostale organizacije, političari i javnost šutjeli, djelovali smo konkretno"
        />
      </div>
      <div className="law-steps">
        <div className="laws-boxes">
          <div className="common">
            <Link to="/pravni-koraci/zupanijski-sud">
              <h3>ŽUPANIJSKI SUD</h3>
            </Link>
          </div>
          <div className="constitution">
            <Link to="/pravni-koraci/ustavni-sud">
              <h3>USTAVNI SUD</h3>
            </Link>
          </div>
          <div className="objection">
            <Link to="/pravni-koraci/vrhovni-sud">
              <h3>VRHOVNI SUD</h3>
            </Link>
          </div>
          <div className="citizen">
            <Link to="/pravni-koraci/opcinski-gradjanski-sud">
              <h3>
                OPĆINSKI GRAĐANSKI <br /> SUD
              </h3>
            </Link>
          </div>
        </div>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Nakon proglašenja stanja epidemije usred pojave i širenja u dijelovima
          svijeta novog virusa SARS-CoV-2 i bolesti COVID-19 koju on uzrokuje,
          temeljem tada postojećih odredbi, početkom 2020. godine, ustrojen je
          za područje Republike Hrvatske Stožer civilne zaštite koji je dobio
          zaduženje vođenja koordinacije u borbi protiv novonastalog izazova.
          Nacionalni stožer civilne zaštite (moguće korištenje kratice NSCZ)
          donio je niz odluka od trećeg mjeseca iste godine pa do dana
          pokretanja sudskog spora, temeljem ovlaštenja iz članka 22.a Zakona o
          sustavu civilne zaštite, a koje su, zbog svoje prirode, imale
          dalekosežan utjecaj na mnoge dijelove društva i države kao cjeline.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Većina tih utjecaja bila je izrazito negativnog karaktera, uzimajući u
          obzir da je Stožer civilne zaštite u praksi funkcionirao ne kao
          državno tijelo koje bi trebalo osiguravati općenarodne ciljeve iz
          domene sustava civilne zaštite, već kao privatno tijelo vladajuće
          Hrvatske demokratske zajednice i još više premijera Andreja
          Plenkovića. Na taj način došlo je do cijelog niza zloupotreba u kojima
          su ljudski strah, neznanje i nesigurnost korišteni za ostavrivanje
          političkih ciljeva, što, barem neformalno, poprilično asocira na
          "ratno" profiterstvo. Barem ako uzmemo da su u početku članovi Stožera
          često ponavljali da smo u ratu s nevidljivim neprijateljem.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Djelovanje Stožera obilovalo je neujednačenim pravilima koja za neke
          vrijede, dok za druge ne. Obilovalo je davanjem ustupaka povlaštenim
          skupinama i potencijalnim biračima, dok se druge za puno manje
          ozbiljne stvari prozivalo, osuđivalo, a nečesto i slalo u pritvore na
          30 dana. Obilovalo je namještanjem pravila da bi se mogli održati
          nacionalni ili stranački izbori ili razne druge političke, vjerske i
          ostale manifestacije, događaji, skupovi ili kolone. Obilovalo je
          stotinama situacija gdje su vladajući (i oporbeni političari) javno,
          besramno i bahato kršili vlastite mjere, a potom tražili uvođenje još
          strožih građanima. Popis "prijestupa" je ogroman, a da ne duljimo,
          savjetujemo sve pogledati na poveznici ispod.
        </h4>
        <br />
        <br />
        <Link className="link-ls" to="/inicijativa-protiv-stozera">
          <div className="docs-fin smaller">
            POGLEDAJ OGROMNU LISTU BAHATOG PONAŠANJA VLADAJUĆIH 2020. — 2021.
          </div>
        </Link>
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Na sve to nismo šutjeli. Iako smo preko velike grupe na Facebooku
          (Tražimo ukidanje Nacionalnog stožera civilne zaštite) ostvarivali
          izvanredne rezultate i mnijenje javnosti od pretežite podrške politici
          vladajućih oko epidemije preokrenuli do potpune omraženosti, odlučili
          smo se za korak dalje i pokretanje pravnih koraka osude neprimjerenog
          djelovanja Vlade i zloupotrebe državnih institucija za osobne i
          stranačke svrhe.
        </h4>
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Suštinski, naš osnovni pravni korak bilo je pokretanje udružne tužbe
          za zaštitu kolektivnih prava i interesa zbog sumnje u diskriminaciju i
          nejednako postupanje u odlukama, mjerama i ponašanju (nečinjenju)
          pripadnika Stožera i Vlade. Stoga smo pokrenuli takav korak. Razvoj
          situacije koji je uslijedio doveo je do podjele tužbe na više
          dijelova, ali svi koraci proizašli iz kolektivne tužbe ispod će u
          izborniku biti navedeni pod jednom poveznicom. Pokrenuli smo i
          mehanizme pred Ustavnim sudom, a koji su došli najdalje od svih
          prijedloga. Da ne duljimo, pripremili smo odgovarajuće poveznice na
          pojedine sudove pri početku stranice pa ih slobodno prouči.
        </h4>

        <div className="corrupt laws-corr">
          <img
            src={require("../files/nocorruption.png")}
            alt="Dosta je bilo - podrška transparentnosti i borbi protiv mita i korupcije u Hrvatskoj"
            draggable="false"
            className="stopcorr"
            style={{ width: "75%" }}
          />
          <h1>netransparentnosti</h1>
          <h1>malverzacijama</h1>
          <h1>korupciji</h1>
          <h5>
            "Dosta je bilo" od prvih se dana svog djelovanja, još kao neformalna
            inicijativa, vodi načelima transparentnosti, izbjegavanja svih
            oblika nezakonitosti, malverzacija i nepravilnosti i najstrože
            osuđuje bilo kakve koruptivne radnje pa čak i njezino opravdanje,
            toleriranje ili neprijavljivanje.{" "}
          </h5>
          <br />
          <h3>
            KORUPCIJA JE ODVRATNI KARCINOM KOJI UBIJA POVJERENJE U DEMOKRACIJU
          </h3>
          <br />
          <h5>
            Ako imaš bilo kakva saznanja za koruptivne radnje oko sebe, nuđenje,
            primanje mita, sumnju u bilo kakav oblik nepravilnosti na bilo kojoj
            razini oko tebe, imaš za to potvrdu, ali se ne osjećaš sigurno
            prijaviti, <a href="/kontakt">prijavi sumnju na korupciju nama</a> i
            mi ćemo ti pomoći ili odraditi provjeru i prijavu, pazeći potpuno na
            tvoju anonimnost.
          </h5>
          <p>
            Korupcija guši tvoje pravo da živiš bolje. Ne budi sudionik šutnjom.
          </p>
        </div>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}

export default Lawsuit;
