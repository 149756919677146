import React from "react";
import "./VolontiranjeDetails.css";
import { Link } from "react-router-dom";

function VolontiranjeDetails() {
  return (
    <div className="vd">
      <p>
        Drago nam je da si na ovoj stranici jer to znači da si jedan/na od onih
        koje ili zanima volontiranje ili traže način kako da izraze svoj stav u
        društvu. Da budemo izravni,{" "}
        <b>
          sada je pravo vrijeme da si ovdje i najbolji trenutak da iskažeš svoje
          stavove
        </b>
        . Pođimo nekim redom — što motivira nas, kako smo i ćemo djelovati, koje
        aktivnosti organiziramo i tebi možemo ponuditi, zašto je dobro da
        volontiraš u "Dosta je bilo" i slično.
      </p>
      <br />
      <br />
      <br />
      <br />
      <img
        src={require("../files/dostajeflag.png")}
        alt="London, crveni double decker bus na kat - Dosta je bilo, zastava i simbol"
        draggable="false"
        className="decker"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p>
        Zašto je sada najbolje vrijeme? Upravo negdje kroz koronakrizu društvo u
        Hrvatskoj zapalo je u stanje apatije, izraženiju više nego ikad.{" "}
        <b>
          Rezultat je to godina nesposobne vlasti, nepreglednog gomilanja
          korupcije i razočaranja u tzv. treće opcije
        </b>{" "}
        kroz političke stranke koje su izdavale svoja uvjerenja, odlazili u
        koalicije s onima za koje su obećavali da ne dolaze u obzir, potpisivali
        lažna obećanja kod javnih bilježnika, svađali se oko pozicija u
        parlamentima, iznosili sulude ideje itd. Iako veliki dio odgovornosti
        leži i na našem društvu,{" "}
        <b>
          kombinacija svih ovih čimbenika dovela je do faze u kojoj je više od
          pola društva — odustalo od svega
        </b>
        .{" "}
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <a href="volontiranje#akcije" className="act-vol">
        POGLEDAJ KONKRETNE AKCIJE
      </a>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p>
        Kada smo odlučili reći "dosta" Stožeru i svima onima koji su to
        stranačko tijelo podržavali na štetu građana i zdravog razuma, motivi su
        nam bili jasni.{" "}
        <b>
          Nismo htjeli dopustiti da pasivno promatramo kako zakoni ne vrijede za
          one koji ih donose, kako osobe koje donose protuepidemijska mjerila
          same iste krše masovno i bez srama, a osuđuju građane...
        </b>{" "}
        Nismo pristali biti građani drugog reda, već smo krenuli u civiliziranu
        ofenzivu i prokazali Nacionalni stožer kao tijelo stranke, a ne države.
        Iako je to mišljenje u početku{" "}
        <b>
          bilo marginalno, uspjeli smo ga progurati kao prevladavajuće u kratkom
          roku
        </b>
        .
      </p>
      <br />
      <br />
      <br />
      <div className="volont-img"></div>
      <br />
      <br />
      <br />
      <p>
        Zašto smo u tome uspjeli? Uspjeli smo jer smo djelovanje vodili načelima{" "}
        <b>
          razuma, odbacivanja ekstremizma i beskompromisno osuđujući sve koji su
          se ponašali licemjerno
        </b>
        . Nismo u fokusu imali sitnije ribe. Krivca smo tražili u jačima od sebe
        i onima koji krivac doista i jesu. Sve smo dodatno rješavali sudskim
        putem, nastojeći poštovati institucije i vratiti povjerenje u pravnu
        državu. <b>Takav oblik djelovanja nastavit ćemo koristiti i dalje</b>.
        Ako si se u svemu ovome iznad prepoznao, čitaj dalje jer se vjerojatno
        nalazimo na istoj valnoj duljini.{" "}
      </p>
      <br />
      <br />
      <br />
      <div className="volont-img"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond">Jesi li ti volonter/ka za "Dosta je bilo"?</p>
      <p className="for-links">
        Da bi u to bio/la siguran/na, možeš se prvo upoznati s nama još bolje pa
        pogledati naše <Link to="/program-i-ciljevi">programske ciljeve</Link>,
        tekst našeg <Link to="/statut">statuta</Link>, a predlažemo i vidjeti
        našu prvu veliku <Link to="pravni-koraci">inicijativu</Link> protiv
        neujednačenog postupanja državnih tijela tijekom epidemije koronavirusa
        od 2020. Puno je tu još informacija, ali ako te zanimaju, imaš našu
        web-stranicu i možeš ju slobodno istraživati. Ako ti je lakše,{" "}
        <Link to="/kontakt">tu smo</Link> ako nas želiš bilo što upitati.
      </p>
      <br />
      <br />
      <p>
        Jezgru onoga što bismo voljeli vidjeti od tebe možemo sažeti u nekoliko
        crtica. U osnovi, najbitnije je:
      </p>
      <br />
      <ul className="vol-ul">
        <li>
          da si hrabar i ne bojiš se stati iza svojih uvjerenja, i pred državom
          i pred ostalima,
        </li>
        <li>
          da se ne želiš dodvoravati nikome, nego uvijek biti na strani razuma,
          makar to možda ne bilo popularno uvijek,
        </li>
        <li>
          da su ti stavovi podudarni s našima (naravno, nitko ne traži da se
          slažemo u svemu ili da slaganje glumimo),
        </li>
        <li>
          da znaš čvrsto odgovoriti na svaku nepravilnost, nepravdu i
          nejednakost u primjeni zakona i pravila i nazvati stvari pravim
          imenom,
        </li>
        <li>
          ujedno si bistra, razumna, zrela i pametna osoba koja odbacuje i
          propitkuje sve sulude teorije, bilo da dolaze s "ove" ili "one"
          strane,
        </li>
        <li>
          nisi osjetljiv na ideološke razlike i shvaćaš da su to teme koje
          toliko ne pomažu građanima, koliko ih politika koristi za skupljanje
          jeftinih bodova na osjećajima i emocijama.{" "}
        </li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      {/*   <img
        src={require("../files/event.jpeg")}
        alt="Prosvjed 8. studenog 2020. na Trgu bana Jelačića u Zagrebu protiv Vlade Republike Hrvatske i Nacionalnog stožera civilne zaštite zbog dvostrukih mjerila"
        draggable="false"
        className="volont-img"
        style={{ objectPosition: "left" }}
      /> */}
      <div className="volont-img"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond">Zašto volontirati?</p>
      <ul className="vol-ul">
        <li>
          <b>Pomažeš društvu</b>. Svaka demokracija bila bi potpuna tek kada bi
          u njoj sudjelovali svi. Samo jedan tvoj pametan prijedlog koji je tek
          izrečen pomak je nabolje.
        </li>
        <li>
          {" "}
          <b>Pomažeš nama</b>. Svaki naš pojedinac ili ti sam teže može
          ostvariti svoje zamisli, nego u društvu. Nije svako društvo ispravno,
          ali zato smo tu da te uvjerimo da nama možeš posvetiti pažnju.
        </li>
        <li>
          <b>Podupireš obitelj, djecu, prijatelje</b>. Svaka kritika, prijedlog
          ili ideja stišće vlast i omogućava tvojim najdražima da ne odsele,
          nego žive u uređenijem društvu.
        </li>
        <li>
          <b>Pokazuješ hrabrost</b> dok drugi hodaju pognute glave i život
          provode pasivno, plašeći se bilo kome suprotstaviti.
        </li>
        <li>
          <b>
            Ne trebaš nam ništa prisilno plaćati, nema obveznih donacija i
            članarina, bitno nam je tvoje povjerenje.
          </b>
        </li>
        <li>
          <b>Fleksibilno je</b>. Nemaš nikakve obvezujuće rasporede, ocjene,
          šefove — iako nam je bitno da nam pomažeš iz uvjerenja.
        </li>
        <li>
          <b>Izgrađuješ se kao osoba</b> i oblikuješ jasnije i hrabrije svoje
          stavove.
        </li>
      </ul>{" "}
      <br />
      <br />
      <br />
      <div className="volont-img"></div>
      <br />
      <br />
      <br />
      <div className="volont-img"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond" id="akcije">
        Načini volontiranja
      </p>
      <p>
        "Dosta je bilo" kao nevladina neprofitna organizacija imat će razne
        aktivnosti kojima nastoji djelovati promičući svoj program, statut,
        ideje i prijedloge za poboljšanje društvene klime. Za potrebe toga
        potrebni su nam volonteri ili možda bolje reći suradnici kojima možemo
        vjerovati i koju to isto mogu nama. Cilj je da imamo{" "}
        <b>pouzdane, razumne i bistre osobe</b> koji se, barem okvirno, slažu s
        pročitanim iznad i općenito s onim što su pročitali na ovoj stranici.{" "}
      </p>
      <br />
      <br />
      <p>
        Pomoći nam možeš na razne načine. Zato smo ispod izdvojili neke od njih,
        ali ako ti imaš neko znanje, vještinu ili sposobnosti koje nismo naveli,
        a misliš da bi nam mogao pomoći da se zajedno istaknemo i smatraš da smo
        razumna organizacija kojoj vrijedi pokloniti dio svog truda — budi
        slobodan i javi nam pa ćemo već nešto smisliti. Odgovaramo na svaki upit
        pa ćemo ti se sigurno javiti s odgovorom, ne brini.
      </p>
      <br />
      <br />
      <ul className="vol-ul">
        <li>
          <b>Administrativna pomoć. </b> S obzirom da za pripremu naših članaka
          i analiza često trebamo pomoć na način da nam netko ispita neku temu,
          područje ili rad, možeš nam pomoći da naši volonteri koji se bave
          uskim područjem budu brži, a time i svi mi zajedno kao organizacija.
          Interno ćemo ti, kao i ostalima, dodijeliti sve upute. Ništa teško i
          naporno.
        </li>
        <li>
          <b>Održavanje reda na društvenim mrežama. </b> Društvene mreže novi su
          alat koji je zamijenio ili će svakog trena zamijeniti čak i medije, u
          smislu utjecaja na kreiranje javnog mnijenja. Iako ih smatramo
          pozitivnim iskorakom, na njima obitavaju i razni provokatori i
          radikali koji su sveprisutni. Tu nam možeš pomoći na način da u točno
          određeno vrijeme, kada ti dodijelimo taj dan tu mogućnost, pratiš i
          uređuješ komentare i pripaziš s ostalima da su u skladu s našim
          pravilima. Vrlo jednostavno, sve ti objasnimo.{" "}
        </li>
        <li>
          <b>Pisanje članaka i analiza u ime organizacije. </b>Ako si stručnjak
          za neko područje, poznaješ ga dobro, radiš u njemu, imaš solidno
          znanje i ideje kako nešto promijeniti, uspješan si student ili bilo
          tko drugi s konkretnim idejama, a ne floskulama — odlično. Nakon što
          provjerimo da bi se uklopio u naše statut i program i da si spreman,
          omogućujemo ti da nam iznosiš ideje i prijedloge za to polje.
        </li>
        <li>
          <b>
            Komentiranje aktualnih događanja, problema i nepravilnosti u
            društvu.{" "}
          </b>{" "}
          Ovo je nešto lakše od prethodnog pa ako više uživaš u praćenju
          događaja oko tebe i pravi si kritičar, osjetljiv na nepravdu,
          nepravilnosti, licemjerje i dvostruka mjerila, na dobrom smo putu.
          Uvjet je, naravno, da su ti barem većinski prihvatljivi naši statut i
          program.
        </li>
        <li>
          <b>Stručnjak za komunikacije i marketing. </b> Ako bi ostatku naše
          ekipe mogao pomoći savjetima iz područja komunikacija na način da naše
          izražavanje preko web-stranice "Dosta je bilo" i društvenih mreža
          postane još bolje i profesionalnije, kao i komunikacija prema državnim
          službama zbog raznih akcija, možeš pomoći. Isto vrijedi i za
          marketinške aktivnosti.
        </li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      <Link to="/kontakt" className="act-vol">
        ISPUNI OBRAZAC ZA PRIJAVU
      </Link>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="vday">
        <h3>MEĐUNARODNI DAN VOLONTERA</h3>
        <h4>5. prosinca</h4>
        <p>
          <b>
            Volontiranje je jedna od najiskrenijih aktivnosti gdje osoba
            poklanja svoje povjerenje, stručnost, volju i vrijeme zajednici kako
            bi ju oblikovala nabolje. Svim volonterima želimo sretan svaki dan
            njihovog marljivog i iskrenog rada za bolju zajednicu, ne samo ovaj.
          </b>
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <img
        src={require("../files/lights.jpeg")}
        alt="Osvjetljena soba s božićnim ukrasima"
        draggable="false"
        className="volont-img"
        style={{ objectFit: "cover" }}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <iframe
        width="560"
        height="415"
        src="https://www.youtube.com/embed/nILMdsY8uGk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "10px", boxShadow: "0 0 16px" }}
      ></iframe>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <iframe
        width="560"
        height="415"
        src="https://www.youtube.com/embed/EEcm2_W8FH4"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "10px", boxShadow: "0 0 16px" }}
      ></iframe>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond" id="akcije">
        Imaš našu podršku i detaljnu edukaciju o programu i ciljevima
      </p>
      <div className="red-warn">
        U slučaju da se odlučiš biti naš volonter, u pravilu ćemo slušati tvoje
        želje i dodijeliti ti da se baviš područjem o kojem posjeduješ znanje,
        iskustvo ili obrazovanje. Cijelo vrijeme davat ćemo ti dodatne savjete i
        materijale, a pobrinut ćemo se da te do detalja uputimo u naše ciljeve i
        program kako bismo bili što usklađeniji kao organizacija. Na volontere
        se ne stavlja nikakav pritisak i rad je isključivo prema dogovoru i uza
        sve poštovanje privatnih obveza osobe, što nam je prioritet.
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond" id="akcije">
        Ugovor i potvrda o volontiranju
      </p>
      <div className="yellow-warn">
        U skladu s važečim zakonom u trenutku trajanja tvog volontiranja
        osigurat ćemo ti pravovaljanu potvrdu o izvršenom volontiranju potpisanu
        od odgovorne osobe. U slučaju da ćeš biti naš volonter duže vremensko
        razdoblje, duže od nekoliko mjeseci — možeš s nama potpisati i ugovor o
        volontiranju koji će, naravno, potpuno pratiti sve odredbe primjenjivog
        Zakona. O tome ćemo te upoznati detaljno pa ćemo zajedno vidjeti što
        tebi najviše odgovara.
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond" id="akcije">
        Spremni smo ti osigurati i pismo preporuke
      </p>
      <div className="green-warn">
        Ako ćemo osigurati dužu, kvalitetnu i prijateljsku suradnju, možemo ti
        uz ugovor i potvrdu o volontiranju potpisati i pismo preporuke za koju
        god namjenu ti trebalo — dobivanje financijskih potpora za
        zapošljavanje, novce iz europskih ili nekih drugih javnih fondova,
        prijavu za određeni posao ili stažiranje i niz drugih situacija.
        Naravno, vrlo stručno, detaljno i pozitivno opisat ćemo tebe i pomoć
        koju si nam poklonio kao organizaciji civilnog društva.
      </div>
    </div>
  );
}

export default VolontiranjeDetails;
