import React, { useEffect } from "react";
import "./Statute.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import StatuteMore from "../components/StatuteMore";

/* import { Link } from "react-router-dom";
 */

function Statute() {
  document.title = "Statut \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Statut organizacije Dosta je bilo u tekstualnom i PDF-obliku odobrenom od skupštine i Gradskog ureda za opću upravu Grada Zagreba."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, statut primjer, primjer statuta, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, statut udruge, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="statute">
        <Header
          mainLabel="STATUT"
          otherLabel="Cjelokupan tekst statuta ispisan je ovdje, a moguće je preuzimanje u formatu PDF pri dnu stranice"
        />
        <StatuteMore />
      </div>
      <Footer />
    </div>
  );
}

export default Statute;
