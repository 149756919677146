import React from "react";
import "./InfoBox.css";

function InfoBox() {
  return (
    <div className="info-box">
      <div className="info-box-details">
        <h3>150 000 +</h3>
        <h5>DRUŠTVENE MREŽE</h5>
        <p>
          Na društvenim smo mrežama omogućili ogromnoj zajednici da iskaže svoje
          probleme, nezadovoljstva i kritike Vladinoj politici, dok drugdje nisu
          mogli.
        </p>
      </div>
      <div className="info-box-details">
        <h3>13 000 +</h3>
        <h5>PODRŽAVATELJA TUŽBE</h5>
        <p>
          Mnogi ljudi dali su nam potporu da pokušamo njihova prava zaštititi
          civiliziranim putem.
        </p>
      </div>
      <div className="info-box-details">
        <h3>365 DANA</h3>
        <h5>VOLONTIRANJA</h5>
        <p>
          Nismo Bandić i nikada to nećemo biti, ali dostupni 365 dana — jesmo
          :).
        </p>
      </div>
      <div className="info-box-details">
        <h3>10 000 +</h3>
        <h5>OBJAVA</h5>
        <p>
          Prepoznali smo važnost novog vremena i moderne tehnologije koristili
          za ono bitno — ljudska prava.
        </p>
      </div>
      <div className="info-box-details">
        <h3>TRI +</h3>
        <h5>PRAVNA KORAKA</h5>
        <p>
          Kolektivna tužba, prijedlozi pred tzv. Ustavnim sudom i niz drugih
          radnji...
        </p>
      </div>
      <div className="info-box-details">
        <h3>5000 +</h3>
        <h5>PORUKA ODGOVORENO</h5>
        <p>
          Odgovorili smo, ali i primili tisuće prijedloga i savjeta. Bez
          pretjerivanja.
        </p>
      </div>
      <br />
    </div>
  );
}

export default InfoBox;
