import React from "react";
import "./WhyDJB.css";

function WhyDJB() {
  return (
    <div className="something scd-img">
      <div className="black-div scd-img">
        <h3>
          „ SVA RATNA PROPAGANDA, SVE DERANJE, SVE LAŽI, SVA MRŽNJA I OBJEDE —
          DOLAZE ISKLJUČIVO OD LJUDI KOJI SE NE BORE ”
        </h3>
        <img
          alt="Logo Dosta je bilo, crvena i crna boja u krugu, valovita granica"
          src={require("../files/dostaje.png")}
          style={{ backgroundColor: "white" }}
        />
      </div>
      <br />
      <br />
      <h3 className="mb-link centre-txt">
        Zašto "Dosta je bilo" i koji je bio glavni povod?
      </h3>
      <br />
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          color: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Sredinom 2020. svijet, a ubrzo i Hrvatska, suočili su se s pojavom novog
        virusa SARS-CoV-2 i bolesti COVID-19 koju on uzrokuje. Kao i svaka
        pojava bilo čega novoga u povijesti, ovaj događaj izazvao je strah
        građana. Vlada Republike Hrvatske preko novoosnovanog Stožera civilne
        zaštite u početku je čak primijenila razuman pristup. Građane se
        pozivalo na smirenost i borbu s novom bolesti pokušalo se voditi kroz
        stvaranje povjerenja i hladni pristup.
      </h4>
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Međutim, vidjevši da se ljudski strah može iskoristiti i da se na njihov
        rad gleda gotovo nekritički i čak s pretjeranim hvalama, Vlada je Stožer
        pretvorila u stranačko tijelo koje će "prikriveno" voditi predizbornu
        kampanju. Sasvim slučajno, ubrzo su se spremali parlamentarni izbori na
        kojima se vladajućima predviđao loš rezultat. Taj loš rezultat Stožer će
        uspješno popraviti na kraju, koristivši naivnost građana.{" "}
      </h4>
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Gledali smo da se na drzak, bezobrazan i podmukao način manipulira
        ljudskom panikom, a u tu svrhu koriste se i vrtićka djeca koja te
        političare zovu junacima, iako nisu niti svjesna tko su ti ljudi zbog
        svojih godina. Gledali smo prve ustupke povlaštenim skupinama i to od
        licemjernih ljudi koji su se mjera držali samo pred kamerama, čak s
        vremenom niti tada.{" "}
      </h4>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="mb-link centre-txt">
        Odlučili smo reći "dosta" jer su ostali šutjeli i čekali
      </h3>
      <br />
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Gledali smo ljude koji su naše građane zvali "idiotima" i slali ih čak
        30 dana u pritvore jer su pili piće u kafićima s nekoliko ljudi. Dok su
        te osobe ležale u ćelijama, licemjeri koji su ih tu slali okupljali su
        se u Slovenskoj 9, pili, jeli, smijali se, uživali u životu, a sutradan
        odlazili pred kamere prozivati mlade za neodgovornost i uvoditi im nova
        ograničenja. Virus koji je na razini gripa, kako su ponavljali uporno,
        za potrebe izbora postao je "nevidljivi opasni neprijatelj". Budući da
        na ovu nepravdu (a koja je bila tek blagi početak dvostrukih mjerila)
        nitko nije reagirao, odlučili smo konačno reći "DOSTA".
      </h4>
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          color: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Nepravedno ponašanje i dvostruki kriteriji postali su svakodnevni, a u
        pojedinim situacijama, nažalost, uočavali smo i javni linč, gdje je
        najpoznatiji primjer poljoprivrednik koji je sam otišao u polje, ne
        ugrožavajući ničiji život, a dočekala ga je osuda sa svih strana i
        komentari ispunjeni prijetnjama. Isti tretman političari nisu doživjeli
        za puno gore prekršaje. Budući da reakcija nije bilo čak ni u oporbi,
        koja je tada podržavala rad Stožera, pljeskala mu i kukavički zatvarala
        oči na pretvaranje svojih građana u građane drugog reda, odlučili smo
        sami stati na kraj takvom bahatom ponašanju i započeli inicijativu na
        Facebooku kroz grupu "Tražimo ukidanje Nacionalnog stožera civilne
        zaštite".{" "}
      </h4>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 className="mb-link centre-txt">
        Jedina smo inicijativa koja je branila građane i bez kompromisa
        prozivala licemjerje i dvostruka mjerila
      </h3>
      <br />
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          color: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        U početcima epidemije prepoznali smo pokvarenost Vlade, ali smo vidjeli
        i da protivljenje takvom ponašanju često vode skupine kojima uopće nije
        stalo do toga da zaustave Vladu i izbore se za jednakost građana. Većina
        njih koristila je situaciju za samopromociju i širenje suludih
        političkih i drugih teorija, a pojedinci su se čak predstavljali kao
        protivnici mjera, ali su pritom na podmukao način cijelo vrijeme branili
        i hvalili Vladu. Treća skupina bili su agresivci koji su tražili vrlo
        strogi pristup i mjere, ne misleći na ljudske poslove, sudbine i patnje
        koje im to može uzrokovati.
      </h4>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/*       
      <div className="black-div">
        <h3>
          SMORE NEĆEŠ PRIJEĆI BUDEŠ LI SAMO TAKO STAJAO ZAGLEDAN U VODU
        </h3>
        <img
          alt="Logo Dosta je bilo, crvena i crna boja u krugu, valovita granica"
          src={require("../files/dostaje.png")}
          style={{ backgroundColor: "white" }}
        />
      </div>
 */}
      <br />
      <br />
      <h3 className="mb-link centre-txt">
        Usmjeravali smo prokazivanje stranačkog Stožera u smjeru zdravog razuma,
        bez gluposti i radikalizma
      </h3>
      <br />
      <br />

      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          color: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Grupa je, kako smo čuli i u povratnim informacijama, postala jedno od
        rijetkih mjesta zdravog razuma u kojima su građani mogli slobodno
        kritizirati i prozivati vladajuće i pisati što im zamjeraju. Čvrsto i
        bez dlake na jeziku. Da ne ostane samo na tome, organizirali smo i
        kolektivnu tužbu i druge pravne radnje, ali i naknadne odgovore zbog
        loše reakcije sudstva na njih.
      </h4>
      <br />
      <h4
        className="expl-admin mb-link centre-txt"
        style={{
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
          lineHeight: "1.3",
        }}
      >
        Tijekom cijelog svog djelovanja vodili smo se osnovnim načelom
        ljudskosti — nikad ne napadaj slabijeg od sebe. U životinjskom svijetu
        opstaju jači, oni koji prepoznaju slabije od sebe i nad njima se iskažu.
        Ljudi ipak trebaju biti više od životinja. Zato krivce za nejednakost
        pred zakonima, pravila koja se primjenjuju samo na ljudima i hrpu
        ustupaka nismo tražili u zamišljenim organizacijama, medicini,
        znanstvenicima, fanaticima željnima pet minuta slave. Prst smo bez
        straha uprli u glavnog krivca i onog jačeg od sebe — Vladu i njihov
        Stožer. Time ćemo se voditi i dalje.
      </h4>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default WhyDJB;
