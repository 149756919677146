import React from "react";
import "./PrivacyDetails.css";

function PrivacyDetails() {
  return (
    <div className="pd">
      <h3 className="pd-label">Što su pravila o zaštiti podataka?</h3>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Pravila o obradi i posljedičnoj zaštiti podataka su pravila koja
        određuju način na koji "Dosta je bilo" prikuplja, obrađuje i
        upotrebljava sve vaše podatke, prije svega one osobne i druge povezane s
        korištenjem internetske stranice https://dostajebilo.hr. Ujedno
        označavamo da je "Dosta je bilo", kao nevladina neprofitna organizacija
        registrirana pod OIB-om 72276005315 u Gradu Zagrebu, temeljem službenog
        odobrenja u Rješenju Gradskog ureda za poslove opće uprave prihvaćena
        kao voditelj obrade podataka. U skladu s time, sva pitanja, nejasnoće,
        sporovi i zahtjevi obvezno se rješavaju slanjem pisanog upita
        elektroničkim putem na adresu info@dostajebilo.hr ili
        dostajebilo@dostajebilo.hr.
      </h4>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Tu se izdvaja situacija u kojoj se na našim stranicama osoba služi
        poveznicama na stranice, usluge i općenito sadržaj drugih kompanija,
        posebice onih sa sjedištem ili djelovanjem izvan teritorija Republike
        Hrvatske. Za pitanja i sve radnje vezane uz osobne podatke osoba se u
        tom slučaju može isključivo obratiti toj kompaniji ili bilo kojem
        pravnom subjektu. "Dosta je bilo" nema nikakav utjecaj ili saznanja o
        oblikovanju tuđih politika privatnosti i shodno tome ne snosi nikakvu
        zakonsku odgovornost.
      </h4>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ponavljamo da "Dosta je bilo" provodi sve zakonske upute u skladu sa
        zakonodavstvima Republike Hrvatske i Europske unije, prije svega Općom
        regulativom o zaštiti podataka (<em>engl. </em> GDPR — General data
        protection regulation). Unatoč tome, pojedinci ili pojedine organizacije
        nalaze razne načine kojim pokušavaju manipulativno povrijediti vašu
        privatnost i podatke. Stoga vas podsjećamo da nikada ne ostavljate
        oosbne podatke vezane uz komunikaciju s nama drugim stranama, a ako
        postoje sumnje u zloupotrebu podataka, da nam to odmah javite na
        gorespomenute adrese elektroničke pošte.
      </h4>

      <h3 className="pd-label">Opće postupanje s osobnim podatcima</h3>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prije svega, jedini podaci koje obrađujemo su kontaktni podatci koje
        korisnik ostavlja na ovoj stranici prilikom ispunjavanja kontaktne
        forme/obrasca, slanja e-maila ili na bilo koji drugi način putem kojeg
        "Dosta je bilo" službeno komunicira s korisnicima, a navedeni su u opis
        dostupnih metoda kontaktiranja i komunikacije na web-stranici
        www.dostajebilo.hr. Ovi se podatci koriste samo u jednu svrhu, a odnose
        se na potrebe samog odgovora na pitanje, a ne za bilo koje druge
        potrebe, uključujući marketinške aktivnosti ili prosljeđivanje trećim
        stranama. Isto je moguće samo pod uvjetom da "Dosta je bilo" kao
        voditelj obrade podataka o tome precizno i detaljno obavijesti korisnika
        i od njega dobije nedvosmislenu suglasnost da može poduzimati radnje u
        ovom obliku. Također, ne prosljeđujemo podatke trećim ili stranim
        zemljama ili organizacijama bilo koje vrste ili skupine, osim pod upravo
        spomenutom iznimkom, kako je i prije objašnjeno.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Slanjem upita, prvenstveno putem kontaktnog obrasca, korisnik pristaje
        na takvu obradu podataka, svjesno i nedvosmisleno suglasan s tim. U
        slučaju da korisnik ne želi poslati upit zbog potrebe da pristane na
        takvu suglasnost, ili u poslanoj poruci izričito navede da ne dopušta
        navedeni način obrade podataka, "Dosta je bilo" neće moći postupiti po
        upitu i neće, naravno, odgovoriti. Korisnik osobno ima pravo u bilo
        kojem trenutku dati drugu suglasnost ili povući staru, a o prihvaćanju
        takvih zahtjeva bit će obaviješten u najkraćem mogućem roku, sukladno
        važećem zakonu.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Također, slanjem provjerenog zahtjeva korisnik ima pravo zatražiti
        informaciju o svim osobnim podatcima koji se odnose na tu osobu, koje
        "Dosta je bilo" posjeduje ili obrađuje u određenom trenutku. Također se
        odgovara u najkraćem mogućem roku u okviru zakonske regulative. Po
        primitku zahtjeva za povlačenje privole, "Dosta je bilo" će izbrisati,
        ukloniti ili uništiti sve povezane osobne podatke tražitelja akcije,
        tako da ih više ne posjeduje u svojoj obradi. U pravilu će većina
        zahtjeva vezanih uz bilo koji oblik radnje u smjeru obrade podataka biti
        riješena unutar svega nekoliko dana, a najduže u roku od osam (8)
        kalendarskih dana.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Osobni podatci korisnika čuvaju se na sigurnom mjestu i na način da im
        je nemoguće pristupiti bilo kome tko nema pravo pristupa tim podatcima,
        sukladno gore navedenim uvjetima. "Dosta je bilo" će redovito održavati
        svoje administrativne i podatkovne baze i osigurati tehničke uvjete koji
        će jamčiti stabilnost, sigurnost i povjerljivost osobnih podataka i
        dodatno će pružiti svaku potrebnu zaštitu za sprječavanje kopiranja,
        zlouporabe, izmjene ili bilo kakvog neovlaštenog pristupa takvim
        podacima. U slučaju da se takvo što dogodi, bez ikakve odgode
        obavijestit će sve nadležne institucije, prije svega najbližu policijsku
        upravu i AZOP — Agenciju za zaštitu osobnih podataka sa sjedištem u
        Zagrebu.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako imate bilo kakav upit koji bi se na bilo koji način odnosio na
        ostvarivanje vaših prava iz zakona i propisa, kao i posebnih propisa
        koji se odnose na zaštitu osobnih podataka, obratite nam se u bilo kojem
        trenutku slanjem upita na adresu elektroničke pošte info@dostajebilo.hr,
        što je službena adresa putem koje ostvarujete upravo opisana prava.
        Alternativno zaprimamo ovakve službene zahtjeve i na
        dostajebilo@dostajebilo.hr. Bilo kakvo slanje upita ili zahtjeva
        neregularnim načinima, poput društvenim mrežama, usmeno ili bilo kako
        drugačije, neće se uvažiti i smatrat će se da upit nije niti poslan.
      </h4>

      <h3 className="pd-label">Mogućnost izmjene pravila privatnosti</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        "Dosta je bilo" zadržava legitimno i zakonski opravdano pravo da pravila
        privatnosti koja su ovdje trenutno navedena mijenja redovito. Broj
        izmjena nije određen i shodno tome postoji mogućnost da će doći do
        povremene izmjene pravila. Voditelj obrade podataka ipak će nastojati
        izmjene vršiti koliko god je periodički rjeđe moguće i kada za time
        postoji jasna potreba, posebice ako se želi izbjeći nepridržavanje
        zakonskih normi ili se pravila moraju/mogu dodatno prilagoditi državnim
        i europskim aktima koji Republiku Hrvatsku obvezuju.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Nastavno na opisano, proizlazi da je svaki korisnik ove internetske
        stranice obvezan i dužan redovito se upoznati s pravilima privatnosti,
        dok je obveza voditelja podataka svaku izmjenu bez odgode pružiti na
        uvid, upravo na ovoj domeni. Isto vrijedi i za osobe koje stupaju u
        službeni kontakt s "Dosta je bilo" ili njihovim volonterima, zaposlenima
        i drugim osobljem, a da je isto ostvareno putem službenih kanala
        popisanih upravo na www.dostajebilo.hr. Organizacija je svakom korisniku
        i zainteresiranoj strani dužna pružiti informacije o aktualnim i
        planiranim izmjenama ovih uvjeta i na traženje svaku izmjenu objasniti
        legitimnim i razumnim ciljem i opravdanjem, kao i time na koji način je
        to usklađeno s obveznim normama i utječe na osobne podatke korisnika.
      </h4>

      <h3 className="pd-label">Podatci koje dajete "Dosta je bilo"</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Postoji niz kategorija informacija koje mogu biti prikupljene od "Dosta
        je bilo", a osnovna su svakako oni koje nam vi dajete ili šaljete. Takvo
        što je nužno, obvezno i potrebno kako bismo kao dvije strane koje,
        zakonski gledano, sklapaju ugovorni odnos, mogli provoditi svoje obveze
        i zaduženja. Ono što jest bitno je to da takve podatke niit u kojem
        slučaju ne šaljemo bilo kojoj trećoj strani izvan sustava naše
        organizacije, a kada bi se takva potreba stvorila, ista treba biti ili
        uz vašu izričitu i nedvosmislenu dozvolu, ili na zahtjev nadležnih
        sudskih i policijskih institucija pod pravovaljanim nalogom.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U slučaju da nam odbijete pružiti neke od tih podataka, vrlo je
        vjerojatno da vam traženu uslugu, odgovor ili bilo koju treću radnju
        nećemo moći pružiti. Ponavljamo da takve podatke možemo tražiti samo ako
        za time postoji opravdani interes i legitimni cilj i to pravo ne smije
        se zloupotrebljavati. Ako osoba odbije dostaviti tražene podatke, bilo
        preko zahtjeva na internetskoj stranici www.dostajebilo.hr ili drugog
        verificiranog kanala, "Dosta je bilo" neće nastaviti s obradom istog.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Takvi podatci mogu biti skupljani prilikom bilo kakvih registracija ili
        prijava na www.dostajebilo.hr, a kojima je cilj verifikacija profila.
        Mogu se tražiti podatci poput imena i prezimena, naziva poslovnog
        subjekta, broj mobitela ili telefona, adresa elektroničke pošte, datum
        rođenja ili registracije praavne osobe ili njezine odgovorne osobe
        ovlaštene za zastupanje i slično.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        S obzirom na to da je daljinskim putem velika mogućnost manipulacije
        identitetom i krađe istog, kako bismo otklonili mogućnost zloupotrebe i
        nezakonitih aktivnosti osoba s kriminalnim namjerama, dužni smo tražiti
        provjeru identiteta svake osobe, na način da zatražimo sliku važeće
        osobne iskaznice, putovnice, radne ili vozačke dozvole ili kakvog
        sličnog prihvatljivog dokumenta. Mogu se tražiti i drugi podatci, ali
        samo dok postoji legitimna potreba za potpunim otklonom sumnje u
        ispravnost nečijeg identiteta i njegovu autentičnost.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Također, prikupljamo i sve vaše podatke i poruke koje ste nam ostavili
        prilikom komunikacije s osobljem iz naše udruge preko službenih kanala
        komunikacije koje smo već objasnili iznad. Molimo vas da svaku
        komunikaciju s organizacijom vodite preko upravo tih službenih kanala
        kako bismo maksimalno pojačali transparentnost, kao temeljno načelo
        kojim se vodimo u svom radu. Komunikacija ostvarena preko Facebookovih
        stranica s udrugom potpada pod odgovornost trećih strana, dakle, u ovom
        slučaju kompanije Facebook.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Za vezane upite uz to molimo vas da više informacija provjerite na
        https://www.facebook.com/policy.php, odnosno
        https://twitter.com/en/privacy za Twitter, kojeg možemo također
        povremeno koristiti kao alat komuniciranja. Službeni podatci o kompaniji
        Facebook: Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal
        Harbour, Dublin, Republika Irska. Službeni podatci o kompaniji Twitter:
        Twitter International Company, One Cumberland Place, Fenian Street,
        Dublin, D02 AX07, Republika Irska. Također, za sve ostale treće strane
        molimo vas da se obratite isključivo njima i pronađete njihove
        odgovarajuće internetske stranice s politikom obrade podataka.
      </h4>

      <h3 className="pd-label">Donacije i oosbni podatci</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Donacije su način na koji podržavate naše napore, rad, trud i
        volonterski rad kojim nastojimo našu zajednicu učiniti boljim mjestom za
        život. One su način na koji nas motivirate i šaljete nam poruku da još
        odlučnije i čvršće nastavimo u istom smjeru beskompromisne borbe s
        negativnim pojavama u Hrvatskoj. Prilikom doniranja, naravno, šaljete
        nam određene podatke, a koje smo onda dužni obrađivati u skladu sa
        zakonom.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        U većini slučajeva kod anonimne uplate skupljat ćemo dostupne podatke s
        informativnog dokumenta o uplati, a uglavnom je to međunarodni bankovni
        broj ili IBAN. Ako dopišete druge podatke poput vašeg imena, prezimena,
        adrese ili slično, obrađujemo i njih kada nam dospiju i postanu
        dostupni. Bitno je naglasiti da prilikom doniranja u pravilu osoba
        uplaćuje novčani iznos izravno na transakcijski račun, čime ne dolazi do
        međuprijenosa preko neke treće strane. Slično se događa i kod plaćanja
        na kriptovalutnu adresu, uz napomenu da posebne porezne obveze obvezuju
        organizaciju da takve uplate obvezno bilježi, registrira i izbjegne bilo
        kakav oblik utaje poreza, od čega se kao "Dosta je bilo" čvrsto
        ograđujemo i u takvom smjeru ne želimo djelovati. Na našim službenim
        internetskim stranicama ne postoje nikakvi trenutno ugrađeni sustavi
        prijenosa uplata ili internetske trgovine ili kartična rješenja, odnosno
        aplikacije. Ako se to promijeni, automatski s ugradnjom takvih
        funkcionalnosti na stranicama će se izmijeniti i ovi uvjeti, koje je
        korisnik dužan redovito čitati i pratiti.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako uplatu donacija ili članarina vršite preko sustava PayPala, tada vas
        molimo da se za bilo kakve upite ovoga oblika obratite samo i isključivo
        kompaniji PayPal, budući da je isto u domeni njihove odgovornosti i
        "Dosta je bilo" ni na koji način nije imao utjecaj na njihovu politiku
        privatnosti.
      </h4>

      <h3 className="pd-label">Pritužbe na obradu podataka</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako imate bilo kakav oblik pritužbe na način na koji se izvršava obrada
        vaših podataka, ili niste zadovoljni reakcijom ili rješenjem osoblja iz
        organizacije po tom pitanju, tada nam možete uputiti službeni prigovor
        na info@dostajebilo.hr ili alternativno na dostajebilo@dostajebilo.hr.
        Ako popunjavate tim putem ili web-obrascem, dužni ste poslati sve
        podatke kojim potvrđujete vaš identitet nedvosmisleno kak bismo mogli
        nastaviti s rješavanjem spora. Odgovor će vam pristići u najdulje osam
        (8) dana.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako daljnje prigovore ili ne želite komunicirati s "Dosta je bilo", tada
        ste dužni vaše pritužbe i upite poslati na službene adrese komuniciranja
        Agencije za zaštitu osobnih podataka (AZOP), o čemu više možete vidjeti
        na njihovim službenim internetskim stranicama https://azop.hr.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Spomenutoj agenciji kao ovlaštenom državnom regulatornom tijelu za
        pitanja obrade i zaštite podataka možete se obratiti i u slučajevima
        kada imate sumnje na nepravinosti vezane uz ponašanje trećih strana.
        Primjerice, ako je tu u pitanju Facebook, tada se možete obratiti upitom
        i AZOP-u, ali se preporučuje najprije obratiti nadzornom tijelu te
        kompanije za zaštitu podataka, a dalje i povjereniku za zaštitu podataka
        države u kojoj je sjedište tvrtke. U ovom slučaju - Republike Irske.
      </h4>

      <h3 className="pd-label">
        Prikupljanje informacije tijekom posjećivanja internetskih stranica
      </h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prilikom posjeta našim internetskim stranicama, uz kolačiće kao
        standardnu postavku, postoji mogućnost prikupljanja nekih drugih
        podataka u skladu sa zakonski legitimnom svrhom. Ove su informacije opet
        nužne iz razloga da bi se osigurala provedba ugovornog odnosa između
        "Dosta je bilo" kao voditelja obrade podataka i vas u svojstvu osobe
        čiji se podatci obrađuju. Njihov je temeljni cilj osigurati korisnicima
        što ugodnije korisničko iskustvo i naše usluge prilagoditi vama, kako
        bismo razvili odnos utemeljen na uvažavanju, povjerenju i zakonskim
        normama.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Tako se mogu prikupljati podatci o zemljopisnom položaju jer se oni
        registriraju i bilježe preko vaše IP-adrese ili pomoću GPS-a u
        mobitelima i drugim uređajima. Namjena je ista, prilagoditi se
        korisnicima i osigurati im ugodan boravak na našim stranicama. Upućujemo
        vas na činjenicu da gotovo svaki uređaj ima u svojim postavkama
        mogućnost da se deaktivira korištenje usluge lokacije za određene
        aplikacije. U slučaju preciziranja ovih uvjeta u ovom dijelu ili
        nadogradnje naših usluga mogućnošću korištenja mobilne aplikacije,
        obavijest će biti ovdje automatski istaknuta.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prikupljati se mogu i podatci o vaše uređaju i onda kada niste
        registrirani na našim stranicama. To se odnosi na informacije o samom
        tipu i modelu uređaja, hardwareu, softwareu, podatke o kolačićima, datum
        pristupa i duljinu trajanja sesije (i slično). Mogu se obrađivati i
        podatci poput stranica koje ste pregledavali ili pretraživanja za ispis
        i druge bliske radnje na stranicama "Dosta je bilo".
      </h4>

      <h3 className="pd-label">
        Treće strane kod obrade i prikupljanja podataka
      </h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Sve napisano prije vezano uz ovu temu vrijedi i dalje. Ponavljamo — bilo
        kakva obrada podataka za koju je izravno odgovorna neka strana koja nije
        naša organizacija, makar je do te obrade došlo i preko poveznice na
        našim stranicama, nije u našoj domeni i za nju ne polažemo odgovornost.
        Ako vas zanima što se s vašim podatcima tamo događa, kako se obrađuju,
        mi isto ne kontroliramo pa se za detalje trebate obratiti osobama u tim
        organizacijama koje su zadužene za obradu informacija. Takve podatke
        pronaći ćete na njihovim službenim internetskim stranicama ili da na
        neki drugi regularan način od njih dobijete tu informaciju.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Glavninu svoje aktivnosti preko društvenih mreža odrađujemo putem
        Facebooka, a u budućnosti ćemo u povećanom opsegu koristiti i Twitter.
        Njihove osnovne informacije naveli smo iznad. U tom kontekstu, obrada
        podataka vezana uz vašu aktivnost na tim mrežama, pa i na našim
        stranicama na tim mrežama, u njihovoj je domeni. Možete nam se obratiti
        s pitanjima vezanima uz razloge postupanja moderatora ili admina prema
        vama i vašim komentarima/objavama, ali ostalo nije naša odgovornost. O
        tome više u nastavku pri dnu stranice.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako dođe do značajnijih promjena u "Dosta je bilo", na način da se
        organizacija podijeli, spoji s nekom drugom ili drugima ili promijeni
        svoj formalnopravni status kojeg je prije imala prema važećem
        primjenjivom zakonu, o tome ćemo vas prije same izmjene obavijestiti
        putem ovih uvjeta i pravila, tim više ako takvom radnjom dolazi do
        prijenosa vaših osobnih podataka ili oni dospiju pod neka druga pravila
        i uvjete čija je primjena s ciljem zaštite privatnosti. Ista obavijest
        bit će naznačena ako "Dosta je bilo" sklopi bilo kakav ugovor s
        pružateljima usluga iz područja oglašavanja i marketinga ili društvima
        koja imaju kao poslovni zadatak prikupljati mjerna izvješća i druge
        analitičke podatke kako bi nama pomogli u poboljšanju javnog djelovanja
        i poslovanja kao organizacije.
      </h4>

      <h3 className="pd-label">Vaša prava</h3>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Da biste ostvarili nabrojana prava odmah u nastavku, potrebno ih je
        jasno i nedvosmisleno zatražiti službenim komunikacijskim kanalima koji
        su dosad već nekoliko puta opisani, uz izvršenje tražene identifikacije
        s namjerom sprječavanja krađe identiteta. Rok za povratnu reakciju i
        izvršenje zahtjeva je do osam (8) dana od dana kad je zahtjev zaprimljen
        kao potpun, ispravan i bez ponovnog vraćanja na doradu.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Možete zatražiti da vam se omogući popis vaših osobnih podataka koji se
        trenutno obrađuju u organizaciji i da vam se pošalje njihova lista ili
        kopija. Imate pravo tražiti da se netočni podatci isprave, kao i
        naložiti zaustavljanje obrade ili dodatno i brisanje svih ili nekih
        podataka (pritom pazite da time ne gubite neko pravo ili status zbog
        nemogućnosti nastavka suradnje ili provedbe ugovora). Postoji mogućnost
        da precizirate i redefinirate svrhu u sklopu koje se vaši podatci mogu
        koristiti, ako ste se predomislili. Imate pravo uložiti reklamaciju na
        način na koji se izvršava obrada vaših podataka i, konačno, zatražiti da
        se vaši podatci prenesu nekom drugom, željenom izvršitelju obrade tih
        podataka.
      </h4>

      <h3 className="pd-label">O korištenju kolačića</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Korištenjem ove stranice, na vaš se uređaj pohranjuju kolačići ili{" "}
        <i>cookies</i> na negleskom. Oni ne služe da bi vas pratili i
        registrirali nečije surfanje internetom, niti to uopće mogu, već da
        prije svega vama poboljšaju korisničko iskustvo upravo na ovoj stranici
        koju posjećujete. Kolačići su zapravo skup alfanumeričkih podataka koji
        nama pomažu da prilagodimo stranicu sukladno željama i preferencijama
        korisnika. O njihovoj upotrebi vas obavješćujemo pri ulasku na stranicu
        iskočnim prozorom s pripadajućom obavijesti i poveznicom na pravila
        korištenja i privatnosti.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ako se ne slažete, možete napustiti stranicu. Brisanje kolačića u
        postavkama vašeg pretraživača rezultirat će ponavljanjem poruke iz
        spomenutog iskočnog prozora. Ova stranica koristi one kolačiće čiji
        popis i definiciju možete pronaći kada kliknete na znak lokota koji je
        smješten u gornjem dijelu internetskog pretraživača kojeg koristite.
        Jedna od ponuđenih opcija upravo su kolačići i tamo možete ostvariti
        detaljan uvid u iste. Pritiskom na tipku za micanje i prihvaćanje
        iskočnog prozora on se pohranjuje petnaest (15) dana i u tom razdoblju
        ne dolazi do ponavljanja poruke (osim ako korisnik ne obriše kolačiće
        preglednika).
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Na stranici Marketing Platform Google moguće je vidjeti kako, na koji
        način i u kojem opsegu neka treća strana koristi i pohranjuje svoje
        podatke. Ovdje je riječ o servisu <i>Google Analytics</i>, a koji
        isključivo služi da bismo analizirali statistiku posjećenosti stranice.
        Isti ne mora biti uvijek aktivan u programskom kodu, ali to tada nije ni
        bitno jer do obrade i skupljanja podataka niti ne dolazi.
      </h4>

      <h3 className="pd-label">Autorska prava</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Organizacija "Dosta je bilo" polaže autorska prava na sav sadržaj na
        ovoj stranici, tekstualni i onaj u vidu netekstualnih formata, kao i
        dizajn i programerski kod za ovu stranicu. Isto vrijedi za sav sadržaj
        naveden ovdje, a da nije nedvosmisleno naznačen kao tuđe autorsko pravo
        ili je pridodana poveznica uz jasan opis kamo usmjerava. Organizacija
        nije odgovorna za bilo što proizašlo na stranicama na koje preusmjerava,
        budući da je sadržaj na njima podložan promjenama na koje subjekt nema
        apsolutno ikakav utjecaj i nisu u njegovoj domeni. Korištenje bilo čega
        s ove stranice dozvoljeno je isključivo uz navođenje izvora. U suprotnom
        se čini povreda tog prava koja je kažnjiva.
      </h4>

      <h3 className="pd-label">
        Ograda od sadržaja koji pišu drugi na društvenim mrežama
      </h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Organizacija nije odgovorna za stavove koje pojedini korisnici
        društvenih mreža iznose u komentarima po njenim stranicama i grupama.
        Bilo kakav stav pojedinca iznesen u komentaru, neovisno o načinu na koji
        ga on ili ona prenosi ili predstavlja, nije naš službeni stav. Stavovi
        udruge proizlaze isključivo iz podataka navedenih na ovoj stranici i u
        samom statutu "Dosta je bilo". "Dosta je bilo" može poduzimati i pravne
        radnje protiv svih osoba koje druge dovode u zabludu o stavovima
        organizacije ili svjesno/nesvjesno iznose informacije koje u javnosti
        stvaraju neispravan dojam o njezinom radu. Da bismo jasno naznačili naše
        vrijednosti koje tražimo od osoba koje komentiraju na našim profilima po
        društvenim mrežama, ispod iznosimo pravila komentiranja i objavljivanja
        koja se jednako primjenjuju na sve, od ovlaštene osobe do krajnjih
        korisnika usluge.
      </h4>

      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Zaključno potvrđujemo da je "Dosta je bilo" odgovorna za samo one
        stavove koji su izneseni bilo gdje u sklopu ove internetske stranice,
        kao i objave na društvenim mrežama čiji je ona izravni autor, ili neka
        od osoba koja je navedena kao osoba ovlaštena za zastupanje. Sve drugo
        ima se smatrati osobnim stavom osobe koja je to izrekla i sva
        odgovornost za izrečeno isključivo je i samo na upravo toj osobi.
      </h4>

      <br />
      <br />
      <br />
      <br />
      <div className="fence-line"></div>
      <h3 className="pd-label">Pravila na društvenim mrežama</h3>
      <div className="fence-line"></div>

      <h3 className="pd-label">Prijetnje i govor mržnje</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prijetnje i/ili iskazivanje mržnje, kao i poticanje istih i
        diskriminacije prema bilo kojoj skupini ili osnovi strogo su zabranjeni.
        Svi komentari koji sadrže bilo kakav oblik prijetnje ili šire mržnju
        prema bilo kojoj osnovi brisat će se bez opomene. Ponavljanje je strogo
        nedozvoljeno.
      </h4>

      <h3 className="pd-label">Vrijeđanje</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Nisu primjereni niti dozvoljeni komentari u kojima se vrijeđaju drugi
        članovi ili osobe izvan grupe. Puno je načina da se neslaganje izrekne
        primjerenije. Ovo uključuje i pretjerano psovanje i verbalno ponižavanje
        bilo koje osobe. Svako ponavljanje potpuno je nedozvoljeno.
        Neprimjereno, vulgarno, agresivno ili bilo kakvo uvredljivo pristupanje
        bilo kojem članu grupe nije dozvoljeno. Isto se odnosi i na osobe,
        pojave, organizacije ili teme nevezane za samu grupu.
      </h4>

      <h3 className="pd-label">Poticanje na nasilje</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Nije dozvoljeno pozivanje drugih osoba na nasilne radnje na bilo koji
        način. Isto vrijedi i za spominjanje bilo kakvog oružja, nasilnih
        skupina i neizravno motiviranje na nasilje. Jednako se pravilo
        primjenjuje i na odobravanje nasilja. Ponavljanje nije dopušteno. Isto
        se odnosi i na iskazivanje podrške i reklamiranje opasnih organizacija
        prema uputama Facebooka.
      </h4>

      <h3 className="pd-label">Kršenje teme grupe</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Dio teme nisu 5G, chemtrailsi, čipiranje, cjepivo, HAARP, Bill Gates i
        dalje tematika koja promiče sulude, terorističke, ekstremističke i druge
        ideje, skupine, političke stranke ili pojedince, kao i sve što previše
        odudara od zadanih ciljeva iz opisa grupe. Ova organizacija, inicijativa
        i Facebook-zajednica kreirana je kao antistožer, a ne antikorona-grupa.
        Ideja grupe je bila početno ukazivati na diskriminatorno, nerazmjerno i
        političko ponašanje vlasti kroz Nacionalni stožer civilne zaštite, kao i
        ukazivati na nelogičnosti u odlukama. S obzirom na kompliciranost
        odgovora na ovu stavku, za sve ostale podatke o našem programu, statutu,
        ciljevima i načelima djelovanja može se pogledati izbornik ove
        internetske stranice klikom na logo "Dosta je bilo" u gornjem desnom
        kutu stranice. Za sve ostale upite i nejasnoće naši volonteri stoje na
        raspolaganju.
      </h4>

      <h3 className="pd-label">
        Promoviranje političkih stranaka i drugih stranica
      </h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Komentari koji sadrže otvorenu promociju bilo koje stranke ili
        djelovanja nekog političara nisu poželjni. Isto se odnosi i na
        reklamiranje drugih grupa i stranica i proizvoda za prodaju. Uklanjaju
        se svi sadržaji koji nemaju veze s temom, a služe samo za reklamiranje
        drugih stranica i grupa. Ovo se posebno odnosi na izazivanje sukoba kroz
        reklame političara/stranaka.
      </h4>
      <h3 className="pd-label">Spam</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Ponavljanje istog komentara više puta ili kopiranje po više objava ometa
        raspravu i predstavlja dodatan posao voditeljima zbog prijava. Isto
        vrijedi i za ponavljanje obrisanog sadržaja. Ako voditelji procjene da
        intenzitet spama stvara pretjeranu štetu ili povećava opseg posla,
        primijenit će ograničenje komentiranja na određeno vrijeme po odabiru.
        Nije dozvoljeno isti sadržaj ponavljati višestruko u kratkom vremenskom
        roku, posebno ako je namjera reklamirati neke političke ili druge ideje
        ili uvjerenja i širiti stavove koji nemaju veze s temom.
      </h4>
      <h3 className="pd-label">Lažne vijesti i netočne informacije</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Prije komentiranja ili objavljivanja, bitno je provjeriti je li vijest
        pouzdana. Bilo bi dobro i poslati dokaze za neka iskustva ili priče koja
        bi mogla drugima izazvati sumnju. Voditelji grupe zadržavaju pravo
        ukloniti komentare za koje ocjene da su sigurno neistiniti, sadrže
        neistine, iznose iskrivljene informacije o nekomu ili nečemu ili su
        takvi da bez jasnih dokaza tvrdnji mogu potaknuti na krive zaključke.
        Nije dozvoljeno širiti informacije koje su vrlo upitne ili otvoreno
        neistinite i obmanjuju ili pokušavaju prevariti članove. Ako netko
        smatra da je neka informacija upitna, slobodno to može javiti.
      </h4>
      <h3 className="pd-label">Ograničavanje tuđeg prava na iznošenje stava</h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Nije dozvoljeno ometati komentatore na način da im se prigovara zašto
        pišu na Facebooku ili agresivno napadati drukčije mišljenje. Prozivanje
        drugih ili same grupe zbog pisanja na Facebooku - voditelji imaju pravo
        odmah trajno zabraniti pristup te osobe grupi/stranici! Provociranje se
        prijavljuje voditeljima grupe. Dok poštuje temu i pravila, svaki član
        ima pravo iskazati svoje stavove. Nije dozvoljeno nekoga napadati jer
        razmišlja drukčije, a onaj tko je tomu izložen, može to odmah javiti
        kako bismo provjerili.
      </h4>

      <h3 className="pd-label">
        Zašto neka objava nije odobrena ili je još na čekanju?
      </h3>
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Vrlo vjerojatno je razlog u nekomu od pravila nabrojanima dosad. Ako
        treba konkretna provjera, slobodno se javite, provjerit ćemo bez
        problema. Objave se ne puštaju ako krše barem jedno od pravila iznad,
        ako je taj sadržaj već bio u grupi ili ako je više osoba stavilo isto na
        čekanje. Detalje se može vidjeti s voditeljima grupe, kao i sva druga
        pitanja vezana uz ova pravila.
      </h4>

      <br />
      <br />
      <br />
      <br />
      <div className="fence-line"></div>
      <h3 className="pd-label">Pravno utemeljenje za djelovanje</h3>
      <div className="fence-line"></div>
      <br />
      <br />
      <h4
        className="mb-link"
        style={{
          backgroundColor: "white",
          fontSize: "clamp(var(--clamp-xs-text))",
          marginBottom: "16px",
        }}
      >
        Statut, ciljevi i djelatnosti, odnosno aktivnosti kojima se oni žele
        postići usklađeni u potpunosti sa svim važećim zakonskim propisima
        države, prije svega Zakonom o udrugama (Narodne novine, 74/14, 70/17,
        98/19), sukladno kojima udruga djeluje u pravnom prometu. U Registar
        stvarnih vlasnika kao nadležna osoba upisan je Tomislav Pilkić, temeljem
        Zakona o sprječavanju pranja novca i financiranja terorizma (Narodne
        novine, 108/17 i 39/19). Svi ostali podatci dostupni su na službenoj
        internetskoj stranici dostajebilo.hr ili slanjem upita nekim od
        predviđenih komunikacijskih kanala (npr. e-mail: info@dostajebilo.hr).
        Podatci o poslovanju, poput financijskih izvješća i održavanja sjednica,
        ažurirat će se na https://dostajebilo.hr/poslovanje
      </h4>
    </div>
  );
}

export default PrivacyDetails;

/* MathMLElement
<h3 className="pd-label">TOMISLAV</h3>
MathMLElement */
