import React, { useState } from "react";
import "./MembershipDetails.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

import billimage from "../files/billimage.jpg";
import stopimage from "../files/stopimage.png";
import qrcode from "../files/qrcode.png";

function MembershipDetails() {
  const [isError, setIsError] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  function sendEmailToBecomeAMember(e) {
    e.preventDefault();

    if (
      (document.querySelector("#checkerid").checked === true) &
      (document.querySelector("#nazivid").value !== "") &
      (document.querySelector("#mailid").value !== "") &
      (document.querySelector("#dateid").value !== "") &
      (document.querySelector("#oibid").value !== "") &
      (document.querySelector("#descid").value !== "")
    ) {
      emailjs
        .sendForm(
          "service_zo9q334",
          "template_38g7m7a",
          e.target,
          "user_saWdICV9owpFaiJHB3V1j"
        )
        .then(
          (result) => {
            setIsCorrect(true);
          },
          (error) => {
            setIsError(true);
          }
        );

      e.target.reset();
    } else {
      setIsError(true);
    }
  }

  return (
    <div className="maindivmember">
      <div className="cm memb-details">
        <form className="setform" onSubmit={sendEmailToBecomeAMember}>
          <p className="setppp">
            <h1>Pristupnica</h1>
          </p>
          <h4 className="mb-link">
            Zašto se učlaniti i zašto možda ne, odnosno koje su druge odlične
            mogućnosti, vidi <a href="/uclanjenje#dodatno">niže na stranici</a>.
          </h4>
          <h4 className="map-h4 line-down">
            Molimo te da sve podatke popuniš ispravno i ne ostaviš bilo koje
            polje praznim jer u tom slučaju tvoj zahtjev neće biti zaprimljen.
            Ispod se traži minimalna količina podataka nužna temeljem{" "}
            <i>Zakona o udrugama</i>.
          </h4>
          <div className="toleft">
            <label>Ime i prezime ili puni naziv pravne osobe: </label>
            <input type="text" name="naziv" id="nazivid" required />
          </div>
          <br />
          <div className="toleft">
            <label>E-mail: </label>
            <input type="email" name="mail" id="mailid" required />
          </div>
          <br />
          <div className="toleft">
            <label>OIB: </label>
            <input type="text" name="oib" id="oibid" required />
          </div>{" "}
          <br />
          <div className="toleft">
            <label>
              Datum rođenja (za pravnu osobu datum ovlaštene osobe): <br />
              <h5 className="label-h5">
                * ako ti je teško koristiti ovakav izbor datuma, označi bilo
                koji datum i možeš nam točan unijeti tekstualno ispod u
                komentaru
              </h5>{" "}
            </label>
            <input type="date" name="date" id="dateid" required />
          </div>
          <br />
          <div className="toleft">
            <label>Napomene i bitni dodatci podatcima iznad: </label>
            <textarea name="desc" id="descid" required></textarea>
          </div>
          <br />
          <div className="toleft-col">
            <input
              type="checkbox"
              value="abc"
              checked="false"
              id="checkerid"
              required
            />{" "}
            <label>Potvrđujem da sam popunio sve podatke. </label>
          </div>
          <br />
          <br />
          <br />
          <label className="toleft">
            Obrada podataka danih pri popunjavanju obrasca iznad odvijat će se
            isključivo s ciljem odgovaranja na postavljeni upit i neće doći do
            prosljeđivanja istih bilo kojoj trećoj strani. Podatke obrađuje
            organizacija 'Dosta je bilo' (OIB 72276005315). U slučaju
            nepopunjavanja svih podataka iznad, neprofitna organizacija nije
            dužna odgovoriti na upit. Svako postupanje usklađeno je s GDPR-om.
          </label>{" "}
          <br />
          <br /> <br />
          <h4 className="mb-link">
            Ako ne želiš učlanjenje i unositi tražene podatke, klikni na{" "}
            <Link to="/kontakt">poveznicu ovdje</Link>, a gdje možeš popuniti
            opći kontaktni obrazac i pitati što god da te zanima (ako ti mi,
            naravno, možemo pomoći).
          </h4>{" "}
          <br />
          <br />
          <br />
          <button type="submit" className="form-btn" value="POŠALJI ZAHTJEV">
            POŠALJI ZAHTJEV
          </button>
          <br />
          <br />
          {isCorrect ? (
            <div className="is-correct">
              Poruka je uspješno poslana. Tvoj ćemo zahtjev za pristupanjem
              unijeti u internu bazu u roku od osam dana. Puno ti hvala na
              povjerenju koje si nam odlučio dati.
            </div>
          ) : (
            ""
          )}
          {isError ? (
            <div className="is-error">
              Prilikom slanja dogodila se određena poteškoća. Ljubazno te molimo
              da nam se trenutno obratiš slanjem izravnog upita klikom na{" "}
              <a href="mailto:info@dostajebilo.hr">info@dostajebilo.hr</a>
            </div>
          ) : (
            ""
          )}
          <br /> <br />
        </form>
      </div>

      <div className="secondone memb-conditions" id="dodatno">
        <p className="accesssecond more-shadow">Postupak učlanjivanja</p>
        <div className="decordivagain">
          <p>
            Sukladno našim statutarnim odredbama, učlaniti u "Dosta je bilo"
            mogu se sve punoljetne i poslovno sposobne fizičke i pravne osobe.{" "}
            <strong>
              Za učlanjenje je potrebno ispuniti pristupnicu smještenu iznad ili
              poslati e-mail s navedenim svim potrebnim podatcima
            </strong>{" "}
            koji su obveza zbog zakonskih traženja. Ti podatci dostupni su ti
            pogledom na pristupnicu koju smo priložili iznad.{" "}
          </p>
          <br />
          <br />
          <p>
            Nakon što uspješno pošalješ podatke koji su potrebni, mi ćemo ih
            obraditi u najkraćem roku, a nikako{" "}
            <strong>ne duljem od osam dana u skladu sa statutom</strong>. Ako
            provjerom utvrdimo da podatci nisu potpuni, točni i ispravni,
            zahtjev nećemo smjeti uvažiti, već ćemo ti poslati povratnu poruku u
            kojoj te obavješćujemo o tome i zamolimo za nadopunu i/ili
            ispravljanje. Čim pošalješ novi zahtjev, prolazimo istu proceduru
            otpočetka.
          </p>
          <p
            style={{
              backgroundColor: "#d82020",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #d82020",
              border: "5px solid #d82020",
              marginTop: "80px",
            }}
          >
            Kada je zahtjev prihvaćen, šaljemo ti o tome povratnu informaciju. S
            obzirom na ograničenu mogućnost provjere svih detalja, podsjećamo te
            i da je tvoja odgovornost da nam pošalješ točne podatke i da
            učlanjenje možeš odrađivati isključivo u svoje ime, nikako u ime
            bilo koje druge osobe, neovisno o odnosima s njom.
          </p>
          <p
            style={{
              backgroundColor: "#d82020",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #d82020",
              border: "5px solid #d82020",
              marginTop: "80px",
            }}
          >
            Postupanje s tvojim podatcima utemeljeno je na važečem hrvatskom i
            zakonodavstvu EU vezanima uz obradu i zaštitu osobnih podataka. To
            znači da se s tvojim podatcima neće postupati niti u jednu drugu
            svrhu, osim one za koju je ostvarena jasna privola, koja se očituje
            popunjavanjem pristupnice ovdje, odnosno slanjem nedvosmislenog
            zahtjeva za učlanjenjem e-mailom. Više na{" "}
            <Link
              to="/politika-privatnosti"
              style={{
                color: "yellow",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              poveznici
            </Link>
            .
          </p>{" "}
          <br />
          <br />
          <br />
          <p>
            Nakon učlanjenja nemaš nikakve posebne obveze. Ne trebaš ga
            obnavljati, čak niti plaćati članarinu (vidjet ćeš ubrzo ispod) niti
            trebaš prisustvovati ikakvim organiziranim događajima koje "Dosta je
            bilo" organizira. Svaka radnja isključivo je i samo dobrovoljna.
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond more-shadow">
          Možeš biti naš volonter i bez članstva
        </p>
        <div className="decordivagain">
          <p
            style={{
              backgroundColor: "#d82020",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #d82020",
              border: "5px solid #d82020",
              marginTop: "80px",
            }}
          >
            Ni mi ne volimo članske iskaznice jer znamo dobro što one
            simboliziraju u Hrvatskoj. Članstva su često samo puka formalnost
            koja predstavlja želju pojedinca da stekne bolji društveni položaj
            za sebe. To svi mi neformalno zovemo "uhljebljivanje". To je ono što
            stvara dvostruka mjerila. To je baš ono zašto smo odlučili reći
            "dosta je". Ne želimo to.
          </p>
          <br />
          <br />
          <br />
          <p>
            Zato bismo radije htjeli da se ostaviš formalnosti i ne zamaraš se
            iskaznicama. Više te cijenimo ako si stvarno iskren i motiv ti je
            želja za jednakošću. Baš zato možeš biti naš volonter i bez
            učlanjivanja, ako procijenimo da dijelimo slične stavove i namjere,
            o čemu više imaš u našim{" "}
            <Link
              style={{
                color: "yellow",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              to="/program-i-ciljevi"
            >
              programskim ciljevima{" "}
            </Link>{" "}
            i dodatno{" "}
            <Link
              to="/statut"
              style={{
                color: "yellow",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              statutu
            </Link>
            .
          </p>
          <br />
          <br />
          <p>
            Za sve detalje o suradnji pripremili smo ti detaljno obrazloženje na
            poveznici{" "}
            <Link
              to="/volontiranje"
              style={{
                color: "yellow",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              o volontiranju
            </Link>
            .
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond more-shadow">Je li članarina obvezna?</p>
        <div className="decordivagain">
          <p>
            Nije. Iako uplata nije loša jer nama pomaže da djelujemo još
            snažnije, a nije javna tajna da je bez novca svaka radnja drastično
            teža, otvorili smo mogućnost da onaj tko se učlanjuje sam odredi
            želi li, a ako želi — koliko uplatiti.{" "}
          </p>
          <p
            style={{
              backgroundColor: "#d82020",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #d82020",
              border: "5px solid #d82020",
              marginTop: "80px",
            }}
          >
            <strong>IZNOS ČLANARINA KOD FIZIČKIH OSOBA</strong> <br />
            <br />
            <br />
            Fizičke osobe mogu uplatiti članarinu bez plaćanja ikakvog poreza
            koliki god iznos žele i koliko god on visok bio.
          </p>
          <p
            style={{
              backgroundColor: "#d82020",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #d82020",
              border: "5px solid #d82020",
              marginTop: "80px",
            }}
          >
            <strong>IZNOS ČLANARINA KOD PRAVNIH OSOBA I OBRTA</strong> <br />
            <br />
            <br />
            Navedene kategorije mogu neoporezivo uplatiti novčanu vrijednost
            članarine do dva (2) % vrijednosti prihoda koje su ostvarili u
            prethodnoj financijskoj, odnosno kalendarskoj godini.
          </p>
          <br />
          <br />
          <br />
          <br />
          <p>
            Dodatne informacije o uplati članarine za one koji su se odlučili
            možeš pronaći{" "}
            <a
              href="/uclanjenje#uplata"
              style={{
                textDecoration: "none",
                color: "yellow",
                fontWeight: "bold",
              }}
            >
              niže na ovoj stranici
            </a>
            .
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond more-shadow">Zahvale za tvoju podršku</p>
        <div className="decordivagain">
          <p>
            U ime "Dosta je bilo" zahvaljujemo ti jer si nam dao podršku.
            Vjerujemo da je tvoja odluka bila motivirana time da si prepoznao
            naše trud i iskrenost, a što nama stavlja još veći pritisak da se
            potrudimo više u svom radu. Sama podrška veliki je zalog koji sve
            nas, koji smo volonteri i financijski ne profitiramo osobno od našeg
            djelovanja, dodatno motivira da budemo još uporniji i oštriji prema
            negativnim pojavama u društvu. Društvu kojeg dijelimo i s tobom.
          </p>{" "}
          <br />
          <br />
          <p>
            Ako ćeš nam se htjeti obratiti, možeš imati na umu da ćemo uvijek
            biti dostupni i da će te netko od volontera uvijek saslušati.
            Iskreno vjerujemo da ćemo ti se moći odužiti za tvoju pomoć, a
            najviše kroz uređenije društvo u kojem će se naša djelovanja više
            vrednovati i u kojem neće biti dvostrukih mjerila, licemjerja i
            privilegiranosti. Jer upravo to nas je i motiviralo da djelujemo, a
            vjerojatno i tebe da nam se javiš. Sretno!
          </p>
        </div>
      </div>

      <div className="secondone admin-more">
        <p className="accesssecond more-shadow">Zašto postati član?</p>
        <div className="decordivagain">
          <p
            style={{
              backgroundColor: "#111",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #32a834",
              border: "5px solid #111",
            }}
          >
            <strong>
              <i>Nekoliko motivacijskih rečenica u dvije minute čitanja. </i>
            </strong>
          </p>{" "}
          <br />
          <br /> <br />
          <p>
            Inicijativa koju smo pokrenuli s vremenom je postala ozbiljan
            projekt koji je nadmašio početno djelovanje započeto na društvenim
            mrežama. Kroz najbrži rast u kratkom vremenu ikada zabilježen u ovoj
            zemlji, preko grupe na Facebooku{" "}
            <strong>
              pokrenuli smo u javnosti drukčije mišljenje i stavove o Stožeru
              civilne zaštite
            </strong>
            . Kada je sve počelo, još tada 2020., dok još nismo ni postojali kao
            konkretna pravno određena organizacija, javnost je barem
            dvotrećinski i dalje podržavala rad tog tijela. Svega mjesec poslije
            toga, ta podrška drastično se otopila.{" "}
            <p>
              {" "}
              <br /> <br />
              <em>
                Kao inicijativa veća od svih ostalih zajedno i zajednica na
                Facebooku koja je jedna od rijetkih djelovanju protiv dvostrukih
                mjerila i nepravdi Stožera i Vlade pristupila ozbiljno, uspjeli
                smo prokazati Stožer kao produženu ruku stranke na vlasti i
                uspješno im narušili ugled, a radit ćemo to i dalje. Ovakvom po
                demokraciju i jednakost opasnom tijelu ne smije biti mjesta u
                uređenom europskom društvu. Stožer treba čuvati svaku osobu ove
                zemlje od bilo koje nepogode, a ne bolesti, potrese i druge
                tragedije tretirati kao sredstvo za podizanje rejtinga stranke.
              </em>
            </p>{" "}
            <br />
            <br />
          </p>
          <p>
            Zasluge za to nosi najviše upravo ova inicijativa jer je bez straha
            odlučila nastupiti protiv same države i pokrenuti i kompliciran
            sudski postupak kolektivne tužbe, ali s vremenom i niz drugih
            pravnih koraka, o kojima ćeš naći više u zasebnoj jedinici ove
            web-stranice. Takva tužba zahtijeva i novac i vrijeme i resurse,{" "}
            <strong>
              od pregovora s pravnicima, nalaska novčanih sredstava osobnim
              putem, dugotrajnog čitanja pravne dokumentacije, pažnje da svaka
              rečenica u tužbi ne ulazi u sukob s odlukama Ustavnog suda i
              financiranja troškova rada udruge i sudskog procesa
            </strong>
            . A ovo je tek dio i baš zato nam treba i tvoja pomoć.{" "}
          </p>{" "}
          <br />
          <br />
          <p>
            Sve je navedeno iznad veliki posao. Posao koji nisu mogle odraditi
            financijski dosta snažne inicijative, udruge i skupine. Neki zbog
            straha, neki zbog pretjerane kompliciranosti, neki jer vide druge
            populističke načine za skupljanje bodova.{" "}
            <strong>
              Nakon svih mjeseci, jedina smo skupina koja je ovakav korak dovela
              pred sud
            </strong>
            . Korak je to koji je jedini da može obvezujuće zaštiti prava svih
            nas građana, unatoč stanju sudstva u kojem se trenutno nalazi.{" "}
          </p>{" "}
          <br />
          <br />
          <p>
            Potpora udruzi potpora je i tvojim pravima, pravima tvoje obitelji,
            prijatelja, susjeda. Pomaganjem nama{" "}
            <strong>
              pomažeš nam da zajedno pokažemo da se ne bojimo vlasti, da je ovo
              naša zemlja, da nećemo više dozvoliti da vlast stvara kaste s
              posebnim povlasticama i da zakoni vrijede samo za nas
            </strong>
            . Preko udruge zajedno istupamo protiv nepravde i nećemo dozvoliti
            da još jedno izrugivanje s narodom prođe ispod radara, do nekog
            novog za tko zna koliko mjeseci. Usporedno s isticanjem kršenja
            načela jednakosti pred institucijama od države, ukazivali smo i
            ukazivat ćemo i na druge nepravilnosti pa se nadamo da ćeš ne samo
            formalno prelistati ovu stranicu, nego nam i izravno pomoći kako
            bismo pokušali ukazati na stvari koje nisu onakve kakvima bi trebale
            biti.{" "}
          </p>{" "}
          <br />
          <br />
          <p
            style={{
              backgroundColor: "#111",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #32a834",
              border: "5px solid #111",
            }}
          >
            <strong>
              <i>
                Bitno je naglasiti da kada tražimo potporu od tebe, ne mislimo
                prvenstveno na financije. Ono što bismo cijenili puno više je da
                prepoznaš našu iskrenost i javiš nam se kao volonter, kako bismo
                ti pronašli aktivnost kojom bi s nama mogao djelovati u društvu.
              </i>
            </strong>
          </p>{" "}
          <br />
          <br />
          <p>
            <strong>
              <i>
                Donirati možeš koliko god želiš, a mi se obvezujemo da ćemo
                sudski proces pažljivo voditi i dati sve od sebe da izađemo kao
                pobjednici i vlasti jasno kažemo "dosta je". Bolje društvo
                nećemo postati sami od sebe, nego kada ga sami uredimo, a za to
                nas se ipak treba potruditi malo više i napustiti svoju zonu
                udobnosti. Nije strašno, unatoč svemu, i dalje možemo slobodno
                iskazivati svoje stavove i boriti se za njih.
              </i>
            </strong>
          </p>{" "}
          <br />
          <br />
          <p
            style={{
              backgroundColor: "#111",
              padding: "2rem",
              borderRadius: "10px",
              color: "white",
              outline: "8px solid #32a834",
              border: "5px solid #111",
            }}
          >
            <strong>
              <i>
                Podsjećamo — za učlanjenje nije potrebno uplaćivati bilo kakav
                iznos! Za one koji to žele, ispod prilažemo kratke upute, a više
                je u dijelu stranice koji opisuje same donacije.{" "}
              </i>
            </strong>
          </p>{" "}
          <br />
          <br />
          <br />
          <br />
        </div>
        <Link to="/pravni-koraci">
          <img
            alt="Dosta je bilo Stožera i Vlade - znak STOP, označava kolektivnu tužbu zbog diskriminacije u mjerama Nacionalnog stožera tijekom pandemije i epidemije koronavirusa"
            src={stopimage}
            draggable="false"
            className="memb-img"
          />
        </Link>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond more-shadow" id="uplata">
          Opće informacije u slučaju plaćanja donacije
        </p>

        <p
          className="iban-h4"
          /*     style={{
              color: "black",
              backgroundColor: "yellow",
              padding: "12px",
              textAlign: "center",
              fontWeight: "700",
              borderRadius: "30px",
              border: "5px solid red",
            }} */
        >
          Osnovni iznos članarine nismo odredili, tako da možeš uplatiti koliko
          god želiš — bilo jednu HRK ili neki drugi simboličan iznos, 100 HRK
          ili 1000 HRK. Iako ne forsiramo članove na plaćanje, unaprijed
          zahvaljujemo ako si odlučio/la nagraditi naš rad. Nastojat ćemo tvoj
          korak opravdati i nadamo se da ćeš biti zadovoljan viđenim.
        </p>
        <br />
        <br />
        <br />
        <br />
        <p className="cont-add">
          Ispod ti prilažemo QR-kod koji otvara popis podataka za plaćanje i
          primjerak jedne uplatnice. Klikom na slike možeš iste spremiti na svoj
          uređaj.
        </p>

        <br />
        <br />
        <br />
        <br />
        <a href={qrcode} download>
          <img
            alt="QR-kod ili QR-code za plaćanje članarine udruzi DOSTA JE BILO s podatcima za plaćanje — IBAN, poziv na broj primatelja i model"
            src={qrcode}
            className="memb-img"
          />{" "}
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <a href={billimage} download>
          <img
            alt="Primjer računa i uplatnice za plaćanje članarine udruzi DOSTA JE BILO za pomoć u kolektivnoj tužbi protiv države i Nacionalnog stožera zbog diskriminacije u mjerama protiv novog koronavirusa"
            src={billimage}
            className="memb-img"
          />{" "}
        </a>
        <br />
        <br />
        <h4 className="iban-h4">IBAN: HR8423900011101225100</h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <iframe
          width="560"
          height="415"
          src="https://www.youtube.com/embed/11bUiouH3tA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ borderRadius: "10px", boxShadow: "0 0 16px" }}
        ></iframe>
      </div>
    </div>
  );
}

export default MembershipDetails;
