import React, { useState, useRef } from "react";
import "./Newsletter.css";
import axios from "axios";

import { Link } from "react-router-dom";

function Newsletter() {
  const btnNewsRef = useRef();
  const nameNewsRef = useRef();
  const emailNewsRef = useRef();

  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [newsName, setNewsname] = useState("");
  const [newsEmail, setNewsEmail] = useState("");

  function saveInNewsletterList() {
    setNewsname(nameNewsRef.current.value);
    setNewsEmail(emailNewsRef.current.value);

    setError(false);
    setSent(false);
    setErrorTxt("");

    axios
      .post("http://localhost:7250/newsletter", {
        newsletterName: newsName,
        newsletterEmail: newsEmail,
      })
      .then(function (response) {
        console.log(response);
        setSent(true);
        btnNewsRef.current.disabled = "true";

        emailNewsRef.current.value = "";
        nameNewsRef.current.value = "";

        setNewsEmail("");
        setNewsname("");
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
        setErrorTxt(error.message);
      });
  }

  return (
    <div className="newsletter">
      <h3>Bilten — newsletter</h3>
      <h4>
        Unesi ispod svoju važeću adresu elektroničke pošte ako želiš primati
        obavijesti iz "Dosta je bilo"
      </h4>
      <p>
        "Dosta je bilo" u pravilu sadržaj šalje povremeno, obično jednom tjedno,
        a u kojem te obavješćuje o aktualnim događajima u organizaciji, bitnijim
        izmjenama, novim vijestima i komentarima i svim drugim radnjama ili
        pojavama koje bi mogle biti od interesa za rad naše skupine. Za više
        podataka o tome kako postupamo s tvojim osobnim podatcima u okvirima
        važećih zakona (i kojih), pogledaj na poveznici u nastavku.{" "}
      </p>
      <Link to="/politika-privatnosti">
        Želim više informacija o zaštiti podataka
      </Link>

      <input
        type="text"
        name="text"
        id="text"
        placeholder="Tvoje ime..."
        onChange={(e) => setNewsname(e.target.value)}
        ref={nameNewsRef}
      />
      <input
        type="email"
        name="email"
        id="email"
        placeholder="Tvoj e-mail..."
        onChange={(e) => setNewsEmail(e.target.value)}
        ref={emailNewsRef}
      />
      <button ref={btnNewsRef} onClick={() => saveInNewsletterList()}>
        Želim primati obavijesti
      </button>
      {sent ? (
        <h4
          style={{
            color: "greenyellow",
            backgroundColor: "rgba(255, 255, 255, .95)",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          Uspješno si se predbilježio na bilten. Provjerit ćemo tvoje podatke.
          Za promjene, odustajanje ili druga pitanja obrati nam se na
          info@dostajebilo.hr
        </h4>
      ) : (
        ""
      )}

      {error ? (
        <h4
          style={{
            color: "red",
            backgroundColor: "rgba(255, 255, 255, .95)",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          {errorTxt} <br />
          <br /> Provjeri o čemu se radi slanjem upita na info@dostajebilo.hr
          kako bi naši volonteri otklonili smetnju.
        </h4>
      ) : (
        ""
      )}
    </div>
  );
}

export default Newsletter;
