import React, { useEffect } from "react";
import "./Membership.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import MembershipDetails from "../components/MembershipDetails";

function Membership() {
  document.title = "Učlanjenje \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Pristupnica za učlanjenje u Dosta je bilo. Nemaš nikakve obveze, ne trebaš plaćati članarinu — samo trebaš željeti mijenjati svijet."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="membership">
        <Header
          mainLabel="UČLANJENJE"
          otherLabel="NEMAŠ STROGE OBVEZE. NE TREBAŠ PLAĆATI ČLANARINU. SAMO TREBAŠ ŽELJETI DRUŠTVO POMAKNUTI NAPRIJED BAREM JEDAN KORAK."
        />

        <MembershipDetails />
      </div>
      <Footer />
    </div>
  );
}

export default Membership;
