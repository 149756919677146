import React from "react";
import "./Volunteer.css";
import { Link } from "react-router-dom";

function Volunteer() {
  return (
    <div className="volunteer">
      <img
        src={require("../files/bckg17.jpeg")}
        alt="Djevojka s bakljom u ruci"
        draggable="false"
        className="ban-djb"
      />
      <h2>DOSTA JE</h2>
      <Link to="/volontiranje">
        <div className="inner-cont">
          <img
            src={require("../files/dostaje.png")}
            alt="Logo Dosta je bilo"
            className="dostaje"
            draggable="false"
          />
          <h1>TVOJE IDEJE ISTO VRIJEDE. KLIKNI I IZRECI IH.</h1>
        </div>
      </Link>
    </div>
  );
}

export default Volunteer;
