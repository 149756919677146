import React, { useEffect } from "react";
import "./About.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutMore from "../components/AboutMore";

function About() {
  document.title = "Tko smo mi? \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Dosta je bilo oduvijek je drukčija inicijativa, udruga i organizacija od ostalih, posebno stranaka. Više o nama ovdje."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div>
        <Header
          mainLabel="TKO SMO MI?"
          otherLabel="Naša inicijativa od prvih je dana bila drukčija od ostalih i takva će i ostati. Možeš nas upoznati malo bolje ovdje."
        />
        <AboutMore />
      </div>
      <Footer />
    </div>
  );
}

export default About;
