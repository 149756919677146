import React, { useEffect } from "react";
import "./Program.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgramMore from "../components/ProgramMore";

function Program() {
  document.title = "Program i ciljevi \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Program, ciljevi, načela djelovanja i vrijednosti koje zastupa Dosta je bilo u borbi protiv nepravilnosti i korupcije u Hrvatskoj."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="program">
        <Header
          mainLabel="PROGRAM"
          otherLabel="Program, ciljevi, ideje i vrijednosti koja zastupamo i što nas čini potpuno drukčijima od svih ostalih"
        />
        <ProgramMore />
      </div>
      <Footer />
    </div>
  );
}

export default Program;
