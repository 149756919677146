import React, { useEffect } from "react";
import "./Documents.css";

import Header from "../components/Header";
import Footer from "../components/Footer";

import klasifikacija from "../files/pdfs/klasifikacija-djelatnosti.doc";
import rsv from "../files/pdfs/obavijest-rsv.pdf";
import obrazacUpis from "../files/pdfs/obrazac-za-upis.doc";
import obrazacPromjene from "../files/pdfs/obrazac-za-promjene.doc";
import ciljneskupine from "../files/pdfs/popis-ciljnih-skupina.docx";
import PS from "../files/pdfs/porezni status.pdf";
import vodic from "../files/pdfs/vodic-za-osnivanje.pdf";

function Documents() {
  document.title = "Datoteke \u00A0 \u2764 \u00A0Dosta je bilo";

  useEffect(() => {
    window.scroll(0, 0);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Preuzimanje raznih dokumenata i materijala vezanih uz rad Dosta je bilo, kako bi nas mogao bolje upoznati."
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div>
      <div className="documents">
        <Header
          mainLabel="DATOTEKE"
          otherLabel="Ovdje možeš preuzeti različite dokumente i datoteke koji ti možda mogu pomoći ili dati bolji uvid u naš rad i o nama"
        />
        <div className="djb-docs">
          <div className="djb-docs-box">
            <h3>Logo "Dosta je bilo"</h3>
            <p>
              Ispod možeš skinuti na svoj uređaj slike loga naše organizacije u
              različitim veličinama i formatu <b>.png</b>, dok planiramo uz taj
              format priložiti i jednu datoteku <b>.svg</b>. Slike u nizu drugih
              formata dostupne su interno i ovdje ih se neće sve navoditi.
            </p>
            <div className="djb-docs-box-inside">
              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje64x64.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje64x64.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 64 X 64 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 64x64</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje300x300.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje300x300.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 300 X 300 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 300x300</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje500x500.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje500x500.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 500 X 500 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 500x500</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje900x900.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje900x900.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 900 X 900 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 900x900</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje1300x1300.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje1300x1300.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 1300 X 1300 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 1300x1300</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje2100x2100.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje2100x2100.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 2100 X 2100 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 2100x2100</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostaje3200x3200.png")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/dostaje-materials/dostaje3200x3200.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, veličina 3200 X 3200 piksela"
                  draggable="false"
                />
                <p>
                  <b>Logo "Dosta je bilo", format .png, veličina: 3200x3200</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/dostaje-materials/dostajelogo.svg")}
                title="Dosta je bilo - logo"
              >
                <img
                  src={require("../files/dostaje.png")}
                  alt="Logo organizacije, inicijative i udruge Dosta je bilo, crvena i crna boja, format SVG - scalable vector graphics"
                  draggable="false"
                />
                <p>
                  <b>
                    Logo "Dosta je bilo", format .svg (
                    <i>scalable vector graphics</i>) — nedostupno
                  </b>
                </p>
              </a>
            </div>
          </div>

          <div className="djb-docs-box">
            <h3>Interni dokumenti za preuzimanje</h3>
            <p>
              Ovdje je dostupno i bit će nekoliko dokumenata koji su povezani s
              pravnim i drugim javnim radnjama organizacije, a iz raznih razloga
              odlukom nadležnih osoba trebaju biti dostupni i osobama izvan
              udruge. Kada je donesena, primjerice, odluka o slanju zahtjeva
              prema Ustavnom sudu, dokument za slanje učinjen je javnim kako bi
              bio dostupan onima koji ga žele potpisati pod svojim imenom i
              poslati na ocjenu ustavnosti i usklađenosti sa zakonima (uz
              odobrenje "Dosta je bilo" za takvo postupanje).
            </p>
            <div className="djb-docs-box-inside">
              <a
                className="logo-itself"
                href={require("../files/pdfs/prijedlog.pdf")}
                title="Dosta je bilo - prijedlog Ustavnom sudu za ocjenom ustavnosti"
                download
              >
                <img
                  src={require("../files/pdfdocs.png")}
                  alt="Prijedlog za ocjenom usklađenosti s Ustavom i zakonima Ustavnom sudu, studeni 2021."
                  draggable="false"
                />
                <p>
                  <b>
                    Prijedlog za ocjenom usklađenosti s Ustavom i zakonima
                    Ustavnom sudu, format .pdf, studeni 2021.
                  </b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={require("../files/pdfs/zalba.pdf")}
                title="Dosta je bilo - logo"
                download
              >
                <img
                  src={require("../files/pdfdocs.png")}
                  alt="Neslužbena žalba na odluku Županijskog suda s pregledom tijeka kolektivne tužbe, ožujak 2022."
                  draggable="false"
                />
                <p>
                  <b>
                    Neslužbena žalba na odluku Županijskog suda s pregledom
                    tijeka kolektivne tužbe, format .pdf, ožujak 2022.
                  </b>
                </p>
              </a>
            </div>
          </div>

          <div className="djb-docs-box">
            <h3>
              Dokumenti za osnivanje i upravljanje neprofitnim organizacijama
            </h3>
            <p>
              Kako bismo pomogli ostalima koje zanima postupak osnivanja i
              naknadnog upravljanja neprofitnim organizacijama, posebno
              udrugama, priložit ćemo neke ključne dokumente koji u toj namjeri
              mogu pomoći. S obzirom da se taj sadržaj može mijenjati, prilikom
              pokretanja službenog zahtjeva prema javnim tijelima potrebno je
              provjeriti jesu li na snazi neka druga pravila i uvjeti
            </p>
            <div className="djb-docs-box-inside">
              <a
                className="logo-itself"
                href={vodic}
                title="Dosta je bilo - vodič za osnivanje udruge, Grakni.hr"
                download
              >
                <img
                  src={require("../files/pdfdocs.png")}
                  alt="Vodič za osnivanje udruge s uputama za podnošenje zahtjeva nadležnim uredima opće uprave"
                  draggable="false"
                />
                <p>
                  <b>Vodič s uputama i pravilima za registraciju udruge</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={obrazacUpis}
                title="Dosta je bilo - obrazac za upis udruge u Registar"
                download
              >
                <img
                  src={require("../files/docdocs.png")}
                  alt="Obrazac za registraciju udruge u Registru udruga"
                  draggable="false"
                />
                <p>
                  <b>Obrazac za registraciju udruge u Registru udruga</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={obrazacPromjene}
                title="Dosta je bilo - obrazac za promjenu podataka udruge u Registru"
                download
              >
                <img
                  src={require("../files/docdocs.png")}
                  alt="Obrazac za promjenu podataka udruge u Registru"
                  draggable="false"
                />
                <p>
                  <b>Obrazac za promjenu podataka udruge u Registru</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={klasifikacija}
                title="Dosta je bilo - klasifikacija djelatnosti"
                download
              >
                <img
                  src={require("../files/docdocs.png")}
                  alt="Klasifikacija djelatnosti za udruge"
                  draggable="false"
                />
                <p>
                  <b>Klasifikacija djelatnosti za udruge</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={ciljneskupine}
                title="Dosta je bilo - popis ciljnih skupina"
                download
              >
                <img
                  src={require("../files/docdocs.png")}
                  alt="Popis ciljnih skupina za udruge"
                  draggable="false"
                />
                <p>
                  <b>Popis ciljnih skupina za udruge</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={rsv}
                title="Dosta je bilo - upute za Registar stvarnih vlasnika - RSV"
                download
              >
                <img
                  src={require("../files/pdfdocs.png")}
                  alt="Upute za Registar stvarnih vlasnika - RSV, FINA"
                  draggable="false"
                />
                <p>
                  <b>Upute za Registar stvarnih vlasnika (RSV), FINA</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href={PS}
                title="Dosta je bilo - porezni status udruga, dokument Ministarstva financija"
                download
              >
                <img
                  src={require("../files/pdfdocs.png")}
                  alt="Porezni status udruga, dokument Ministarstva financija"
                  draggable="false"
                />
                <p>
                  <b>Porezni status udruga, dokument Ministarstva financija</b>
                </p>
              </a>
            </div>
          </div>

          <div className="djb-docs-box">
            <h3>Korisne poveznice</h3>
            <p>
              Priložene su poveznice koje usmjeravaju korisnike na druge
              stranice državnih i javnih tijela, ali i sve druge koje mogu
              usmjeravati na sadržaj koji će nekome pomoći da se lakše snađe
              prilikom pokretanja ovakvog važnog koraka za razvoj, napredak i
              promicanje demokratskih načela u Republici Hrvatskoj. Odgovornost
              za točnost, istinitost i ažuriranost podataka na stranicama na
              koje poveznice prebace osobu snose isključivo osobe odgovorne za
              stavljanje tih podataka na internet. Za takve se upite obratite
              njima, "Dosta je bilo" nema nikakav utjecaj na oblikovanje tuđeg
              sadržaja i njegov monitoring.
            </p>
            <div className="djb-docs-box-inside">
              <a
                className="logo-itself"
                href="https://narodne-novine.nn.hr/clanci/sluzbeni/2014_06_74_1390.html"
                target="_blank"
                rel="noreferrer"
                title="Zakon o udrugama - Narodne novine, 2014."
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Simbol poveznice ili linka na webu"
                  draggable="false"
                />
                <p>
                  <b>Zakon o udrugama - Narodne novine, verzija 2014.</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!udruge"
                target="_blank"
                rel="noreferrer"
                title="Registar udruga Republike Hrvatske"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Simbol poveznice ili linka na webu"
                  draggable="false"
                />
                <p>
                  <b>Registar udruga Republike Hrvatske</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!strane-udruge"
                title="Registar stranih udruga koje djeluju na teritoriju Republike Hrvatske"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="SImbol poveznice ili linka na webu"
                  draggable="false"
                />
                <p>
                  <b>
                    Registar stranih udruga koje djeluju na teritoriju Republike
                    Hrvatske
                  </b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!stranke"
                title="Registar političkih stranaka Republike Hrvatske"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Simbol linka ili poveznice za web i internet"
                  draggable="false"
                />
                <p>
                  <b>Registar političkih stranaka Republike Hrvatske</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!zaklade"
                title="Registar zaklada Republike Hrvatske"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Poveznica ili link na webu - simbol"
                  draggable="false"
                />
                <p>
                  <b>Registar zaklada Republike Hrvatske</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!strane-zaklade"
                title="Registar stranih zaklada Republike Hrvatske"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Registar stranih zaklada Republike Hrvatske"
                  draggable="false"
                />
                <p>
                  <b>Registar stranih zaklada u Republici Hrvatskoj</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://registri.uprava.hr/#!vjerske-zajednice"
                title="Evidencija vjerskih zajednica u Republici Hrvatskoj"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Evidencija vjerskih zajednica u Republici Hrvatskoj"
                  draggable="false"
                />
                <p>
                  <b>Evidencija vjerskih zajednica u Republici Hrvatskoj</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://nesolventnost.pravosudje.hr/registar "
                title="Registar nesolventnosti"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Registar nesolventnosti"
                  draggable="false"
                />
                <p>
                  <b>Registar nesolventnosti</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://sudreg.pravosudje.hr/"
                title="Registar trgovačkih sudova"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Registar trgovačkih sudova"
                  draggable="false"
                />
                <p>
                  <b>Registar trgovačkih sudova</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://pretrazivac-obrta.gov.hr/pretraga"
                title="Obrtni registar"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Obrtni registar - slika linka ili poveznice za internet"
                  draggable="false"
                />
                <p>
                  <b>Obrtni registar</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://oib.oib.hr/SaznajOibWeb/"
                title="Provjera osobnog identifikacijskog broja (OIB-a)"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Provjera osobnog identifikacijskog broja (OIB-a)"
                  draggable="false"
                />
                <p>
                  <b>Provjera osobnog identifikacijskog broja (OIB-a)</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://porezna.gov.hr/RpoProvjeriObveznikaPdvWeb/upit"
                title="Aplikacija za provjeru obveznika u sustavu PDV-a"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Aplikacija za provjeru obveznika u sustavu PDV-a"
                  draggable="false"
                />
                <p>
                  <b>Aplikacija za provjeru obveznika u sustavu PDV-a</b>
                </p>
              </a>

              <a
                className="logo-itself"
                href="https://www.registri.uprava.hr/#!upisi"
                title="Elektronički upis udruge i pokretanje osnivačkog zahtjeva"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../files/hyperlink.png")}
                  alt="Elektronički upis udruge i pokretanje osnivačkog zahtjeva"
                  draggable="false"
                />
                <p>
                  <b>
                    Elektronički upis udruge i pokretanje osnivačkog zahtjeva
                  </b>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Documents;
