import "./App.css";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import Membership from "./components/Membership";
import Donate from "./components/Donate";
import Volontiranje from "./components/Volontiranje";
import Privacy from "./components/Privacy";
import Sitemap from "./components/Sitemap";
import Admin from "./components/Admin";
import Financije from "./components/Financije";
import Documents from "./components/Documents";
import Statute from "./components/Statute";
import Program from "./components/Program";
import Lawsuit from "./components/Lawsuit";
import OGS from "./components/OGS";
import USUD from "./components/USUD";
import VSRH from "./components/VSRH";
import ZSZG from "./components/ZSZG";
import News from "./components/News";
import NSCZ from "./components/NSCZ";
import About from "./components/About";
import Error from "./components/Error";

import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useEffect, useRef } from "react";

function App() {
  const shapeRef = useRef();

  useEffect(() => {
    window.scroll(0, 0);

    setTimeout(() => {
      shapeRef.current.classList.add("remove");
    }, 2500);
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <div ref={shapeRef} className="shape">
          <img
            src={require("./files/dostaje.png")}
            alt="Logo Dosta je bilo"
            draggable="false"
          />
          <h3>Dosta je bilo</h3>
          <p>Ne odustajem</p>
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kontakt" element={<ContactUs />} />
          <Route path="/uclanjenje" element={<Membership />} />
          <Route path="/doniranje" element={<Donate />} />
          <Route path="/volontiranje" element={<Volontiranje />} />
          <Route path="/politika-privatnosti" element={<Privacy />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/administracija" element={<Admin />} />
          <Route path="/financijska-izvjesca" element={<Financije />} />
          <Route path="/dokumenti" element={<Documents />} />
          <Route path="/statut" element={<Statute />} />
          <Route path="/program-i-ciljevi" element={<Program />} />
          <Route path="/pravni-koraci" element={<Lawsuit />} />
          <Route path="/pravni-koraci/ustavni-sud" element={<USUD />} />
          <Route path="/pravni-koraci/zupanijski-sud" element={<ZSZG />} />
          <Route
            path="/pravni-koraci/opcinski-gradjanski-sud"
            element={<OGS />}
          />
          <Route path="/pravni-koraci/vrhovni-sud" element={<VSRH />} />
          <Route path="/vijesti" element={<News />} />
          <Route path="/inicijativa-protiv-stozera" element={<NSCZ />} />
          <Route path="/o-nama" element={<About />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="U redu, prijeđimo na bitnije stvari"
        cookieName="mathscript-tracker-ntc-cookie"
        style={{
          background: "rgba(216, 32, 32, 0.9)",
          zIndex: 2000000,
          height: "fit-content",
          display: "grid",
          placeContent: "center",
          placeItems: "center",
          boxShadow: "0 0 30px #111111",
          padding: "1.5rem 4rem",
          textAlign: "center",
          lineHeight: "1.4",
          fontSize: "clamp(var(--clamp-xs-text))",
          userSelect: "none",
          fontWeight: "bold",
        }}
        buttonStyle={{
          color: "white",
          backgroundColor: "#111111",
          borderRadius: "20px",
          padding: "0.5rem 1rem",
          boxShadow: "0 0 10px white",
          fontWeight: "bold",
        }}
        expires={15}
      >
        Bok i dobrodošao/la na službenu stranicu "Dosta je bilo". Da ti ne
        uzimamo vrijeme, samo te kratko informiramo da kao i svaka stranica
        koristimo tzv. kolačiće i to s ciljem da bismo stranicu prilagodili
        drugima i tebi i osigurali ti ugodno korisničko iskustvo. Za više
        detalja šaljemo te {"  "}
        <Link
          to="/politika-privatnosti"
          style={{
            color: "#d82020",
            backgroundColor: "white",
            borderRadius: "20px",
            textDecoration: "none",
            paddingInline: ".5rem",
            boxDecorationBreak: "clone",
            fontWeight: "bold",
          }}
        >
          ovdje
        </Link>
        . Ako smatraš da bismo nešto trebali posebno prilagoditi, javi nam
        porukom kako bismo poboljšali svoj rad.
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
