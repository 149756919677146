import React from "react";

function Pictures() {
  return (
    <div>
      <div className="nscz-images">
        <div className="nscz-images-box">
          <img
            src={require("../files/nscz1.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Članovi Stožera i Vlade, pa i sam premijer, slave izbornu pobjedu u
            srpnju 2020. kršeći sve moguće vlastite mjere, za koje građanima
            određuju stroge kazne i javne osude.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz2.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Premijer Plenković i članovi Vlade na otvaranju Pelješkog mosta,
            ljeto 2021. Veselo krše sve mjere dok su ograničenja građanima tada
            još uvijek na snazi, posebno marljivim radnicima u ugostiteljskom
            sektoru.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz3.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ministar zdravstva Vili Beroš bez maske u prodavaonici, božićno
            vrijeme 2021. Dotad su ovlašteni organi ostalim građanima već
            ispisali nekoliko tisuća kazni i opomena zbog istog. Ne i
            političarima, kojima je ovo uobičajeno ponašanje.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz4.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ljeto 2020. Čelnik Stožera gosp. Davor Božinović krši mjere nakon
            jedne od sjednica na kojima je s ostatkom Stožera pozivao na potrebu
            strogog i ozbiljnog ponašanja. Tih mjeseci su također ukinuli mnoga
            pravila samo za potrebe izbora, kao i nekoliko puta naknadno u 2021.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz5.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Gospođa Alemka Markotić iz Stožera bez maske i među ogromnom
            skupinom vjernika čita za Stepinčevo 2021. Nekoliko puta prije
            počinitelje značajno manjih "prekršaja" zvala bioteroristima i
            izvedenicama riječi "idioti".
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz6.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Vukovarska Kolona sjećanja 2021. (slično i 2020.). Iako su na snazi
            stroge zabrane okupljanja, vladajući zloupotrebljavaju nevine žrtve
            Grada heroja za stjecanje političkih bodova. Tvrde da opasnosti nema
            jer, kažu, ljude štiti "duševni mir". Ovo su riječi stručnjaka,
            kojima građani trebaju vjerovati.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz7.webp")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Andrej Plenković na turniru u Zadru 2020. Dodirivao se sa zaraženim
            tenisačem Novakom Đokovićem. Iako su svi u toj zoni dodira išli u
            izolaciju, premijer je pravila zaobišao jer je izborna kampanja bila
            u tijeku i gdje je redovito kršio tada još donekle stroga pravila.
            Otad se počinje snažnije primjenjivati pravilo o dopuštenosti
            kontakta od 15 minuta.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz8.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Ožujak 2021. i sprovod Milana Bandića, koalicijskog partnera
            vladajućih i osobe protiv koje se vodio niz postupaka za teške
            malverzacije i koruptivna djela. Ostali građani tada svoje mrtve
            pozdravljaju pod rigoroznim ograničenjima. Beroševo opravdanje:
            "Virus nije šampion skoka u dalj".
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz9.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Veljača 2021. i sprovod Miroslava Tuđmana, saborskog zastupnika
            HDZ-a. Ostali građani tada svoje mrtve pozdravljaju pod rigoroznim
            ograničenjima. Ostalim sprovodima dozvoljeno je do 25 ljudi.
            Ministar Božinović komentirao da se na slikama ne vidi jasno stanje,
            možda i nije bilo kršenja mjera (parafr.).
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz10.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Procesija "Pod križen" na Hvaru u travnju 2020. Dok je cijela država
            u jednom od najstrožih lockdowna u svijetu, gosp. Krunoslav Capak
            potpisuje ovu iznimku, jednu od prvih od stotina koje će se
            zaredati. Član Stožera gosp. Beroš podrijetlom je iz tih krajeva,
            slučajno.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz11.png")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            U prvim tjednima jednog od najstrožih lockdowna u svijetu političari
            i ostali, saznalo se naknadno, okupljali su se tajno u klubu u
            Slovenskoj 9 u Zagrebu, jeli, pili i zabavljali kršeći sve mjere na
            snazi. Poljoprivredniku tih tjedana naplatili 8000 kn jer je sam
            otišao u polje usred izolacije. Opet ponovimo — sam.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz12.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Slika iz turističke sezone 2021., slično 2020. Iako se priča o
            strašnom nevidljivom neprijatelju (virusu), da mjesta opuštanju
            nema, da ljudi umiru, da su oni koji osporavaju autoritet stranačkom
            stožeru "idioti" — sva opasnost uvijek je naglo i potpuno nestala
            kada državi trebaju novci turista. Novci za plaće i druge benefite
            raznim uhljebima.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz13.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Kraj srpnja 2020. — slučaj Hrebak, možda i najbahatiji oblik
            bezobrazluka. Iako je prema važećim pravilima zbog dugotrajnog
            boravka u prostoriji sa zaraženim u Bjelovaru trebao u
            samoizolaciju, gosp. Capak potpisuje mu posebno rješenje kojim je
            izbjegao pravilo i došao na konstituirajuću sjednicu Sabora. Bio je
            glas potreban za potvrdu većine u parlamentu.
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz14.jpeg")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Masovno okupljanje Torcide krajem 2020. Ni policija ni Stožer ne
            reagiraju jer se boje zamjerati većoj organiziranoj skupini. Zato
            dodjeljuju kazne i prijave mirnim prosvjednicima koji su svoje
            prosvjede uredno prijavili (primjer 8. studenog 2020.).
          </h4>
        </div>

        <div className="nscz-images-box">
          <img
            src={require("../files/nscz16.webp")}
            alt="Dosta je bilo — Stožer i Vlada krše vlastite mjere kao licemjeri koji ih nameću drugima, primjer"
            draggable="false"
          />
          <h4>
            Javno praćenje jedne od niza utakmica hrvatske nogometne
            reprezentacije u ljeto 2021., protiv Češke. S druge strane
            poduzetnicima i radnicima ograničava se posao, sportašima treninzi,
            sportskim djelatnicima aktivnosti, a pogrebima dostojni uobičajeni
            oproštaji od pokojnika.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Pictures;
