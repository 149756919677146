import React, { useEffect, useRef } from "react";
import Aims from "./Aims";
import DemocracyDay from "./DemocracyDay";
import Footer from "./Footer";
import Header from "./Header";
import "./Home.css";
import InfoBox from "./InfoBox";
import LastPosts from "./LastPosts";
import Volunteer from "./Volunteer";
import WhyDJB from "./WhyDJB";
import Pictures from "./Pictures";

import { Link } from "react-router-dom";

function Home() {
  const shapeRef = useRef();

  // Trans. u JS HTML-entity --- stavi \u + 4 znamenke hex-koda
  document.title = "Dosta je bilo \u00A0 \u2764";

  useEffect(() => {
    window.scroll(0, 0);

    setTimeout(() => {
      shapeRef.current.classList.add("remove");
    }, 2000);

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        '"Dosta je bilo" ukazuje na dvostruka mjerila, nejednakosti, korupciju i druge nepravilnosti koje nastoji spriječiti, riješiti i reći im "dosta".'
      );

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "dosta je, dosta je prosvjed, dosta je bilo, inicijativa dosta je, kolektivna tužba hrvatska, kolektivna tužba stožer, tužba protiv države, udruga dosta je, udruge za diskriminaciju, tužba zbog diskriminacije, kako se pokreće tužba protiv diskriminacije, sudski postupak trajanje, tražimo ukidanje nacionalnog stožera, tomislav pilkić, pilkić, korona stožer, ukidanje stožera korona, grupa za ukidanje stožera, facebook grupe protiv vlade, prosvjed protiv vlade, 24 sata korona vijesti, udruge civilnog društva, broj udruga"
      );

    document
      .querySelector('meta[name="author"]')
      .setAttribute(
        "content",
        "Tomislav Pilkić, predsjednik Dosta je bilo. Izrada weba: Mathscript d. o. o., Zagreb. Informacije: https://mathscript.eu i https://mathscript.hr"
      );
  }, []);

  return (
    <div className="home">
      <div ref={shapeRef} className="shape">
        <img
          src={require("../files/dostaje.png")}
          alt="Logo Dosta je bilo"
          draggable="false"
        />
        <h3>Dosta je bilo</h3>
        <p>Ne odustajem</p>
      </div>
      <Header
        mainLabel="DOSTA JE BILO"
        otherLabel="NEVLADINA ORGANIZACIJA ZA SUZBIJANJE DVOSTRUKIH MJERILA U DRUŠTVU"
      />
      {/*       <h3 className="banner">"Dosta je bilo" u brojevima</h3>
       */}{" "}
      <InfoBox />
      <div className="motivation">
        <img
          src={require("../files/jogger.jpeg")}
          alt="Djevojka trči, krajem riječi pjesme Ovaj dan je tvoj grupe Meritas"
          draggable="false"
        />
        <h1>
          Sada kreni i prati me, <br />
          uvijek budi svoj. <br />
          Trči, ne daj svoje vizije, <br />
          ovaj dan je tvoj.
        </h1>
      </div>
      <br />
      <WhyDJB />
      {/*       <h3 className="banner">Obavijesti i komentari — istaknuto</h3>
       */}{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="nscz-expl home-curs">
        <h3 className="make-black make-cursive" style={{ color: "#111" }}>
          Vijesti, komentari, analize, konstruktivni prijedlozi, kritike
          nepravilnosti i dvostrukih mjerila
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Ispod možeš vidjeti nekoliko novijih članaka u kojima iznosimo svoje
          viđenje raznih problema i poteškoća, pokušavamo dotaknuti bitne teme i
          potpuno zapostaviti tzv. <i>clickbait</i>, a naši marljivi volonteri
          iznose i žestoke kritike nepravilnosti, licemjerja, dvostrukih
          kriterija i svega lošeg u državi. Bez kompromisa ili dodvoravanja bilo
          kome. Također, iznose i konkretne prijedloge kako poboljšati neku manu
          u društvu, kako ne bismo bili samo oni koji ništa ne nude, a puno
          "laju", kao ostali. Posebno ćemo doticati i neke političarima
          neatraktivne skupine, poput mladih, a možeš nam i sam predložiti neku
          temu pa ćemo ju dodijeliti pravoj osobi da o njoj nešto napiše.
        </h4>
      </div>
      <LastPosts />
      {/*       <h3 className="banner">Možda i ti možeš pomoći</h3>
       */}{" "}
      {/*   Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break
      Break */}
      <div className="something other">
        <div className="black-div other">
          <h3>
            MORE NIKADA NEĆEŠ PRIJEĆI BUDEŠ LI STALNO SAMO TAKO STAJAO ZAGLEDAN
            U VODU <br />
            <br /> Jednom kad doneseš odluku, čitav svemir se udruži da bi se
            ostvarila.
          </h3>
          <img
            alt="Dosta je bilo - smajlić kao simbol optimizma i manje apatije i ravnodušnosti :)"
            src={require("../files/cat.jpeg")}
            style={{ backgroundColor: "white" }}
          />
        </div>
        <h3 className="mb-link centre-txt">
          Glupost znači raditi istu stvar iznova i očekivati drukčije rezultate
        </h3>
        <br />
        <br />
        <h4
          className="expl-admin mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
          }}
        >
          "Dosta je bilo" od prvih dana nastoji biti drukčija i raditi sve
          drukčije. Tako smo odbili promovirati i reklamirati druge političke
          opcije u nadi da ćemo se tamo uhljebiti i dobiti osiguranu poziciju.
          Toliko smo čvrsto stajali iza uvjerenja da niti jedna jedina objava od
          njih preko 10 000 na Facebooku nije sponzorirala takve stvari.
        </h4>
        <br />

        <h4
          className="expl-admin mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
          }}
        >
          Dok su se drugi bavili medicinskim pitanjima, mi — nismo. Slušali smo
          naše sugrađane i, kao i nama, većini njih smetalo je licemjerno
          djelovanje Vlade koja je ljude gurala u građane drugog reda
          neujednačenim mjerama. Zato smo jedini imali hrabrosti odabrati taj
          smjer i bez straha vrlo oštro prozivati vladajuće, ali i druge u
          društvu koji su pričali jedno, a radili drugo. I u tom stavu smo bili
          toliko uvjereni da ni u jednom trenu nismo skretali s njega,
          zanemarujući korist koju bi to moglo donijeti u datom trenutku.
        </h4>
        <br />
        <br />

        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Ako imaš pametne ideje, a u društvu koje lovi lajkove nikoga to ne
          zanima — tu je "Dosta je bilo"
        </h3>
        <br />
        <br />
        <h4
          className="expl-admin mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
          }}
        >
          Kroz svoj rad upoznali smo razne priče i mnogi su nam otkrili da su od
          svega odustali. Čuli smo mnoge pametne ideje koje nitko drugi ne može
          čuti jer ih njihovi autori nemaju gdje iznijeti, ili su razočarani
          strankama i mnogim udrugama koje gledaju kako opravdati sponzore, a ne
          sugrađane. Mi nismo takvi i kod nas ćeš uvijek moći žestoko (ali
          pametno i s prijedlozima) napisati što ne valja. Naravno, ako se barem
          okvirno slažeš s našim ciljevima, programom i idejama koje zastupamo.
          Zato imaš ovu stranicu, a i nas — šalji poruke kad god ti se prohtije.
        </h4>

        <br />
        <br />

        <br />
        <br />
        <br />
        <br />
        <h3 className="mb-link centre-txt">
          Tko danas skreće pogled s malih nepravilnosti, riskira da sutra osvane
          u diktaturi
        </h3>
        <br />
        <br />
        <h4
          className="expl-admin mb-link centre-txt"
          style={{
            color: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
            lineHeight: "1.3",
          }}
        >
          Puno ljudi smatra da je bolje šutjeti kad uoče nezakonje. Puno ih se
          tješi da je "mogao ukrasti i više". Puno ih misli da su riješili
          probleme bijegom na Zapad. To nisu rješenja, nego kao da veliki požar
          ugasimo samo 10 m od svoje kuće i nastavimo spavati. Svijet je prije
          više desetljeća otišao u svjetski sukob baš zato jer su ljudi vikali
          "bitno da se ne puca". Time su i oni postali sudionici i krivci za sve
          ono što je uslijedilo. Takvu odgovornost ne trebamo više preuzimati na
          sebe.
        </h4>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="nscz-expl">
        <h3 className="make-black make-cursive" style={{ color: "#111" }}>
          Svaka organizacija ima svoja osnovna zlatna pravila djelovanja. Ovo su
          naša.
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Ne brini, ako te zanima, pripremili smo za tebe dosta materijala na
          našim stranicama. Moći ćeš upoznati naše prijašnje djelovanje, ideje,
          program, ciljeve, planove u budućnosti i puno drugog. Ovdje bismo
          htjeli izdvojiti neke opće smjernice kojih se naši volonteri drže u
          svom djelovanju. Ako ti se sviđaju — odlično, nastavi pregledavati
          dalje. Ako ne, svejedno bismo voljeli čuti od tebe što nam zamjeraš i
          što bismo mogli poboljšati u bilo kojem obliku. Ne pišeš uzalud, sve
          čitamo i na sve odgovaramo.
        </h4>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Aims />
      <br />
      <br />
      <br />
      <br />
      <div className="nscz-expl">
        <h3 className="make-black make-cursive" style={{ color: "#111" }}>
          Tko ne uči iz grešaka iz povijesti, osuđen je da mu se one ponavljaju
        </h3>
        <br />
        <br />
        <br />
        <h4
          className="mb-link"
          style={{
            backgroundColor: "white",
            fontSize: "clamp(var(--clamp-xs-text))",
            marginBottom: "16px",
          }}
        >
          Kao ljudi skloni smo brzo zaboravljati ono loše i to je u redu, ali
          kada je u pitanju vlast, to si ne smijemo priuštiti. Uporno i
          neprestano pratili smo nepravilnosti u radu Vlade tijekom epidemije i
          kroz djelovanje, posebno u "Tražimo ukidanje Nacionalnog stožera
          civilne zaštite", sve ih bilježili. Na posebnoj stranici imaš puno
          više detalja, a ovdje imaj na umu — ovo ispod nije možda niti jedan
          posto onoga što su radili. Ako ćemo kao društvo i dalje prešućivati
          ovakve stvari kao ispod i skretati poglede sa strane, pretvorit ćemo
          cijelu državu u veliku stranačku podružnicu. Ako već i nismo.
        </h4>
        <br />
        <br />

        <div className="mb-link-more">
          <Link to="/inicijativa-protiv-stozera">
            VIŠE DVOSTRUKIH KRITERIJA
          </Link>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Pictures />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <DemocracyDay />
      <Volunteer />
      <Footer />
    </div>
  );
}

export default Home;
