import React, { useState, useRef, useEffect } from "react";
import "./Header.css";

import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";

function Header(props) {
  const [spreaded, setSpreaded] = useState(false);
  const [hasTurn, setHasTurn] = useState(false);

  const d = new Date();
  const dayInMonth = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  const redRef = useRef();
  const storeRef = useRef("scaleX(1)");

  useEffect(() => {
    let repeatSec = setInterval(() => {
      if (hasTurn === false) {
        storeRef.current = "scaleX(0)";
      } else {
        storeRef.current = "scaleX(1)";
      }

      setHasTurn(!hasTurn);
    }, 6000);

    return () => {
      clearInterval(repeatSec);
    };
  }, [hasTurn]);

  if (redRef.current) {
    redRef.current.style.setProperty("--scale", storeRef.current);
  }

  return (
    <div className="header">
      <div className="header-one">
        <a href="/" className="remove-decouration">
          <div className="text-djb">
            <p>Zagreb</p>
            <span>{dayInMonth + ". " + month + ". " + year + "."} </span>
          </div>
        </a>
        <div className="action-button">
          {" "}
          <Link to="/vijesti">Vijesti</Link>{" "}
        </div>
        <div className="atom-menu" onClick={() => setSpreaded(!spreaded)}>
          <div className="explore-djb">
            <p>SVE O "DOSTA JE BILO"</p>
          </div>
        </div>
      </div>

      <div className="header-two">
        <h1>{props.mainLabel}</h1>
        <div
          ref={redRef}
          id="box"
          className="red-track"
          data-text="„ More nikad nećeš prijeći ako ćeš samo tako stajati zagledan u vodu.”"
        >
          <p>{props.otherLabel} </p>
          {/* <div className="black-box">
            <MdBackHand className="black-box-hand" />
          </div> */}
        </div>
      </div>

      {/* 
      className={spreaded ? "mc-box shown" : "mc-box"}
       */}

      <div className={spreaded ? "menu-container shown" : "menu-container"}>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>
        <div className={spreaded ? "mc-box shown" : "mc-box"}></div>

        <div className={spreaded ? "nav-track shown" : "nav-track"}>
          <div className={spreaded ? "nav-track-both shown" : "nav-track-both"}>
            <div className={spreaded ? "nav-track-1 shown" : "nav-track-1"}>
              <img
                src={require("../files/dostaje.png")}
                alt="Službeni logo organizacije Dosta je bilo"
                draggable="false"
                className={spreaded ? "shown" : ""}
              />
              <p className={spreaded ? "shown" : ""}>
                Dosta je bilo <br />
                <span className={spreaded ? "shown" : ""}>
                  Nevladina organizacija
                </span>
              </p>
            </div>
            <div className={spreaded ? "nav-track-2 shown" : "nav-track-2"}>
              <Link
                className={spreaded ? "shown" : ""}
                to="/volontiranje"
                onClick={() => setSpreaded(!spreaded)}
              >
                <p>VOLONTIRAJ</p>
              </Link>
              <ImCross
                onClick={() => setSpreaded(!spreaded)}
                className={spreaded ? "cross shown" : "cross"}
              />
            </div>
          </div>

          <div className={spreaded ? "menu-links shown" : "menu-links"}>
            <Link
              className={spreaded ? "shown" : ""}
              to="/"
              onClick={() => setSpreaded(!spreaded)}
            >
              Početna
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/statut"
              onClick={() => setSpreaded(!spreaded)}
            >
              Statut
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/program-i-ciljevi"
              onClick={() => setSpreaded(!spreaded)}
            >
              Program
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/dokumenti"
              onClick={() => setSpreaded(!spreaded)}
            >
              Dokumenti
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/inicijativa-protiv-stozera"
              onClick={() => setSpreaded(!spreaded)}
            >
              Ne Stožeru
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/pravni-koraci"
              onClick={() => setSpreaded(!spreaded)}
            >
              Pravni koraci
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/financijska-izvjesca"
              onClick={() => setSpreaded(!spreaded)}
            >
              Financije
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/politika-privatnosti"
              onClick={() => setSpreaded(!spreaded)}
            >
              Politika privatnosti
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/sitemap"
              onClick={() => setSpreaded(!spreaded)}
            >
              Sitemap
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/o-nama"
              onClick={() => setSpreaded(!spreaded)}
            >
              O nama
            </Link>

            <Link
              className={spreaded ? "shown" : ""}
              to="/administracija"
              onClick={() => setSpreaded(!spreaded)}
            >
              Prijavi se
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/vijesti"
              onClick={() => setSpreaded(!spreaded)}
            >
              Vijesti
            </Link>
          </div>

          <div className={spreaded ? "add-links shown" : "add-links"}>
            <Link
              className={spreaded ? "shown" : ""}
              to="/kontakt"
              onClick={() => setSpreaded(!spreaded)}
            >
              Kontakt
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/doniranje"
              onClick={() => setSpreaded(!spreaded)}
            >
              Doniraj
            </Link>
            <Link
              className={spreaded ? "shown" : ""}
              to="/uclanjenje"
              onClick={() => setSpreaded(!spreaded)}
            >
              Učlani se
            </Link>
          </div>

          <div className={spreaded ? "finger shown" : "finger"}></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
