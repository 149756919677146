import React from "react";
import "./DonateMore.css";

import billimage from "../files/billimage.jpg";
import qrcode from "../files/qrcode.png";

import { AiFillWarning } from "react-icons/ai";

function DonateMore() {
  return (
    <div className="dm">
      <p className="accesssecond">Zašto vjerovati ikome u Hrvatskoj?</p>
      <div className="dm-href">
        <a href="/doniranje#podatci">
          Prijeđi odmah na podatke o plaćanju ispod
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="mark-pgh">
        <p>
          Prije svega, svjesni smo u kakvom društvu živimo i apatije koja je
          zavladala, situacije u kojoj je povjerenje u institucije države dovelo
          do toga da sumnjamo i jedni u druge. Nažalost, takvom stanju{" "}
          <span>
            doprinijele su i razne organizacije, udruge, inicijative i stranke
            koje su načelno radile nešto za javno dobro, ali su se mnoge ili čak
            većina s vremenom kompromitirale
          </span>
          , uglavnom baš na svađama oko novčanih pitanja.
        </p>{" "}
        <br />
        <br />
        <p>
          Sve to rezultiralo je time da su građani u pravilu odustali od svega i
          odlučili{" "}
          <span>vjerovati u to da ovdje djeluju isključivo prevaranti</span>{" "}
          motivirani samo osobnim uhljebljivanjem, zaradom i stjecanjem lagodnog
          života.
        </p>
        <br />
        <br />
        <p>
          U "Dosta je bilo" stoga od prvih koraka, još kao slabo organizirana
          inicijativa,{" "}
          <span>
            imali smo zlatno pravilo da se nećemo vezivati uz poznate političke
            opcije i udruge
          </span>
          , a sve kako bismo građanima pokazali da nas ne motivira osobni
          probitak, nego javno dobro, koliko god to u ovakvoj državi bilo teško
          povjerovati.
        </p>
        <br />
        <br />
        <p>
          Djelujući protiv stranačkog Stožera i Vlade u neprestanom kršenju
          vlastitih pravila i iskazivanju licemjerja još od 2020., stvorili smo
          i jednu od najvećih hrvatskih grupa na Facebooku koja je već nakon
          mjesec dana djelovanja <span>imala oko 130 000 članova</span>.
        </p>
        <br />
        <br />
        <h4>
          Unatoč toj mogućnosti, niti u jednom jedinom trenutku nismo takvu
          grupu iskoristili da bismo se povezali s nekom od mnogih političkih
          opcija i osigurali si sigurnu budućnost. Baš suprotno — to smo uporno
          i uporno, izričito odbijali, želeći se pokazati drukčijima i oosbama s
          poštenim namjerama .
        </h4>
        <br />
        <br />
        <p>
          Većina nas smo ili mlađi ili relativno mlađi ljudi i u pravilu smo{" "}
          <span>samostalni, neovisni, marljivi i obrazovani</span>. Stoga nam
          uništavanje dostojanstva nije i nikada neće biti opcija. Svoje javno
          djelovanje nastavljamo i dalje na isti način — ma koliko on možda bio
          i neprepoznat odmah.
        </p>
        <br />
        <br />
        <p>
          Kroz vođenje ogromne grupe na društvenim mrežama, dobili smo izravnu
          priliku povezati se s nizom poznatih osoba, prije svega političara,
          nekih i iz Hrvatskog sabora — bez pretjerivanja i laganja. Da su mnogi
          drugi akteri u javnom životu, pa čak i građani, imali takvu priliku,
          vjerojatno bi ju <span>iskoristili i odabrali lakši put</span> i
          sredili neku poziciju.
        </p>
        <br />
        <br />
        <h4 style={{ color: "white", backgroundColor: "#d82020" }}>
          Iako ćemo se nekada činiti kao prgavi i prečvrstih stavova, to je samo
          zato jer se od prvih dana trudimo biti drukčiji. Jer sve lijepo i
          dobro na svijetu stvorili su upravo oni drukčiji.{" "}
        </h4>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p className="accesssecond">
        Donacije nisu uvjet jer ne uvjetujemo ni članove članarinom
      </p>
      <div className="mark-pgh">
        <p>
          Prije bilo kakvog nastavka, trebaš znati i da sami članovi (iako
          članstvo također ne forsiramo iz drugih razloga) ne trebaju plaćati
          članarinu ako ne žele.{" "}
          <span>
            Pravilo u "Dosta je bilo" da svatko plati koliko sam želi. Ili ne
            plati.
          </span>{" "}
          To je također jedan od načina kojim nastojimo pokazati fleksibilnost i
          ne fokusirati djelovanje na beskrajno skupljanje novca. Ne u smislu da
          ono postane temelj našeg rada jer tada bismo izgubili samostalnost i
          slobodu da se izražavamo onako kako smatramo da je zajednici
          najkorisnije. Zato je odluka o donaciji isključivo na tebi i nećemo
          raditi nikakve radnje kojima bi se više ili manje izravno poticali
          ljudi da moraju uplatiti. Na nama je da se predstavimo kao drukčiji i
          tu naše uplitanje završava.
        </p>
      </div>

      <br />
      <br />
      <br />
      <br />

      <p className="accesssecond">
        Donacije ne trošimo nerazumno i na sebe, nego na organizaciju
      </p>
      <div className="mark-pgh">
        <p>
          Novac koji dobijemo koristimo i planiramo koristiti s namjerom da
          poboljšamo samu organizaciju i buduće javno djelovanje. Da bismo
          funkcionirali kao pravni subjekt, moramo imati novac za stvari poput{" "}
          <span>
            bankarskih usluga, oglašavanja, plaćanja hostinga za web-stranicu i
            slično
          </span>
          . To su stvari bez kojih, realno, ne možemo ni postojati, ali
          isključivo obvezni, razumljivi troškovi.
        </p>
        <br />
        <br />
        <h4>
          Da je to tako, pokazuje činjenica da smo od desetak tisuća postova u
          ogromnoj grupi na Facebooku (preko 150 000 članova) tek dva posvetili
          pitanju skupljanja novca. Svjesno, da se ne bi stekao dojam da
          koristimo takav teren za iskorištavanje građana.{" "}
        </h4>
        <br />
        <br />
        <p>
          To je također jedan od načina kako smo, uz prijenavedene, pokazali
          drugo lice (ili se barem trudimo pokazati, ako to uopće ima smisla u
          društvu punom apatije). U svakom slučaju, ispod predstavljamo podatke
          s uputama koje trebaš znati prije plaćanja.
        </p>
      </div>
      <br />

      <img
        src={require("../files/girlwriting.jpeg")}
        alt="Cura za laptopom piše članak"
        draggable="false"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="accesssecond" id="podatci">
        Podatci za plaćanje
      </p>
      <div className="mark-pgh">
        <p>
          Podatci o primatelju:
          <span> DOSTA JE BILO, 10 000 ZAGREB</span>
        </p>
        <div className="grey-line"></div>

        <br />
        <p>
          Račun u financijskoj instituciji:
          <span> HRVATSKA POŠTANSKA BANKA - HPB, SJEDIŠTE U ZAGREBU</span>
        </p>
        <div className="grey-line"></div>

        <br />
        <p>
          IBAN — International Bank Account Number:
          <span> HR8423900011101225100</span>
        </p>
        <div className="grey-line"></div>

        <br />
        <p>
          Model:
          <span> HR00</span>
        </p>
        <div className="grey-line"></div>

        <br />
        <p>
          Poziv na broj primatelja:
          <span> UPISATI BILO ŠTO</span>
        </p>
        <div className="grey-line"></div>

        <br />
        <p>
          Iznos uplate:
          <span> ODLUČUJEŠ SAM/A</span>
        </p>
        <div className="grey-line"></div>
        <br />
        <br />
        <br />
        <br />
        <h4>
          Ispod ti je dostupan prikaz QR-koda. Skeniranjem odgovarajućom
          aplikacijom otvorit će ti tekstualnu datoteku s ispisanim podatcima
          koje vidiš iznad. Klikom na sliku možeš taj QR-kod spremiti i na svoj
          uređaj, ako će ti iz nekog razloga biti potreban u budućnosti.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <a href={qrcode} download>
          <img
            alt="QR-kod ili QR-code za plaćanje članarine udruzi DOSTA JE BILO s podatcima za plaćanje — IBAN, poziv na broj primatelja i model"
            src={qrcode}
            className="memb-img"
            style={{
              width: "min(350px, 100%",
            }}
          />{" "}
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h4>
          Također, prilažemo i sliku opće uplatnice sa svim potrebnim podatcima
          za plaćanje. Klikom na sliku također ju možeš pohraniti u memoriju
          svog uređaja.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <a href={billimage} download>
          <img
            alt="Primjer računa i uplatnice za plaćanje članarine udruzi DOSTA JE BILO za pomoć u kolektivnoj tužbi protiv države i Nacionalnog stožera zbog diskriminacije u mjerama protiv novog koronavirusa"
            src={billimage}
            className="memb-img"
          />{" "}
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond">
          Bitne napomene o visini donacija za pravne osobe i obrte
        </p>
        <div className="donate-warn">
          <AiFillWarning className="dw-icon" />
          <h5>
            <span>Fizičke osobe</span> nisu ograničene iznosom uplate i ne
            plaćaju porez na istu kolikog god da je iznosa.{" "}
            <span>Pravne osobe</span> mogu također platiti koliko žele, ali
            trebaju imati na umu da je iznos neoporeziv do visine od dva (2) %
            prihoda iz prošle financijske i kalendarske godine. Za{" "}
            <span> obrte</span> vrijedi isto pravilo.
          </h5>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond">
          Ostali načini plaćanja — PayPal i kriptovalute
        </p>
        <br />
        <br />
        <br />
        <a
          href="https://www.paypal.com/paypalme/dostajebilo"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="PayPal i plaćanje kreditnim karticama Visa, Maestro, American i drugima - Dosta je bilo"
            src={require("../files/paypal.png")}
            style={{
              filter: "none",
              boxShadow: "none",
              width: "min(350px, 100%",
            }}
          />{" "}
        </a>
        <h4 className="mb-link" style={{ backgroundColor: "white" }}>
          Da bi platio koristeći sustav Paypala, trebaš samo kliknuti na sliku
          iznad i bit ćeš preusmjeren na{" "}
          <a
            href="https://www.paypal.com/paypalme/dostajebilo"
            target="_blank"
            rel="noreferrer"
          >
            profil "Dosta je bilo"
          </a>{" "}
          pod oznakom @dostajebilo.
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <a href="https://coinmarketcap.com/" target="_blank" rel="noreferrer">
          <img
            alt="U Dosta je bilo moguće su donacije kriptovalutama, bitcoinom, ethereum, tether, USDT i drugima - čaša s natpisom BITCOIN"
            src={require("../files/btc.jpeg")}
            style={{
              width: "min(350px, 100%",
              height: "250px",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />{" "}
        </a>
        <br />
        <br />
        <h4 className="mb-link" style={{ backgroundColor: "white" }}>
          <big style={{ overflowWrap: "anywhere" }}>
            3EVyRgs3LBzgiGYE3tY7Ntiku8uKPPcbJJ
          </big>{" "}
          <br />
          <br />
          <br />
          Za svaki slučaj te molimo da, ako imaš u planu plaćati veće količine
          novca, najprije prvo{" "}
          <a href="mailto:info@dostajebilo.hr">pošalješ e-mail</a> i provjeriš s
          nama je li adresa ispravna i je li se mijenjala, kako bismo otklonili
          da novac ne bude trajno izgubljen.
        </h4>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="accesssecond">
          Nije na prodaju... (važno prije doniranja)
        </p>

        <h4 className="mb-link" style={{ backgroundColor: "white" }}>
          <big style={{ overflowWrap: "anywhere" }}>
            "DOSTA JE BILO" NI U KOJEM SLUČAJU NEĆE ODSTUPITI OD OSNOVNIH NAČELA
            SVOJIH PROGRAMA I CILJEVA
          </big>{" "}
          <br />
          <br />
          <br />
          Kao organizacija, imamo svoje ciljeve i program zbog kojih smo nastali
          i koji nas motiviraju na daljnji rad. Nažalost, veliki dio
          organizacija iste usklađuje ovisno o izvoru donacija, pazeći pritom da
          svoje djelovanje prilagodit pojedincima, a ne društvu i njegovu
          boljitku, čemu bi organizacije civilnog društva trebale zapravo
          služiti. <br />
          <br />
          Stoga svim donatorima unaprijed zahvaljujemo ako nam žele, planiraju
          ili jesu donirali. Donacije su svakako potrebne iz razloga navedenih
          ranije i kao znak pružanja potpore. Od donatora očekujemo jedino da
          donaciju izvrše jer doista vjeruju da je "Dosta je bilo" drukčija
          organizacija od drugih i na svom putu djeluje iskreno, transparentno i
          vodeći se svim demokratskim ciljevima kojima može poboljšati ono
          negativno među nama.
          <br />
          <br />
          "Dosta je bilo" ne samo da zadržava, već i otvoreno napominje da bilo
          kakav oblik pomoći, podrške, donacije ili suradnje nas ne obvezuje na
          prilagodbu naših statuta, programa ili načela djelovanja kako bismo ih
          uskladili s drugima. Kao organizacija, ni u jednom trenu nećemo
          odstupiti s puta kojim smo išli od prvih dana, idemo trenutno, a njime
          ćemo i nastaviti, što će biti lako uočljivo i prepoznatljivo. Stoga,
          zaključno, pozivamo sve donatore da nam pomognu samo ako doista naše
          djelovanje prepoznaju kao korisno i vrijedno pažnje i da poštuju našu
          autonomiju, koju nije moguće mijenjati ni pod kakvim kompromisima, a
          najmanje onima financijskog tipa. Ako je tako, hvala i tebi na
          iskrenoj potpori s kojom nastavljamo dalje.
        </h4>
        <br />
        <br />
        <a href="/o-nama">
          <img
            alt="Logo Dosta je bilo"
            src={require("../files/dostajeflag.png")}
            style={{
              width: "min(350px, 100%",
              aspectRatio: "2/1",
              objectFit: "cover",
              objectPosition: "center",
              boxShadow: "0 0 16px silver",
              filter: "none",
            }}
          />{" "}
        </a>
      </div>
    </div>
  );
}

export default DonateMore;
